import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { NotFoundComponent } from './components/not-found/not-found.component';
import { RestrictedComponent } from './components/restricted/restricted.component';

@NgModule({
    declarations: [
        NotFoundComponent,
        RestrictedComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class FallbackModule { }
