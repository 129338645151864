import { Component, Input, OnInit } from '@angular/core';
import { OverlayService } from '../../services/overlay/overlay.service';
import { Notification } from '../../types/notification.type';
@Component({
    selector: 'wui-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

    @Input()
    public notification: Notification | undefined;

    constructor(private overlayService: OverlayService) { }

    public ngOnInit(): void {

        if(this.notification?.type) {

            const type = this.overlayService.notificationsStyles
                .find(style => style.type === this.notification?.type);

            if(type === undefined) {

                return;
            }

            const { backgroundColor, foregroundColor } = type;

            this.notification = { ...this.notification, backgroundColor, foregroundColor };
        }

    }

}
