import { Component, Input } from '@angular/core';

@Component({
  selector: 'wui-json',
  templateUrl: './json.component.html',
  styleUrls: ['./json.component.scss']
})

export class JsonComponent {

  @Input() value: any;

}
