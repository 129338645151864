/* eslint @typescript-eslint/ban-types: 0 */

export type Notification = {
    message: string;
    type?: string;
    backgroundColor?: string;
    foregroundColor?: string;
    duration?: number;

    button?: string;
    callback?: Function
};

export type NotificationStyle = {
    type: string;
    backgroundColor: string;
    foregroundColor: string;
}