export class DateUtils {

    public static isToday(date: Date): boolean {

        return DateUtils.isSameDay(date, new Date());
    }

    public static isSameDay(dateA: Date, dateB: Date): boolean {

        return  dateA.getFullYear() === dateB.getFullYear() &&
                dateA.getMonth() === dateB.getMonth() &&
                dateA.getDate() === dateB.getDate();
    }

}
