import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ScrollService {

    // Is fired only when the condition is scrolled changes (on the top or not)
    public onIsScrolled: EventEmitter<boolean> = new EventEmitter();

    // Is fired while a scrolling is made
    public onScrolling: EventEmitter<number> = new EventEmitter();

    // Is fired each time the scroll hits the bottom
    public onHitBottom: EventEmitter<void> = new EventEmitter();

    private _isScrolling = false;
    private _isScrolled = false;
    private _isHittingBottom = false;
    private _scrolledPercents = 0;

    constructor(
        @Inject(DOCUMENT)
        private document: Document) {

        this.document.body.onscroll = (event) => {
            this.onScroll(event);
        }
    }

    public get isScrolling() {

        return this._isScrolling;
    }

    public get isScrolled() {

        return this._isScrolled;
    }

    public get scrolledPercents(): number {

        return this._scrolledPercents;
    }

    private onScroll(event: any): void {

        const documentHeight = event.target.scrollHeight - event.target.offsetHeight;

        // Hits the bottom
        if (event.target.scrollTop > documentHeight - 1) {

            // Move up to detect if still going down
            this.document.body.scroll({ top: documentHeight - 1 });

            if (!this._isHittingBottom) {

                this._isHittingBottom = true;

                this.onHitBottom.emit();

                setTimeout(() => { this._isHittingBottom = false; }, 500);
            }
        }

        // Is scrolling detection with delay
        if (!this._isScrolling) {

            this._isScrolling = true;

            setTimeout(() => { this._isScrolling = false; }, 1000);
        }

        // Is scrolled toggle detection
        const isScrolled = event.target.scrollTop > 0;

        if (this._isScrolled !== isScrolled) {

            this._isScrolled = isScrolled;

            this.onIsScrolled.emit(this._isScrolled);
        }

        // Scroll percents
        const scrolledPercents =
            Math.round((event.target.scrollTop / documentHeight) * 100);

        if (this._scrolledPercents !== scrolledPercents) {

            this._scrolledPercents = scrolledPercents;

            this.onScrolling.emit(this._scrolledPercents);
        }

    }
}
