<ng-container *ngFor="let device of groundModules">
    <div class="area"
        wuiTacticalCorners>
        <div class="title">area</div>
        <div class="body">
            <div class="logo"
                [wuiBackgroundImage]="device.role ? 'assets/operations/overview/roles/' + device.role + '.svg' : ''"></div>
            <div class="name">{{device.name || device.id}}</div>
        </div>
    </div>
</ng-container>

<div *ngIf="groundModules.length === 0">⚠ no available ground modules</div>


<!-- <ng-container *ngFor="let device of groundModules">
    <div class="area"
        [wuiTacticalCorners]="device.role"
        [wuiTacticalCornersOptions]="{infinite: true}"> -->
