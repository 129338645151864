import { Default } from '@lightning/decorators';
import { Expose, Transform } from 'class-transformer';
import { IsOptional, IsNumber, Min, Max } from 'class-validator';
import { OperationReportPreview } from '../entities/operation-report.entity';
import { PaginedRequest, PaginedResponse } from './pagined.dto';

export class OperationReportCreatedResponse {

    public id = '';
}

export class OperationsReportsSearchRequest extends PaginedRequest {

    public authorAccountId?: string;

    public sessionId?: string;

    public date?: Date;

    public mode?: string;

    public version?: number;

    @Expose()
    @IsOptional()
    @Default(-1)    // Latest first by default
    @Transform(({ value }) => Number(value))
    @IsNumber()
    @Min(-1)
    @Max(1)
    public sortByDate?: number;
}

export class OperationsReportsSearchResponse extends PaginedResponse {

    public items: Array<OperationReportPreview> = [];
}
