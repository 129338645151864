
import { Default, IsMongoId, IsNumber, IsOptional, Max, Min } from '@lightning/decorators';
import { DeviceRegistration } from '../entities/device.entity';
import { PaginedRequest, PaginedResponse } from './pagined.dto';
import { Expose, Transform } from 'class-transformer';
import { NfcTagsSystemActions } from '../enums/nfc-tags.enums';
import { ProductsWithSoftware } from '../enums/products.enum';

export class DevicesRegistrationsSearchRequest extends PaginedRequest {

    @Expose()
    @IsOptional()
    @IsMongoId()
    public id?: string;

    @Expose()
    @IsOptional()
    public created?: string;

    @Expose()
    @IsOptional()
    public serial?: string;

    @Expose()
    @IsOptional()
    public product?: string;

    @Expose()
    @IsOptional()
    public model?: string;

    @Expose()
    @IsOptional()
    public orderId?: string;

    @Expose()
    @IsOptional()
    @Default(-1)    // Latest first by default
    @Transform(({ value }) => Number(value))
    @IsNumber()
    @Min(-1)
    @Max(1)
    public sortByDate?: number;
}

export class DevicesRegistrationsSearchResponse extends PaginedResponse {

    public items!: Array<DeviceRegistration>;
}

class DeviceCreateRequest {

    public serial = '';

    public version = '';   // hardwareVersion

}

export class DeviceCreateNfcTagOperatorRequest extends DeviceCreateRequest {

    public lifeTime = 1;

    public accountId = '';

    public operatorName = '';
}

export class DeviceCreateNfcTagGuestRequest extends DeviceCreateRequest{

    public number = 1;
}

export class DeviceCreateNfcTagResourceRequest extends DeviceCreateRequest {

    public value = 1000;
}

export class DeviceCreateNfcTagPartRequest extends DeviceCreateRequest {

    public number = 1;
}

export class DeviceCreateNfcTagLocationRequest extends DeviceCreateRequest {

    public location = ''; // GroundLocations
}

export class DeviceCreateNfcTagSystemRequest extends DeviceCreateRequest {

    public action = NfcTagsSystemActions.Update;
}






export class DeviceCreateHeadquarterGatewayRequest extends DeviceCreateRequest {

}

export class DeviceCreateGroundModuleRequest extends DeviceCreateRequest {

}

