import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'milliseconds'
})

export class MillisecondsPipe implements PipeTransform {

    transform(value: number):string {
        return new Date(value).toISOString().slice(11, -1);;
    }

}
