import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
    selector: 'wui-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {

    private _value: any;

    @Input()
    public autofocus = false;

    @Input()
    public required = false;

    @Input()
    public placeholder = '';

    @Input()
    public get value() {
        return this._value;
    }

    set value(value: any) {
        this._value = value;
        this.valueChange.emit(this._value);
    }

    @Output()
    public valueChange = new EventEmitter();

    constructor(private elementRef: ElementRef) { }

    ngOnInit(): void {

        if (this.autofocus) {
            this.elementRef.nativeElement.querySelector('textarea').focus();
        }
    }
}
