import { Component, OnInit } from '@angular/core';
import { ScrollService } from '../../services/scroll/scroll.service';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from '@lightning/wild-ui';

const MAX_DEEP = 100000;

interface GroundItem {
    left: number;
    top: number;
    rotation: number;
    width: number;
    height: number;
    opacity: number;
    image: string;
    corners: boolean;
    click?: (event: any) => void;
}

@Component({
    selector: 'app-underground',
    templateUrl: './underground.component.html',
    styleUrls: ['./underground.component.scss']
})

export class UndergroundComponent implements OnInit {

    public height = 0;

    public items: Array<GroundItem> = [];

    public get isAtMaxDeep(): boolean {

        return this.height >= MAX_DEEP;
    }

    public isCardCreated = false;
    public isCardFound = false;

    constructor(
        public scrollService: ScrollService,
        private translateService: TranslateService,
        private overlayService: OverlayService) { }

    ngOnInit(): void {

        this.scrollService.onScrolling.subscribe({
            next: () => {
                this.generate();
            }
        });

        navigator.vibrate([1000]);

        this.generate();

        this.overlayService.openNotification({
            message: this.translateService.instant('underground.footerBroken'),
            type: 'success'
        });

    }

    private generate(): void {

        if(this.isAtMaxDeep) {

            return;
        }

        if(this.scrollService.scrolledPercents > 90) {

            this.height += 1000;

            for(let i = 0; i < 10; i++) {

                const size: number = 10 + Math.random() * 90;

                this.items.push({
                    left: Math.random() * 100,
                    top: this.height - Math.random() * 1000,
                    rotation: Math.random() * 359,
                    width: size,
                    height: size,
                    opacity: Math.random() / 2,
                    image: 'stone' + (1 + Math.round(Math.random() * 8)),
                    corners: false
                });
            }
        }

        if (this.isCardCreated === false && this.height > MAX_DEEP / 2) {

            const card: GroundItem = {
                left: 10 + Math.random() * 80,
                top: this.height - Math.random() * 1000,
                rotation: Math.random() * 359,
                width: 90,
                height: 90,
                opacity: 0.5,
                image: 'card',
                corners: true
            }

            card.click = () => {

                this.overlayService.openNotification({
                    message: this.translateService.instant('underground.accessCardFound'),
                    type: 'success'
                });

                this.isCardFound = true;

                this.items = this.items.filter(item => item !== card);
            }

            this.items.push(card);

            this.isCardCreated = true;
        }

    }

}
