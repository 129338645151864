import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AccountService } from '@lightning/website/app/account/services/account/account.service';
import { OverlayService } from '@lightning/wild-ui';

@Component({
    selector: 'app-personal',
    templateUrl: './personal.component.html',
    styleUrls: ['./personal.component.scss']
})

export class PersonalComponent implements OnInit {

    @HostListener('click')
    private onClick() {

        this.overlayService.closeModal();
    }

    constructor(
        public accountService: AccountService,
        private router: Router,
        private overlayService: OverlayService) { }

    public ngOnInit(): void {

        // Close after the navigation completes
        this.router.events.subscribe({
            next: event => {
                if (event instanceof NavigationEnd) {
                    this.overlayService.closeModal();
                }
            }
        });
    }

    public navigateTo(url: string): void {

        if (url === this.router.url) {

            this.overlayService.closeModal();

            return;
        }

        this.router.navigateByUrl(url);
    }
}
