import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ScrollService } from './shared/services/scroll/scroll.service';

const HIT_BOTTOM_TIMES_FOR_UNDERGROUND = 20;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

    public title = 'website';
    public isUndergroundEnabled = false;

    private hitBottomCount = 0;

    constructor(
        @Inject(DOCUMENT)
        private document: Document,
        private translate: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private scrollService: ScrollService) {

        this.translate.setDefaultLang('en');

        this.translate.use('fr');
    }

    public ngOnInit(): void {

        // Hits count
        this.scrollService.onHitBottom.subscribe({
            next: () => {
                if (++this.hitBottomCount >= HIT_BOTTOM_TIMES_FOR_UNDERGROUND) {
                    this.isUndergroundEnabled = true;
                }
            }
        });

        // Reset hits count on navigation (it is usual to hit one time per page)
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.hitBottomCount = 0;
            }
        });

        console.log('The thing you are looking for is under the surface.');
    }

    public activate(): void {

        this.document.body.scrollTop = 0;

        this.isUndergroundEnabled = this.activatedRoute.snapshot.queryParams['ee'] !== undefined;
    }
}
