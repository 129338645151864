<wui-cards *ngIf="winner">

    <!-- winner operator(s) -->
    <wui-card
        *ngIf="!winner.team"
        [title]="'winner.title' | translate"
        [description]="'winner.operator' | translate: winnersData"
        [logo]="logoPath + '/winnerOperator' + logoExtension">
    </wui-card>

    <!-- winner team -->
    <wui-card
        *ngIf="winner.operators"
        [title]="'winner.title' | translate"
        [description]="'winner.team' | translate: winnersData"
        [logo]="logoPath + '/winnerTeam' + logoExtension">
    </wui-card>

</wui-cards>
