import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keyValue'
})

export class KeyValuePipe implements PipeTransform {

    transform(value: any): Array<any> {
        
        const keys = Object.keys(value);

        // Empty enum
        if (keys.length === 0) {
            return [];
        }
    
        // Enum with assigned string values
        if (isNaN(+keys[0])) {
            return keys.map(key => { return { key, value: value[key] } });
        }
    
        // Enum with automatic index or numeric value
        return keys.filter(e => !isNaN(+e)).map(key => { return { key: value[key], value: +key, } });        
    }
}


// // With an Enum:

// enum MyEnum {
//     A = 'a',
//     B = 'b'
// };

// MyEnum | KeyValuePipe gives [{key: 'A', value: 'a'}, {key: 'B', value: 'b'}];


// // With an object

// const myObject: any = {
//     a: 'aa',
//     b: 'bb'
// };

// myObject | KeyValuePipe gives [{key: 'a', value: 'aa'}, {key: 'b', value: 'bb'}];