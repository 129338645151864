/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
// ---------------------------------------------------------------------------------------------------
// Nestjs/swagger : DISABLED
// ---------------------------------------------------------------------------------------------------

export function ApiExtraModels(...args: unknown[]): PropertyDecorator { return function () {}}
export function ApiProperty(...args: unknown[]): PropertyDecorator { return function () {}}
export function ApiPropertyOptional(...args: unknown[]): PropertyDecorator { return function () {}}
export function Prop(...args: unknown[]): PropertyDecorator { return function () {}}
export function Schema(...args: unknown[]): ClassDecorator { return function () {}}


// ---------------------------------------------------------------------------------------------------
// Nestjs/mongoose : DISABLED
// ---------------------------------------------------------------------------------------------------

class EmptyClass {
    constructor() {}
    name(): void {}
}

export class SchemaFactory {
    static createForClass(...args: unknown[]) {
        return Object.getPrototypeOf(EmptyClass)
    }
}

// ---------------------------------------------------------------------------------------------------
// Class transformer : DISABLED
// ---------------------------------------------------------------------------------------------------

// export {
//     Transform,
//     Type
// } from 'class-transformer';

export function Transform(...args: unknown[]): PropertyDecorator { return function () {}}
export function Type(...args: unknown[]): PropertyDecorator { return function () {}}

export function Default(...args: unknown[]): PropertyDecorator { return function () {}}

// ---------------------------------------------------------------------------------------------------
// Class validator
// ---------------------------------------------------------------------------------------------------

// To prevent the warning about ' CommonJS or AMD dependencies can cause optimization bailouts.' allow 'validator' as allowedCommonJsDependency in the project.json file of Angular projects
// "allowedCommonJsDependencies": [
//     "validator"
// ],

export {
    Allow,
    IsDefined,
    IsOptional,
    Validate,
    ValidateBy,
    ValidateIf,
    ValidateNested,
    ValidatePromise,
    IsLatLong,
    IsLatitude,
    IsLongitude,
    Equals,
    NotEquals,
    IsEmpty,
    IsNotEmpty,
    IsIn,
    IsNotIn,
    IsDivisibleBy,
    IsPositive,
    IsNegative,
    Max,
    Min,
    MinDate,
    MaxDate,
    Contains,
    NotContains,
    IsAlpha,
    IsAlphanumeric,
    IsDecimal,
    IsAscii,
    IsBase64,
    IsByteLength,
    IsCreditCard,
    IsCurrency,
    IsEmail,
    IsFQDN,
    IsFullWidth,
    IsHalfWidth,
    IsVariableWidth,
    IsHexColor,
    IsHexadecimal,
    IsMACAddress,
    IsIP,
    IsPort,
    IsISBN,
    IsISIN,
    IsISO8601,
    IsJSON,
    IsJWT,
    IsLowercase,
    IsMobilePhone,
    IsISO31661Alpha2,
    IsISO31661Alpha3,
    IsMongoId,
    IsMultibyte,
    IsSurrogatePair,
    IsUrl,
    IsUUID,
    IsFirebasePushId,
    IsUppercase,
    Length,
    MaxLength,
    MinLength,
    Matches,
    IsPhoneNumber,
    IsMilitaryTime,
    IsHash,
    IsISSN,
    IsDateString,
    IsBooleanString,
    IsNumberString,
    IsBase32,
    IsBIC,
    IsBtcAddress,
    IsDataURI,
    IsEAN,
    IsEthereumAddress,
    IsHSL,
    IsIBAN,
    IsIdentityCard,
    IsISRC,
    IsLocale,
    IsMagnetURI,
    IsMimeType,
    IsOctal,
    IsPassportNumber,
    IsPostalCode,
    IsRFC3339,
    IsRgbColor,
    IsSemVer,
    IsStrongPassword,
    IsTimeZone,
    IsBase58,
    IsBoolean,
    IsDate,
    IsNumber,
    IsEnum,
    IsInt,
    IsString,
    IsArray,
    IsObject,
    ArrayContains,
    ArrayNotContains,
    ArrayNotEmpty,
    ArrayMinSize,
    ArrayMaxSize,
    ArrayUnique,
    IsNotEmptyObject,
    IsInstance,
} from 'class-validator';




// To disable:
// export function Allow(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsDefined(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsOptional(...args: unknown[]): PropertyDecorator { return function () {}}
// export function Validate(...args: unknown[]): PropertyDecorator { return function () {}}
// export function ValidateBy(...args: unknown[]): PropertyDecorator { return function () {}}
// export function ValidateIf(...args: unknown[]): PropertyDecorator { return function () {}}
// export function ValidateNested(...args: unknown[]): PropertyDecorator { return function () {}}
// export function ValidatePromise(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsLatLong(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsLatitude(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsLongitude(...args: unknown[]): PropertyDecorator { return function () {}}
// export function Equals(...args: unknown[]): PropertyDecorator { return function () {}}
// export function NotEquals(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsEmpty(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsNotEmpty(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsIn(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsNotIn(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsDivisibleBy(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsPositive(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsNegative(...args: unknown[]): PropertyDecorator { return function () {}}
// export function Max(...args: unknown[]): PropertyDecorator { return function () {}}
// export function Min(...args: unknown[]): PropertyDecorator { return function () {}}
// export function MinDate(...args: unknown[]): PropertyDecorator { return function () {}}
// export function MaxDate(...args: unknown[]): PropertyDecorator { return function () {}}
// export function Contains(...args: unknown[]): PropertyDecorator { return function () {}}
// export function NotContains(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsAlpha(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsAlphanumeric(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsDecimal(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsAscii(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsBase64(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsByteLength(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsCreditCard(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsCurrency(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsEmail(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsFQDN(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsFullWidth(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsHalfWidth(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsVariableWidth(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsHexColor(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsHexadecimal(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsMACAddress(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsIP(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsPort(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsISBN(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsISIN(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsISO8601(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsJSON(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsJWT(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsLowercase(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsMobilePhone(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsISO31661Alpha2(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsISO31661Alpha3(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsMongoId(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsMultibyte(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsSurrogatePair(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsUrl(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsUUID(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsFirebasePushId(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsUppercase(...args: unknown[]): PropertyDecorator { return function () {}}
// export function Length(...args: unknown[]): PropertyDecorator { return function () {}}
// export function MaxLength(...args: unknown[]): PropertyDecorator { return function () {}}
// export function MinLength(...args: unknown[]): PropertyDecorator { return function () {}}
// export function Matches(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsPhoneNumber(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsMilitaryTime(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsHash(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsISSN(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsDateString(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsBooleanString(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsNumberString(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsBase32(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsBIC(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsBtcAddress(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsDataURI(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsEAN(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsEthereumAddress(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsHSL(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsIBAN(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsIdentityCard(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsISRC(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsLocale(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsMagnetURI(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsMimeType(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsOctal(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsPassportNumber(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsPostalCode(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsRFC3339(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsRgbColor(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsSemVer(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsStrongPassword(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsTimeZone(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsBase58(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsBoolean(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsDate(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsNumber(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsEnum(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsInt(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsString(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsArray(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsObject(...args: unknown[]): PropertyDecorator { return function () {}}
// export function ArrayContains(...args: unknown[]): PropertyDecorator { return function () {}}
// export function ArrayNotContains(...args: unknown[]): PropertyDecorator { return function () {}}
// export function ArrayNotEmpty(...args: unknown[]): PropertyDecorator { return function () {}}
// export function ArrayMinSize(...args: unknown[]): PropertyDecorator { return function () {}}
// export function ArrayMaxSize(...args: unknown[]): PropertyDecorator { return function () {}}
// export function ArrayUnique(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsNotEmptyObject(...args: unknown[]): PropertyDecorator { return function () {}}
// export function IsInstance(...args: unknown[]): PropertyDecorator { return function () {}}

// export function IsObjectId(...args: unknown[]): PropertyDecorator { return function () {}}
