import { Expose } from 'class-transformer';
import { IsString, IsOptional } from '@lightning/decorators';

export class TrackingCreateRequest {

    @Expose()
    @IsString()
    @IsOptional()
    public from?: string;

    @Expose()
    @IsString()
    @IsOptional()
    public guest?: string;
}

