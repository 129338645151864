<ng-container *ngIf="report && report.timeline.length">

    <!-- overview components -->
    <lui-operation-teams-ranking *ngIf="overview.teamsRanking" wuiTacticalCorners
        [teams]="selectedTimelineItem?.context.teams"
        [pointsCurrency]="report.settings.pointsCurrency"
        [pointsLimit]="report.settings.pointsLimit"></lui-operation-teams-ranking>

    <lui-operation-teams-roles *ngIf="overview.teamsRoles" wuiTacticalCorners
        [teams]="selectedTimelineItem?.context.teams"
        [pointsCurrency]="report.settings.pointsCurrency"
        [pointsLimit]="report.settings.pointsLimit"></lui-operation-teams-roles>

    <lui-operation-teams-progress *ngIf="overview.teamsProgress" wuiTacticalCorners
        [teams]="selectedTimelineItem?.context.teams"></lui-operation-teams-progress>

    <lui-operation-areas-roles *ngIf="overview.areasRoles" wuiTacticalCorners
        [devices]="selectedTimelineItem?.context.devices"></lui-operation-areas-roles>

    <lui-operation-areas-captures *ngIf="overview.areasCaptures" wuiTacticalCorners
        [devices]="selectedTimelineItem?.context.devices"
        [teams]="selectedTimelineItem?.context.teams"></lui-operation-areas-captures>

    <!-- timeline -->
    <lui-timeline wuiTacticalCorners
        [customIconsRootUrl]="customIconsRootUrl"
        [items]="report.timeline"
        [selectedIndex]="selectedTimelineItemIndex"
        [scrolling]="isReplaying"
        (itemClick)="timelineItemClick($event)"></lui-timeline>

    <!-- only when completed -->
    <ng-container *ngIf="selectedTimelineItemIndex === report.timeline.length - 1">

        <!-- winner -->
        <lui-operation-winner *ngIf="report.winner"
            [winner]="report.winner">
        </lui-operation-winner>

        <!-- trophies -->
        <lui-operation-trophies *ngIf="report.trophies"
            [trophies]="report.trophies"
            (trophyClick)="trophyClick.emit($event)">
        </lui-operation-trophies>

    </ng-container>

</ng-container>
