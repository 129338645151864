import { Component, Input, Output, EventEmitter, IterableDiffers, IterableDiffer, DoCheck, ChangeDetectionStrategy } from '@angular/core';
import { TimelineItem } from '@lightning/lightning-definitions';
import { UncapitalizePipe } from '@lightning/wild-ui';

@Component({
    selector: 'lui-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})

export class TimelineComponent implements DoCheck {

    public selectedIndex = -1;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('selectedIndex')
    public set selectedIndexProxy(index: number) {

        if (this.selectedIndex === index) {
            return;
        }

        this.selectedIndex = index;

        this.playSound(this.items[this.selectedIndex].action);
    }

    @Input()
    public items: Array<TimelineItem> = [];

    @Input()
    public scrolling = true;

    @Input()
    private iconsPath = 'assets/operations/timeline/icons';

    @Input()
    public iconsExtension = '.svg';

    @Input()
    public customIconsRootUrl = '';

    @Input()
    public soundsPath = 'assets/operations/timeline/sounds';

    @Input()
    public soundsExtension = '.mp3';

    @Input()
    public translatePath = 'timeline';

    @Output()
    public itemClick: EventEmitter<{ index: number, item: TimelineItem }> =
        new EventEmitter<{ index: number, item: TimelineItem }>();

    private timelineIterableDiffer: IterableDiffer<TimelineItem>;

    constructor(
        private iterableDiffers: IterableDiffers,
        private uncapitalizePipe: UncapitalizePipe) {

        this.timelineIterableDiffer = this.iterableDiffers.find([]).create(undefined);
    }

    ngDoCheck() {

        // Detect arrival of new entries in the timeline array
        // (a simple setter can't detect it)
        // Then play a sound only when one single entry was added
        const timelineChanges = this.timelineIterableDiffer.diff(this.items);

        if (timelineChanges) {

            let added = 0;

            timelineChanges.forEachAddedItem(() => {
                added++;
            });

            if (added === 1) {
                this.playSound(this.items[this.items.length - 1].action);
            }
        }
    }

    public click(index: number, item: TimelineItem): void {
        this.itemClick.emit({ index, item });
    }

    public getTimestamp(time: Date): number {

        const timestamp: number =
            (new Date(time)).getTime() - new Date(this.items[0].time).getTime();

        return timestamp - 3600000;
    }

    public getActionImage(action: string): string {
        return [this.iconsPath, this.uncapitalizePipe.transform(action)].join('/') + this.iconsExtension;
    }

    public getSecondaryActionImage(path: string | undefined): string {
        return path ? [this.customIconsRootUrl, path].join('/') : '';
    }

    public getTitle(action: string): string {
        return [this.translatePath, this.uncapitalizePipe.transform(action), 'title'].join('.');
    }

    public getDescription(action: string): string {
        return [this.translatePath, this.uncapitalizePipe.transform(action), 'description'].join('.');
    }

    private playSound(action: string): void {
        new Audio([this.soundsPath, this.uncapitalizePipe.transform(action)].join('/') + this.soundsExtension).play();
    }

}
