import { NgModule } from '@angular/core';
import { RouterModule, Routes, provideRouter, withComponentInputBinding } from '@angular/router';
import { NotFoundComponent } from './fallback/components/not-found/not-found.component';
import { RestrictedComponent } from './fallback/components/restricted/restricted.component';
import { DevelopmentOnlyGuard } from './shared/guards/development-only/development-only.guard';

const routes: Routes = [
    { path: '',                     redirectTo: 'discovery', pathMatch: 'full', runGuardsAndResolvers: 'always' },
    { path: 'discovery',            loadChildren: () => import('./discovery/discovery.module').then(children => children.DiscoveryModule) },
    { path: 'account',              loadChildren: () => import('./account/account.module').then(children => children.AccountModule) },
    { path: 'operations',           loadChildren: () => import('./operations/operations.module').then(children => children.OperationsModule) },
    { path: 'operators',            loadChildren: () => import('./operators/operators.module').then(children => children.OperatorsModule) },
    { path: 'photographies',        loadChildren: () => import('./photographies/photographies.module').then(children => children.PhotographiesModule) },
    { path: 'pmr',                  loadChildren: () => import('./pmr/pmr.module').then(children => children.PmrModule) },
    { path: 'downloads',            loadChildren: () => import('./downloads/downloads.module').then(children => children.DownloadsModule) },
    { path: 'store',                loadChildren: () => import('./store/store.module').then(children => children.StoreModule)/*, canActivate: [ DevelopmentOnlyGuard ]*/}, ////////////////// REMOVE tHIS GUARD TO RELEASE THE STORE ///////////////////////
    { path: 'about',                loadChildren: () => import('./about/about.module').then(children => children.AboutModule) },
    { path: 'himitsu',              loadChildren: () => import('./secret/secret.module').then(children => children.SecretModule) },

    { path: 'OPR/:id',              redirectTo: '/operators/:id?landingFrom=nfcTag' },

    { path: 'restricted',           component: RestrictedComponent },
    { path: '**',                   component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload',  ///
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
    })],
    exports: [
        RouterModule
    ],
    providers: [
        provideRouter(routes, withComponentInputBinding()),
    ]
})

export class AppRoutingModule { }
