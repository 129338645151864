import { Injectable, signal } from '@angular/core';

const UPDATE_INTERVAL = 1000;

@Injectable({
    providedIn: 'root'
})
export class TimeService {

    public now = signal<Date>(new Date());

    constructor() {

        setInterval(() => this.now.set(new Date()), UPDATE_INTERVAL);
    }
}
