import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[wuiBackgroundImage]'
})
export class BackgroundImageDirective {

    @Input('wuiBackgroundImage')
    public set url(value: string | undefined) {
        this.update(value || '');
    }

    constructor(private elementRef: ElementRef) { }

    private update(value: string) {
        this.elementRef.nativeElement.style.backgroundImage = 'url(' + encodeURI(value) + ')';
    }

}
