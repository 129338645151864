import { apps } from '../environments/apps.environment';

export class AppsUtils {

    /**
     * This method resolves the url of an app for the current environment
     * It's dynamic (based of the current window location and knows the network configuration of each app), perfect for blue / green
     * @param appName The name of the app to target
     * @returns The url of the app
     */
    public static resolveAppUrl(appName: string): string {

        const location = globalThis.location;

        // Require to be run in a browser
        if (location === undefined) {

            console.warn(`Application url resolver: The location of the current application is unknown.`);
            console.warn(`If this app is an express backend, please use the locate middleware from this library:`);
            console.warn(`app.use(locate());`);

            return '';
        }

        if (location.protocol === 'file:') {

            console.warn(`Application url resolver: The resolution can't be make because the location of the current application is a file.`);
            console.warn(`You can ignore this warning if you are using Electron.`);

            return '';
        }

        // Find the app from the configuration
        const app = apps
            .find(app => app.name === appName);

        if (!app) {

            console.warn(`Application url resolver: The application ${appName} doesn't exists:`);
            console.warn(apps);

            return '';
        }

        // If running localhost or with an ip address instead of an host name
        if (location.hostname === 'localhost' || /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(location.hostname)) {

            return `${location.protocol}//${location.hostname}:${app.localPort}`;
        }

        // Decompose the current hostname into segments
        const hostnNameElements: Array<string> = location.hostname.split('.');

        // Get subdomains used by apps
        const appsSubdomains = apps
            .map(app => app.hasSubdomain === false ? null : app.name)
            .filter(subdomainName => subdomainName);

        // If the first segment is used by the current app, remove it
        if (appsSubdomains.indexOf(hostnNameElements[0]) >= 0) {

            hostnNameElements.splice(0, 1);
        }

        // Add the name of the wanted app
        if (app.hasSubdomain !== false) {

            hostnNameElements.splice(0, 0, app.name);
        }

        return `${location.protocol}//${hostnNameElements.join('.')}`;
    }

    public static getPort(appName: string): number {

        const app = apps
            .find(app => app.name === appName);

        return app?.localPort || 0;
    }
}
