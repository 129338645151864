import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../../utils/string.utils';

@Pipe({
    name: 'uncapitalize'
})
export class UncapitalizePipe implements PipeTransform {

    transform(value: string): string {
        return StringUtils.uncapitalize(value);
    }
}
