<div class="title">
    <ng-content select="[title]"></ng-content>
</div>

<div class="header">
    <ng-content select="[header]"></ng-content>
</div>

<div class="body">
    <ng-content select="[body]"></ng-content>
</div>

<div class="footer">
    <ng-content select="[footer]"></ng-content>
</div>

