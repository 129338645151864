import { Component, Input, OnInit } from '@angular/core';
import { StoreProduct, StoreProductOptionCheckbox, StoreProductOptionText } from '@lightning/lightning-definitions';
import { StoreService } from '../../services/store.service';
import { InputFormat, OverlayService } from '@lightning/wild-ui';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-store-add-to-cart',
    templateUrl: './store-add-to-cart.component.html',
    styleUrls: ['./store-add-to-cart.component.scss'],
})
export class StoreAddToCartComponent implements OnInit {

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public resolve = (success: boolean) => Function;

    @Input()
    public product: StoreProduct = new StoreProduct();

    public hasInvalidOptions = false;

    constructor(
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private storeService: StoreService) { }

    public ngOnInit(): void {

        this.checkOptions();
    }

    public checkOptions(): void {

        // Check options validity
        this.hasInvalidOptions = this.product.options
            .some(option => option.isRequired && !option.value);

        this.storeService.updateItemComputedValues(this.product);
    }

    public getOptionPattern(option: StoreProductOptionText | StoreProductOptionCheckbox): string {

        return 'pattern' in option ? option.pattern : '';
    }

    public getOptionFormat(option: StoreProductOptionText | StoreProductOptionCheckbox): InputFormat {

        return 'format' in option ? option.format : '';
    }

    public cancel(): void {

        this.resolve(false);

        this.overlayService.closeModal();
    }

    public add(): void {

        if(this.hasInvalidOptions) {

            return;
        }

        this.storeService.addToCart(this.product);

        this.resolve(true);

        this.overlayService.closeModal();
    }

}
