import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'appStatusIcon',
    templateUrl: './status-icon.component.html',
    styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent implements OnInit {

    @Input()
    public icon: string = '';

    @Input()
    public message: string = '';

    constructor() { }

    ngOnInit(): void {

    }

}
