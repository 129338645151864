export class FilesUtils {

    public static save(content: any, fileName: string): void {

        const blob = new Blob([content], { type: 'application/octet-stream' });

        const link = document.createElement('a');

        link.style.display = 'none';
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        link.remove();
    }

    public static load(accept = '', isText = true): Promise<string | ArrayBuffer | null> {

        const input = document.createElement('input');

        input.style.display = 'none';
        input.type = 'file';
        input.accept = accept;
        input.click();

        return new Promise(resolve => {

            input.oninput = () => {

                if (!input.files || input.files.length === 0) {
                    return;
                }

                const fileReader = new FileReader();

                fileReader.onload = () => {
                    resolve(fileReader.result);
                }

                if (isText) {
                    fileReader.readAsText(input.files[0]);
                } else {
                    fileReader.readAsArrayBuffer(input.files[0]);
                }

            };
        });
    }

    public static arrayBufferToBase64(data: ArrayBuffer): string {

        const bytes = new Uint8Array(data);

        let binary = '';

        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }

        return window.btoa(binary);
    }
}
