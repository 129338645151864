import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'wui-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent implements OnInit {

    public realValue: any;

    public isInvalid = false;

    @Input()
    public autofocus = false;

    @Input()
    public required = false;

    @Input()
    public type = '';

    public get value() {
        return this.realValue;
    }

    @Input()
    public set value(value: any) {

        if (this.type === 'number') {
            value = parseInt(value, 10);
        } else if(this.type === 'object') {
            try {
                // To use objects, use the json pipe
                // <option *ngFor="let item of items" [value]="item | json">
                // Works to get the value but not to preselect the right item
                value = JSON.parse(value);
            } catch(error) {
                console.warn(`Select: Unable to parse object ${value}`);
            }
        }

        this.realValue = value;

        this.valueChange.emit(this.realValue);

        this.checkValidity();
    }

    @Output()
    public valueChange = new EventEmitter();

    constructor(private elementRef: ElementRef) { }

    ngOnInit(): void {

        if (this.autofocus) {
            this.elementRef.nativeElement.querySelector('select').focus();
        }

        this.checkValidity();
    }

    public onChange(value: any): void {

        this.value = value;
    }

    public checkValidity(): void {

        this.isInvalid = (this.required && !this.realValue);

        // Fire as a null value when invalid, that make the checking easier

        if (this.isInvalid) {
            setTimeout(() => this.valueChange.emit(null), 0);
        }
    }

}
