import { OperationTrophy } from './operation-trophy.entity';
import { OperationWinner } from './operation-winner.entity';
import { OperatorUnderOperation } from './operator.interface';
import { TimelineItem } from './timeline-item.entity';

export const OPERATION_REPORT_VERSION = 1;

export class OperationReportPreview {

    public id?: any;

    public version = OPERATION_REPORT_VERSION;
    public mode = '';
    public sessionId = '';

    public begin: Date = new Date();
    public end: Date = new Date();
    public operators: Array<OperatorUnderOperation> = [];

    public winner?: OperationWinner;

    public photographiesCount?: number = 0;
}

export class OperationReport extends OperationReportPreview {

    public authorAccountId?: string = '';

    public settings: any;
    public timeline: Array<TimelineItem> = [];

    public trophies?: Array<OperationTrophy>;
}
