import { Directive, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[wuiPointerStart]'
})

export class PointerStartDirective implements OnInit {

    @Output('wuiPointerStart')
    private startEvent = new EventEmitter();

    constructor(private elementRef: ElementRef) {
    }

    public ngOnInit() {

        // Get element
        const elementTarget = this.elementRef.nativeElement;

        // Start to click or touch
        elementTarget.addEventListener('mousedown', (event: MouseEvent) => this.start(event));
        elementTarget.addEventListener('touchstart', (event: TouchEvent) => this.start(event));
    }

    private start(event: MouseEvent | TouchEvent): void {

        event.preventDefault();

        this.startEvent.emit();
    }

}
