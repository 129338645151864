import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayExtract'
})
export class ArrayExtractPipe implements PipeTransform {

    transform(value: Array<any>, property: string): Array<any> {

        // Todo: use deepfind for multi level instead
        return value.map(item => item[property]);
    }
}
