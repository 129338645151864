export enum SoftwaresChannels {

    Main =              'Main',
    Release =           'Release',
    Development =       'Development',
}

// According to the change logs: https://docs.google.com/spreadsheets/d/1o78inlUDSRLifdAl_nfHLrGYTRy0I3tVSuNXPgwetnQ/edit#gid=0
/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum MinimalCompatibleVersions {

    HeadquarterGateway =    '1.0.X',
    HeadquarterGatewaySd =  '1.0.X',

    GroundModule =          '2.2.X',
    MedicModule =           '1.0.X',
    LockModule =            '1.0.X',

    NfcTagOperator =        '1.0.X',
    NfcTagGuest =           '1.0.X',
    NfcTagResource =        '1.0.X',
    NfcTagPart =            '1.0.X',
    NfcTagLocation =        '1.0.X',
    NfcTagSystem =          '1.0.X',
}

export const UnpublishedVersion = '0.0.0';
