import { OperatorUnderOperation } from '@lightning/lightning-definitions';

export class OperatorsUnderOperationAnalysisResult {

    public operatorsNames = '';

    public operatorsLinks = '';

    public operatorsCount = 0;

    public guestsCount = 0;

    public totalCount = 0;
}


export class AnalyzersUtils {

    public static analyzeOperatorsUnderOperation(operators: Array<OperatorUnderOperation>): OperatorsUnderOperationAnalysisResult {

        return {
            operatorsNames: operators
                .filter(operator => operator.isGuest === false)
                .map(operator => operator.name)
                .join(', '),
            operatorsLinks: operators
                .filter(operator => operator.isGuest === false)
                .map(operator => `<a href="/operators/${operator.number}">${operator.name}</a>`)
                .join(', '),
            operatorsCount: operators
                .filter(operator => operator.isGuest === false)
                .length,
            guestsCount: operators
                .filter(operator => operator.isGuest === true)
                .length,
            totalCount: operators
                .length
        };
    }

}
