import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[wuiReadOnly]'
})

export class ReadOnlyDirective {

    private _readOnly = false;

    private defaultPointerEvent = '';

    @Input('wuiReadOnly')
    public set disabled(value: boolean) {
        this._readOnly = value;
        this.update();
    }

    constructor(private elementRef: ElementRef) { }

    private update(): void {

        if (this.defaultPointerEvent === undefined) {
            this.defaultPointerEvent = this.elementRef.nativeElement.style.pointerEvents;
        }

        this.elementRef.nativeElement.style.pointerEvents = this._readOnly ? 'none' : this.defaultPointerEvent;
    }

}
