import { Directive, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[wuiPointerLong]'
})

export class PointerLongDirective implements OnInit {

    @Output('wuiPointerLong')
    private longEvent = new EventEmitter();

    private timer: any = undefined;

    constructor(private elementRef: ElementRef) {
    }

    public ngOnInit() {

        // Get element
        const elementTarget = this.elementRef.nativeElement;

        // Start to click or touch
        elementTarget.addEventListener('mousedown', (event: MouseEvent) => this.start(event));
        elementTarget.addEventListener('touchstart', (event: TouchEvent) => this.start(event));

        // End to click or touch
        elementTarget.addEventListener('mouseup', (event: MouseEvent) => this.end(event));
        elementTarget.addEventListener('touchend', (event: TouchEvent) => this.end(event));
    }

    private start(event: MouseEvent | TouchEvent): void {

        this.timer = setTimeout(() => {

            this.longEvent.emit();

            this.timer = undefined;

        }, 500);
    }

    private end(event: MouseEvent | TouchEvent): void {

        // To prevent the opening of the browser's contextual menu
        if (this.timer === undefined) {
            event.preventDefault();
        }

        clearTimeout(this.timer);
    }

}
