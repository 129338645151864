import { Directive, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[wuiPointerEnd]'
})

export class PointerEndDirective implements OnInit {

    @Output('wuiPointerEnd')
    // eslint-disable-next-line @angular-eslint/no-output-native
    private endEvent = new EventEmitter();

    constructor(private elementRef: ElementRef) {
    }

    public ngOnInit() {

        // Get element
        const elementTarget = this.elementRef.nativeElement;

        // End to click or touch
        elementTarget.addEventListener('mouseup', (event: MouseEvent) => this.end(event));
        elementTarget.addEventListener('touchend', (event: TouchEvent) => this.end(event));
    }

    private end(event: MouseEvent | TouchEvent): void {

        event.preventDefault();

        this.endEvent.emit();
    }

}
