import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TrackingCreateRequest } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { concatMap, filter, take, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TrackingService {

    private request = new TrackingCreateRequest();

    public get data(): TrackingCreateRequest {

        return this.request;
    }

    public constructor(
        activatedRoute: ActivatedRoute,
        onlineService: OnlineService) {

        // On page open
        activatedRoute.queryParams
            .pipe(filter(params => Object.keys(params).length > 0))
            .pipe(take(1))
            .pipe(tap((params: Params) => {

                const { from, visitor } = params;

                this.request = {
                    from,
                    visitor
                };
            }))
            .pipe(concatMap(_ => onlineService.track(this.request)))
            .subscribe();
    }

}
