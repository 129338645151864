import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loraRssiPercents'
})
export class LoraRssiPercentsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    // Typical limits for a Lora signal (rssi in dB)
    const rssiMin = -120;
    const rssiMax = -40;

    // Keeping into the limits
    value = value > rssiMax ? rssiMax : value;
    value = value < rssiMin ? rssiMin : value;

    // Convert to percents and return
    return Math.round((Math.abs(rssiMin) - Math.abs(value)) * 100 / (Math.abs(rssiMin) - Math.abs(rssiMax)));
  }

}
