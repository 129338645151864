import { DateUtils } from '../date/date.utils';
import { JSONUtils } from '../json/json.utils';

export class UuidUtils {

    public static getDayUniqueId(): string {

        const STORAGE_NAME = 'day-unique-id';

        const stored = localStorage.getItem(STORAGE_NAME);

        if (stored) {

            const data = JSON.parse(stored, JSONUtils.dateReviver);

            if (DateUtils.isToday(data.date)) {

                return data.id;
            }
        }

        const date = new Date();
              date.setHours(0, 0, 0);

        const id = UuidUtils.generateObjectId();

        localStorage.setItem(STORAGE_NAME, JSON.stringify({ date, id }));

        return id;
    }


    /** Generate a MongoDB's style object id */
    public static generateObjectId(): string {

        return UuidUtils.generateObjectIdFromDate(new Date());
    }

    public static generateObjectIdFromDate(date: Date): string {

        const timestamp = (date.getTime() / 1000 | 0).toString(16);

        return timestamp + 'xxxxxxxxxxxxxxxx'
            .replace(/[x]/g, () => (Math.random() * 16 | 0).toString(16))
            .toLowerCase();
    }

    public static getDateFromObjectId(objectId: string): Date {

        return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
    }






    /** Generate a uuid v4 */
    // public static generateUuidV4(): string {

    //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {

    //         const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);

    //         return v.toString(16);
    //     });
    // }

}
