<div class="item" wuiStopPropagation (click)="navigateTo('account')">
    {{ 'accounts.yours' | translate | uppercase }}
</div>

@let account = accountService.getAccount() | async;

<div class="item" wuiStopPropagation (click)="navigateTo(account?.operatorId ? '/operators/' + account?.operatorId : 'account/no-operator-profile')">
    {{ 'operators.yours' | translate | uppercase }}
</div>

<div class="item" wuiStopPropagation wuiDevelopmentOnly (click)="navigateTo('/store/orders')">
    {{ 'store.orders.yours' | translate | uppercase }}
</div>

<div class="item" wuiStopPropagation wuiDevelopmentOnly (click)="navigateTo('/operations/reports/edit')">
    {{ 'operations.reports.yours' | translate | uppercase }}
</div>
