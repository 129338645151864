export class JSONUtils {

    public static dateReviver(key: any, value: any) {

        const regExpIso = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

        if (typeof value === 'string' && regExpIso.exec(value)) {
            value = new Date(value);
        }

        return value;
    }
}
