
<div class="item" wuiStopPropagation (click)="navigateTo('discovery')">
    {{ 'discovery.title' | translate | uppercase }}
</div>

<div class="item" wuiStopPropagation (click)="navigateTo('operations/modes')" wuiDevelopmentOnly>
    {{ 'operations.modes.title' | translate | uppercase }}
</div>

<div class="item" wuiStopPropagation (click)="navigateTo('operations/reports')">
    {{ 'operations.reports.title' | translate | uppercase }}
</div>

<div class="item" wuiStopPropagation (click)="navigateTo('operators')">
    {{ 'operators.search.title' | translate | uppercase }}
</div>

<div class="item" wuiStopPropagation (click)="navigateTo('store')" wuiDevelopmentOnly>
    {{ 'store.title' | translate | uppercase }}
</div>

<div class="item" wuiStopPropagation (click)="navigateTo('photographies/upload')" wuiDevelopmentOnly>
    {{ 'photographies.title' | translate | uppercase }}
</div>

<div class="item" wuiStopPropagation (click)="navigateTo('pmr/baofeng')" wuiDevelopmentOnly>
    {{ 'pmr.baofeng.title' | translate | uppercase }}
</div>

<div class="item" wuiStopPropagation (click)="navigateTo('downloads')" wuiDevelopmentOnly>
    {{ 'downloads.title' | translate | uppercase }}
</div>

@if(isAdministrator) {

    <div class="item" wuiStopPropagation (click)="openBackoffice()">
        {{ 'shared.backoffice' | translate | uppercase }}
    </div>
}
