export enum OperationDeploymentDurations {
    m1 = 60000,
    m2 = 120000,
    m3 = 180000,
    m4 = 240000,
    m5 = 300000,
    m10 = 600000,
}

export enum OperationProcessDurations {
    unlimited = 0,
    m15 = 900000,
    m20 = 1200000,
    m25 = 1500000,
    m30 = 1800000,
    m45 = 2700000,
    m60 = 3600000,
}
