import { Directive, OnInit, ElementRef, Input } from '@angular/core';

export type TacticalCornersOptions = {
    infinite?: boolean;
    disappear?: boolean;

    noTop?: boolean;
    noRight?: boolean;
    noBottom?: boolean;
    noLeft?: boolean;

    noTopLeft?: boolean;
    noTopRight?: boolean;
    noBottomLeft?: boolean;
    noBottomRight?: boolean;
}

@Directive({
    selector: '[wuiTacticalCorners]'
})

export class TacticalCornersDirective implements OnInit {

    private isReady = false;
    private isEnabled = true;
    private options: any;

    private cornerTopLeft: HTMLDivElement;
    private cornerTopRight: HTMLDivElement;
    private cornerBottomLeft: HTMLDivElement;
    private cornerBottomRight: HTMLDivElement;

    @Input()
    public set wuiTacticalCorners(isEnabled: boolean | string) {

        // Value when the directive is used without [], enabled by default
        if (isEnabled === '') {
            isEnabled = true;
        }

        this.isEnabled = isEnabled as boolean;
        this.update();
    }

    @Input()
    public set wuiTacticalCornersOptions(options: TacticalCornersOptions) {

        this.options = options;
        this.update();
    }

    constructor(private elementRef: ElementRef) {

        // Create corners
        this.cornerTopLeft = document.createElement('div');
        this.cornerTopRight = document.createElement('div');
        this.cornerBottomLeft = document.createElement('div');
        this.cornerBottomRight = document.createElement('div');
    }

    public ngOnInit(): void {

        // Set the target position as 'relative' (if not defined)
        if (window.getComputedStyle(this.elementRef.nativeElement, null).getPropertyValue('position') === 'static') {
            this.elementRef.nativeElement.style.position = 'relative';
        }

        // Apply classes
        this.cornerTopLeft.className = 'cornerTopLeft';
        this.cornerTopRight.className = 'cornerTopRight';
        this.cornerBottomLeft.className = 'cornerBottomLeft';
        this.cornerBottomRight.className = 'cornerBottomRight';

        // Append corners to the target
        this.elementRef.nativeElement.appendChild(this.cornerTopLeft);
        this.elementRef.nativeElement.appendChild(this.cornerTopRight);
        this.elementRef.nativeElement.appendChild(this.cornerBottomLeft);
        this.elementRef.nativeElement.appendChild(this.cornerBottomRight);

        this.isReady = true;

        //
        this.update();
    }

    private update(): void {

        if (!this.isReady) {
            return;
        }

        if (this.options?.noTop) {
            this.options.noTopLeft = this.options.noTopRight = true;
        }

        if (this.options?.noRight) {
            this.options.noTopRight = this.options.noBottomRight = true;
        }

        if (this.options?.noBottom) {
            this.options.noBottomLeft = this.options.noBottomRight = true;
        }

        if (this.options?.noLeft) {
            this.options.noTopLeft = this.options.noBottomLeft = true;
        }

        this.cornerTopLeft.style.display = (!this.isEnabled || this.options?.noTopLeft) ? 'none' : 'block';
        this.cornerTopRight.style.display = (!this.isEnabled || this.options?.noTopRight) ? 'none' : 'block';
        this.cornerBottomLeft.style.display = (!this.isEnabled || this.options?.noBottomLeft) ? 'none' : 'block';
        this.cornerBottomRight.style.display = (!this.isEnabled || this.options?.noBottomRight) ? 'none' : 'block';

        this.cornerTopLeft.style.animationIterationCount =
        this.cornerTopRight.style.animationIterationCount =
        this.cornerBottomLeft.style.animationIterationCount =
        this.cornerBottomRight.style.animationIterationCount = this.options?.infinite ? 'infinite' : '1';

        if (this.options?.disappear !== false) {
            this.cornerTopLeft.classList.add('cornerAppearAndDisappear');
            this.cornerTopRight.classList.add('cornerAppearAndDisappear');
            this.cornerBottomLeft.classList.add('cornerAppearAndDisappear');
            this.cornerBottomRight.classList.add('cornerAppearAndDisappear');
        } else {
            this.cornerTopLeft.classList.add('cornerAppear');
            this.cornerTopRight.classList.add('cornerAppear');
            this.cornerBottomLeft.classList.add('cornerAppear');
            this.cornerBottomRight.classList.add('cornerAppear');
        }

    }

}
