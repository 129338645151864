import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[wuiDisabled]'
})

export class DisabledDirective {

    private isDisabled = false;
    private defaultPointerEvent = '';
    private defaultOpacity = '';

    @Input('wuiDisabled')
    public set disabled(value: boolean) {
        this.isDisabled = value;
        this.update();
    }

    constructor(private elementRef: ElementRef) {
    }

    private update(): void {

        if (this.defaultPointerEvent === undefined) {
            this.defaultPointerEvent = this.elementRef.nativeElement.style.pointerEvents;
            this.defaultOpacity = this.elementRef.nativeElement.style.opacity;
        }

        this.elementRef.nativeElement.style.pointerEvents = this.isDisabled ? 'none' : this.defaultPointerEvent;
        this.elementRef.nativeElement.style.opacity = this.isDisabled ? '0.5' : this.defaultOpacity;
    }

}
