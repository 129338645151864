import { Component, Input, Output, EventEmitter } from '@angular/core';

import { OperationTrophy } from '@lightning/lightning-definitions';

@Component({
    selector: 'lui-operation-trophies',
    templateUrl: './operation-trophies.component.html',
    styleUrls: ['./operation-trophies.component.scss']
})
export class OperationTrophiesComponent {

    @Input()
    public trophies = new Array<OperationTrophy>();

    @Input()
    public logoPath = 'assets/operations/trophies/logos';

    @Input()
    public logoExtension = '.svg';

    @Input()
    public translatePath = 'trophies';

    @Output()
    public trophyClick: EventEmitter<OperationTrophy> = new EventEmitter();

}
