import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[wuiElectronOnly]'
})

export class ElectronOnlyDirective {

    constructor(private elementRef: ElementRef) {

        if (navigator.userAgent.indexOf('Electron') < 0) {
            this.elementRef.nativeElement.style.display = 'none';
        }
    }
}
