import { LoraDevice } from './lora-devices.entity';
export class LoraPacket {

    public protocolVersion!: number;

    public senderId!: string;

    public packetNumber!: number;

    public destinationId!: string;

    public rebounds!: number;

    public payloadNumber!: number;

    public payload!: string | any;

    public rssi!: number;
}

export class LoraReception {

    public device!: LoraDevice;

    public packet!: LoraPacket;
}
