import { Component, Input } from '@angular/core';

@Component({
  selector: 'wui-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input()
  public tag = '';

  @Input()
  public title = '';

  @Input()
  public logo = '';

  @Input()
  public description = '';

  @Input()
  public data: any;

}
