<ng-container *ngFor="let team of teams">
    <div class="team">
        <div class="title" [style.backgroundColor]="team.color">
            {{ 'shared.team' | translate }} {{ team.id }}
        </div>
        <div class="body">
            <div class="steps">
                <div *ngFor="let step of team.data.steps">
                    {{ step.isDone ? '▯' : '_'}}
                </div>
            </div>
            <div class="progress">
                {{ getDoneStepsCount(team) }} / {{ getTotalStepsCount(team) }}
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="teams.length === 0">⚠ no available teams</div>
