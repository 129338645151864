<wui-modal-layout>

    <div title>{{ 'store.title' | translate }}</div>

    <div body class="body">

        <div class="image" [wuiBackgroundImage]="'assets/store/products/' + (product.name | uncapitalize) + '.jpg'"></div>

        <div class="informations">

            <!-- Title -->
            <div class="title">
                {{ 'store.products.' + (product.name | uncapitalize) + '.title' | translate | capitalize }}
            </div>

            <!-- Description -->
            <div class="description">
                {{ 'store.products.' + (product.name | uncapitalize) + '.overview.whatIs.description' | translate }}
            </div>

            <!-- Generalities -->
            <wui-section [name]="'shared.generalities' | translate">

                <wui-entry>
                    <div name>{{ 'shared.price' | translate | capitalize }}</div>
                    <div detail>{{ 'store.products.' + (product.name | uncapitalize) + '.cart.price.detail' | translate | capitalize }}</div>
                    <div value>{{ product.price.total | currency: 'EUR' }} {{ product.price.period | translate }}</div>
                </wui-entry>

                <wui-entry>
                    <div name>{{ 'shared.quantity' | translate | capitalize }}</div>
                    <div detail>{{ 'store.products.' + (product.name | uncapitalize) + '.cart.quantity.detail' | translate | capitalize }}</div>
                    <wui-input value
                        [(value)]="product.quantity.value"
                        type="number"
                        [min]="product.quantity.min"
                        [max]="product.quantity.max"
                        (valueChange)="checkOptions()"
                        [wuiDisabled]="product.quantity.max === 1">
                    </wui-input>
                </wui-entry>

            </wui-section>

            <!-- Options -->
            <wui-section [name]="'shared.options' | translate" *ngIf="this.product.options.length > 0">

                <wui-entry *ngFor="let option of this.product.options">
                    <div name>{{ 'store.products.' + (product.name | uncapitalize) + '.cart.options.' + option.name + '.name' | translate | capitalize }}</div>
                    <div detail>{{ 'store.products.' + (product.name | uncapitalize) + '.cart.options.' + option.name + '.detail' | translate | capitalize }}</div>
                    <wui-input value
                        [(value)]="option.value"
                        [type]="option.type"
                        [pattern]="getOptionPattern(option)"
                        [required]="option.isRequired"
                        (valueChange)="checkOptions()">
                    </wui-input>
                </wui-entry>

            </wui-section>

        </div>

    </div>

    <wui-buttons footer>
        <wui-button (click)="cancel()">
            {{ 'shared.cancel' | translate }}
        </wui-button>
        <wui-button class="store" (click)="add()" [wuiDisabled]="hasInvalidOptions">
            {{ 'store.cart.add' | translate }}
        </wui-button>
    </wui-buttons>

</wui-modal-layout>
