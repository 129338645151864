export enum StoreProductsStatus {
    Soon = 'soon',
    New = 'new',
    Available = 'available',
    OutOfStock = 'outOfStock',
}

export enum StoreOrderStatus {
    Canceled = 'canceled',

    Unpaid = 'unpaid',
    PaymentInProgress = 'paymentInProgress',
    PaymentFailed = 'paymentFailed',
    Paid = 'paid',

    PayOnDelivery = 'payOnDelivery',

    ReadyForShipping = 'readyForShipping',
    Shipped = 'shipped',

    Complete = 'complete',
}

