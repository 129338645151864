import { ApiProperty } from '@lightning/decorators';
import { ProductsWithSoftware } from '../enums/products.enum';
import { SoftwaresChannels } from '../enums/softwares.enum';
import { SementicVersion } from './sementic-version.entity';

export class Hardware {

    @ApiProperty({ enum: ProductsWithSoftware })
    public product!: ProductsWithSoftware;

    @ApiProperty({ type: SementicVersion })
    public hardwareVersion!: SementicVersion;
}

export class Firmware {

    public id?: any;

    public name!: string;

    public version!: SementicVersion;

    public description!: string;

    public channel!: SoftwaresChannels;

    @ApiProperty({ type: Hardware })
    public target!: Hardware;

    public release?: Date;
}
