import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[wuiScroll]'
})

export class ScrollDirective {

    private isScrolled = false;

    @Input('wuiScroll')
    public set enabled(value: boolean) {
        this.isScrolled = value;
        this.update();
    }

    @Input('wuiScrollOptions')
    public options = {};

    constructor(private elementRef: ElementRef) { }

    private update(): void {

        if (this.isScrolled) {
            this.elementRef.nativeElement.scrollIntoView(this.options);
        }
    }
}
