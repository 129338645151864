
export class AccountsDashboard {

    public total = 0;

    public statuses: { [x: string]: number; } = { };

    // ...
}

export class OperatorsDashboard {

    public total = 0;

    public statuses: { [x: string]: number; } = { };

    public legends = 0;
}

export class OperationsReportsDashboard {

    public total = 0;

    public modes: { [x: string]: number; } = { };

}

export class OperationsPhotographiesDashboard {

    public total = 0;

}

export class StoreOrdersDashboard {

    public total = 0;

    public statuses: { [x: string]: number; } = { };
}

export class DevicesDashboard {

    public total = 0;

    public products: { [x: string]: number; } = { };

}

export class Dashboard {

    public accounts = new AccountsDashboard();

    public operators = new OperatorsDashboard();

    public operations = { reports: new OperationsReportsDashboard(), photographies: new OperationsPhotographiesDashboard() };

    public store = { orders: new StoreOrdersDashboard() };

    public devices = new DevicesDashboard();
}
