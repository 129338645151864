import { Component, Input } from '@angular/core';

import { TeamUnderOperation } from '@lightning/lightning-definitions';

@Component({
    selector: 'lui-operation-teams-ranking',
    templateUrl: './operation-teams-ranking.component.html',
    styleUrls: ['./operation-teams-ranking.component.scss']
})

export class OperationTeamsRankingComponent {

    @Input()
    public teams: Array<TeamUnderOperation> = [];

    @Input()
    public pointsCurrency = ' ';

    @Input()
    public pointsLimit = 0;

}
