<wui-modal-layout>

    <div title>{{ title | translate }}</div>

    <div body>

        {{ message | translate }}

    </div>

    <div footer>
        <wui-buttons>
            <wui-button (click)="no()">{{ 'shared.no' | translate }}</wui-button>
            <wui-button (click)="yes()">{{ 'shared.yes' | translate }}</wui-button>
        </wui-buttons>
    </div>

</wui-modal-layout>
