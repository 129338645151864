export class TimelineItem {

    public time!: Date;

    public level!: number;

    public action!: string;

    public data?: any;

    public customIconPath?: string;

    public context?: any;

    public image?: {
        url: string;
    };
}

export class TimelineItemWithTranslation extends TimelineItem {

    public title!: string;

    public description!: string;
}
