import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OperationReport, OperationTrophy, TimelineItem } from '@lightning/lightning-definitions';

@Component({
    selector: 'lui-operation-report',
    templateUrl: './operation-report.component.html',
    styleUrls: ['./operation-report.component.scss']
})

export class OperationReportComponent implements OnInit, OnDestroy {

    @Input()
    public report: OperationReport | undefined;

    @Input()
    public customIconsRootUrl = '';

    @Output()
    public trophyClick: EventEmitter<OperationTrophy> = new EventEmitter();

    public overview = {
        teamsRanking: false,
        teamsRoles: false,
        teamsProgress: false,
        areasRoles: false,
        areasCaptures: false
    };

    public selectedTimelineItemIndex = 0;
    public selectedTimelineItem: TimelineItem | undefined;

    private replayTimer: any = null;

    public ngOnInit() {

        if (!this.report) {
            return;
        }

        this.overview.teamsRanking =
            ['warmup', 'prisoners', 'looting', 'drops', 'domination'].includes(this.report.mode);

        this.overview.teamsRoles =
            ['convoy'].includes(this.report.mode);

        this.overview.teamsProgress =
            ['assembly', 'discovery'].includes(this.report.mode);   // TODO: Create area discovery mode

        this.overview.areasRoles =
            ['drops', 'destruction'].includes(this.report.mode);

        this.overview.areasCaptures =
            ['domination'].includes(this.report.mode);

        this.selectTimelineItem(this.report.timeline.length - 1);
    }

    public ngOnDestroy() {

        this.replayStop();
    }


    public timelineItemClick(event: { index: number, item: TimelineItem }): void {

        this.replayStop();

        // Brefore:

        // this.replayPause();

        // this.selectTimelineItem(event.index);
    }


    public get isReplaying(): boolean {

        return this.replayTimer !== null;
    }

    public toggleReplay(): void {

        this.isReplaying ? this.replayStop() : this.replayStart();
    }

    public replayStart(): void {

        if (!this.report) {
            return;
        }

        if (this.selectedTimelineItemIndex === this.report.timeline.length - 1) {

            this.selectTimelineItem(0);
        }

        this.replayTimer = setInterval(() => {

            if (!this.report) {
                return;
            }

            if (this.selectedTimelineItemIndex < this.report.timeline.length - 1) {

                this.selectTimelineItem(this.selectedTimelineItemIndex + 1);

            } else {

                this.replayPause();
            }

        }, 3000);
    }

    public replayPause(): void {

        clearInterval(this.replayTimer);

        this.replayTimer = null;
    }

    public replayStop(): void {

        if (!this.report) {
            return;
        }

        this.replayPause();

        this.selectTimelineItem(this.report?.timeline.length - 1);
    }

    private selectTimelineItem(index: number): void {

        if (!this.report) {
            return;
        }

        this.selectedTimelineItemIndex = index;

        this.selectedTimelineItem = this.report.timeline[index];

        // Find the previous known context
        if (this.selectedTimelineItem.context === undefined) {

            for (let i = index - 1; i >= 0; i--) {
                if(this.report.timeline[i].context) {

                    this.selectedTimelineItem.context = this.report.timeline[i].context;

                    break;
                }
            }
        }
    }


}
