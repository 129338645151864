import { Component, OnInit } from '@angular/core';
import { ScrollService } from '../../services/scroll/scroll.service';

@Component({
    selector: 'app-particles',
    templateUrl: './particles.component.html',
    styleUrls: ['./particles.component.scss']
})
export class ParticlesComponent implements OnInit {

    constructor(public scrollService: ScrollService) { }

    ngOnInit(): void { }

}
