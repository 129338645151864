

//const YOUTUBE_URL_REGEX =   /https?:\/\/(www\.)?youtu((\.be)|(be\..{2,5}))\/((user)|(channel))\/?([a-zA-Z0-9\-_]{1,})/;


const YOUTUBE_URL_REGEX =   /https?:\/\/(www\.)?youtu((\.be)|(be\..{2,5}))\/@([a-zA-Z0-9\-_]+)$/;
const INSTAGRAM_URL_REGEX = /https?:\/\/(www\.)?instagram\.com\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/;
const TIKTOK_URL_REGEX =   /https?:\/\/(www\.)?tiktok\.com\/@([a-zA-Z0-9\-_]+)$/;

export class NetworksUtils {

    // Youtube

    public static isValidYoutubeUrl(url: string): boolean {

        return new RegExp(YOUTUBE_URL_REGEX).test(url);
    }

    public static getYoutubeChannelName(url: string): string {

        if (!NetworksUtils.isValidYoutubeUrl(url)) {
            return '';
        }

        return url.substring(url.lastIndexOf('/') + 1);
    }


    // Instagram

    public static isValidInstagramUrl(url: string): boolean {

        return new RegExp(INSTAGRAM_URL_REGEX).test(url);
    }

    public static getInstagramProfileName(url: string): string {

        if (!NetworksUtils.isValidInstagramUrl(url)) {
            return '';
        }

        // Instagram loves to add a dash at the end
        if (url.endsWith('/')) {
            url = url.slice(0, url.length - 1);
        }

        return url.substring(url.lastIndexOf('/') + 1);
    }


    // Tiktok

    public static isValidTiktokUrl(url: string): boolean {

        return new RegExp(TIKTOK_URL_REGEX).test(url);
    }

    public static getTiktokProfileName(url: string): string {

        if (!NetworksUtils.isValidTiktokUrl(url)) {
            return '';
        }

        return url.substring(url.lastIndexOf('/') + 1);
    }

}
