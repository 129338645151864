// Used by the static server and the resolveAppUrl() method.

import { App } from '../entities/app.entity';

export const apps: Array<App> = [
    {
        name: 'backend',
        localPort: 3000,
        isStatic: false
    },
    {
        name: 'website',
        path: 'website/browser',    // This app has 2 sub directories 'server' and 'browser' because also worked with SSR.
        hasSubdomain: false,        // Main frontend, no subdomain
        localPort: 4100
    },
    {
        name: 'headquarter',
        localPort: 4200
    },
    {
        name: 'backoffice',
        localPort: 4300
    },
    {
        name: 'studio',
        localPort: 4400,
        isStatic: false             // Served by its electron frame
    }
];
