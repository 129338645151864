export enum OperationActions {

    OperationStarted = 'OperationStarted',
    OperationPaused = 'OperationPaused',
    OperationResumed = 'OperationResumed',
    OperationComplete = 'OperationComplete',

    OperatorCaptured = 'OperatorCaptured',
    OperatorRespawned = 'OperatorRespawned',

    AreaCaptured = 'AreaCaptured',
    AreaDestroyed = 'AreaDestroyed',

    DropReleased = 'DropReleased',
    DropCatched = 'DropCatched',

    LootingExtracted = 'LootingExtracted',

    PartAssembled = 'PartAssembled',

    ConvoySuccess = 'ConvoySuccess',
    ConvoyHijacked = 'ConvoyHijacked',
    ConvoyByAttacker = 'ConvoyByAttacker',
    ConvoyHijackedByConveyor = 'ConvoyHijackedByConveyor',
    ConvoyDrill = 'ConvoyDrill',

    TeamPenality = 'TeamPenality',
    TeamBonus = 'TeamBonus',
    Note = 'Note',

    Photography = 'Photography',
}
