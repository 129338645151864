import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[wuiStopPropagationLinks]'
})
export class StopPropagationLinksDirective{

    constructor(elementRef: ElementRef) {

        elementRef.nativeElement.onclick = (event: any) => {

            // Do the same directive for all elemenent (topPropagationDirective) and remove event.stopPropagation(); in the apps?
            if(event.target.href) {
                event.stopPropagation();
            }
        }
    }
}
