import { Address } from './account.entity';
import { StoreProductsStatus, StoreOrderStatus } from '../enums/store.enum';

export class StoreProductQuantity {

    public value = 1;

    public min = 1;

    public max: number | undefined = undefined;

}

export class StoreProductOption {

    public name = '';

    public value: string | boolean = '';

    public isRequired = false;

    public price = 0;
}

export class StoreProductOptionText extends StoreProductOption {

    public type = 'text';

    public pattern = '';
}

export class StoreProductOptionCheckbox extends StoreProductOption {

    public type = 'checkbox';
}

export class StoreProductPrice {

    public base = 0;    // Unit price without option

    public total = 0;   // Total price including selected options and quantity

    public period = '';
}

/**
 * Complete store item containing rules, it is made to list products and compose carts
 */
export class StoreProduct {

    public name = '';

    public details = '';

    public url = '';

    public quantity = new StoreProductQuantity();

    public options = new Array<StoreProductOptionText | StoreProductOptionCheckbox>();

    public price = new StoreProductPrice;

    public status: StoreProductsStatus = StoreProductsStatus.Available;
}



export class StoreOrderItemOption {

    public name = '';

    public value: string | boolean = '';

    public price = 0;
}

export class StoreOrderItem {

    public name = '';

    public quantity = 0;

    public price = 0;

    public options = new Array<StoreOrderItemOption>();
}

export class StoreOrder {

    public id?: any;

    public accountId = '';

    public date = new Date();

    public isTermsOfSalesAccepted = false;

    public address = new Address();

    public items = new Array<StoreOrderItem>();

    public amount = 0;

    public status = StoreOrderStatus.Unpaid;
}
