/* eslint-disable @angular-eslint/no-input-rename */
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, HostBinding } from '@angular/core';

@Component({
    selector: 'wui-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

    public realValue: any;

    public isInvalid = false;

    @Input()
    public autofocus = false;

    @Input()
    public required = false;

    public type = '';

    @Input('type')
    public set typeProxy(type: string) {

        if (type === 'email') {
            this.pattern = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
        }

        this.type = type;
    }

    @Input()
    public min?: number;

    @Input()
    public max?: number;

    @Input()
    public pattern = '';

    @Input()
    public placeholder = '';

    @Input()
    public autocomplete: string | undefined = undefined; // Empty string is depreciated for autocomplete

    @Input()
    public suggestions: Array<string> = [];

    @Input()
    public set value(value: any) {

        if(value === null) {
            return;
        }

        if (this.type === 'number') {
            value = parseInt(value, 10);
        }

        this.realValue = value;

        this.valueChange.emit(this.realValue);

        this.checkValidity();

    }

    @Output()
    public valueChange = new EventEmitter<any>();

    @HostBinding('class.checkbox')
    private isCheckbox = false;

    constructor(private elementRef: ElementRef) { }

    ngOnInit(): void {

        if (this.autofocus) {
            this.elementRef.nativeElement.querySelector('input').focus();
        }

        this.isCheckbox = this.type === 'checkbox';

        this.checkValidity();
    }

    // For checkbox & color
    public onChange(value: any, checked: any): void {

        if (this.type === 'checkbox') {
            this.value = checked;
        } else if (this.type === 'color') {
            this.value = value;
        }
    }

    // For text and number (the click event is used to detect clicks on)
    public onKeyUp(value: any): void {

        if (this.type === 'color') {
            return;
        }

        this.checkValidity();

        if (this.isInvalid === false) {
            this.value = value;
        }
    }

    public checkValidity(): void {

        if (this.type === 'number') {

            if (this.min && this.realValue < this.min) {
                this.realValue = this.min;
            }

            if (this.max && this.realValue > this.max) {
                this.realValue = this.max;
            }

            return;
        }

        if (this.type === 'text') {
            this.isInvalid = (this.required && !this.realValue) || (this.pattern && new RegExp(this.pattern).test(this.realValue)) === false;
        }

        // Fire as a null value when invalid, that make the checking easier
        if (this.isInvalid) {
            setTimeout(() => this.valueChange.emit(null), 0);
        }
    }

}
