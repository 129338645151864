
<!-- modals -->
<div
    class="mask"
    *ngIf="overlayService.modals.length > 0"
    (mousedown)="overlayService.closeModal()">

    <wui-overlay-modal
        *ngFor="let modal of overlayService.modals"
        [modal]="modal"
        (mousedown)="$event.stopPropagation()">
    </wui-overlay-modal>
</div>

<!-- notifications -->
<div class="notifications">
    <wui-notification
        *ngFor="let notification of overlayService.notifications"
        [notification]="notification"
        (mousedown)="overlayService.closeNotification(notification)"
        [class.clickable]="notification.callback !== undefined">
    </wui-notification>
</div>
