import { OperationTrophiesTypes } from '../enums/operation-trophies.enum';
import { OperatorUnderOperation } from './operator.interface';
import { TeamUnderOperation } from './team.entity';


export class OperationTrophy {

    public type!: OperationTrophiesTypes;

    public data!: {
        operator?: OperatorUnderOperation,
        team?: TeamUnderOperation
    }
}
