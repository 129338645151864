import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { OverlayService, StyleUtils, WildUiModule } from '@lightning/wild-ui';
import { WildAnimationModule } from '@lightning/wild-animation';
import { LightningUiModule } from '@lightning/lightning-ui';

import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { PersonalComponent } from './components/personal/personal.component';
import { FooterComponent } from './components/footer/footer.component';
import { StackComponent } from './components/stack/stack.component';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { UndergroundComponent } from './components/underground/underground.component';
import { ParticlesComponent } from './components/particles/particles.component';
import {
    ErrorsInterceptor,
    LightningServicesModule,
} from '@lightning/lightning-services';
import { ModalYesNoComponent } from './components/modal-yes-no/modal-yes-no.component';
import { ModalImageUploadComponent } from './components/modal-image-upload/modal-image-upload.component';
import { ModalAddressComponent } from './components/modal-address/modal-address.component';
import { SizedHeaderComponent } from './components/sized-header/sized-header.component';
import { SizedBodyComponent } from './components/sized-body/sized-body.component';
import { SizedPageComponent } from './components/sized-page/sized-page.component';
import { CookiesWallComponent } from './components/cookies-wall/cookies-wall.component';
import { ModalCookiesLearnMoreComponent } from './components/modal-cookies-learn-more/modal-cookies-learn-more.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        MenuComponent,
        PersonalComponent,
        StackComponent,
        StatusIconComponent,
        UndergroundComponent,
        ParticlesComponent,
        ModalYesNoComponent,
        ModalImageUploadComponent,
        ModalAddressComponent,
        ModalCookiesLearnMoreComponent,
        SizedHeaderComponent,
        SizedBodyComponent,
        SizedPageComponent,
        CookiesWallComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        TranslateModule,
        WildUiModule,
        WildAnimationModule,
        LightningUiModule,
        LightningServicesModule,
    ],
    exports: [
        RouterModule,
        FormsModule,
        TranslateModule,
        WildUiModule,
        WildAnimationModule,
        LightningUiModule,

        HeaderComponent,
        MenuComponent,
        FooterComponent,
        StackComponent,
        StatusIconComponent,
        UndergroundComponent,
        ParticlesComponent,
        SizedHeaderComponent,
        SizedBodyComponent,
        SizedPageComponent,
        CookiesWallComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorsInterceptor,
            multi: true,
        },
    ],
})
export class SharedModule {
    constructor(overlayService: OverlayService) {
        // Register notifications styles
        overlayService.notificationsStyles = [
            {
                type: 'success',
                backgroundColor: StyleUtils.getVariable(
                    '--color-background-success'
                ),
                foregroundColor: StyleUtils.getVariable('--color-text-success'),
            },
            {
                type: 'warning',
                backgroundColor: StyleUtils.getVariable(
                    '--color-background-warning'
                ),
                foregroundColor: StyleUtils.getVariable('--color-text-warning'),
            },
            {
                type: 'error',
                backgroundColor: StyleUtils.getVariable(
                    '--color-background-error'
                ),
                foregroundColor: StyleUtils.getVariable('--color-text-error'),
            },
            {
                type: 'information',
                backgroundColor: StyleUtils.getVariable(
                    '--color-background-information'
                ),
                foregroundColor: StyleUtils.getVariable(
                    '--color-text-information'
                ),
            },
        ];
    }
}
