import { Component, Input } from '@angular/core';
import { Address } from '@lightning/lightning-definitions';
import { OverlayService } from '@lightning/wild-ui';

@Component({
    selector: 'app-modal-address',
    templateUrl: './modal-address.component.html',
    styleUrls: ['./modal-address.component.scss'],
})
export class ModalAddressComponent {

    @Input()
    public resolve = (address: Address | null ) => { /* */ };

    @Input()
    public title = '';

    @Input()
    public address = new Address();

    public constructor(private overlayService: OverlayService) {}

    public cancel(): void {

        this.resolve(null);

        this.overlayService.closeModal();
    }

    public confirm(): void {

        this.resolve(this.address);

        this.overlayService.closeModal();
    }

    public get isAddressInvalid(): boolean {

        return (
            !this.address.firstName ||
            !this.address.lastName ||
            !this.address.street ||
            !this.address.city ||
            !this.address.postalCode ||
            !this.address.country);
    }

}
