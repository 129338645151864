import { Transform, IsBoolean, IsOptional, IsString, IsNotEmpty } from '@lightning/decorators';
import { OperatorProfile, OperatorNetworks } from '../entities/operator.interface';
import { PaginedRequest, PaginedResponse } from './pagined.dto';


export class OperatorsSearchRequest extends PaginedRequest {

    public id?: string;

    public number?: number;

    public name?: string;

    public status?: string;

    public isLegend?: boolean;
}

export class OperatorsSearchResponse extends PaginedResponse {

    public items: Array<OperatorProfile> = [];
}

export class OperatorsCreateRequest {

    @IsString()
    @IsNotEmpty()
    public accountId = '';

    @IsString()
    @IsNotEmpty()
    public name = '';

    @IsBoolean()
    @Transform(({ value }: { value: string }) => value === 'true')
    public isLegend = false;
}

export class OperatorsUpdateRequest {

    // public name?: string; Not updatable yet

    public hashTags = new Array<string>();

    public networks = new OperatorNetworks();
}


export class OperatorsAvatarOptions {

    @IsBoolean()
    @IsOptional()
    @Transform(({ value }: { value: string }) => value === 'true')
    public ignoreStatus?: boolean;
}
