import { Component, OnInit } from '@angular/core';
import { Host } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';

import { environment } from '@lightning/website/environments/environment';
import { OverlayService } from '@lightning/wild-ui';
import { ModalCookiesLearnMoreComponent } from '../modal-cookies-learn-more/modal-cookies-learn-more.component';
import { cicd } from '@lightning/configuration';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    public year: number = (new Date()).getFullYear();

    public host?: Host;

    public socials = environment.links.socials;

    public cicd = cicd;

    constructor(
        private overlayService: OverlayService,
        private onlineService: OnlineService) {}

    ngOnInit(): void {

        this.onlineService.getHost().subscribe({
            next: (host: Host) => {
                this.host = host;
            }
        });
    }

    public learnMoreCookies(): void {
        this.overlayService.openModal({
            component: ModalCookiesLearnMoreComponent,
            inputs: {
                isProposalDisplayed: false
            }
        });
    }

}
