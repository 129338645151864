import { Component, Input } from '@angular/core';

@Component({
    selector: 'wui-input-image',
    templateUrl: './input-image.component.html',
    styleUrls: ['./input-image.component.scss'],
})
export class InputImageComponent {

    @Input()
    public imageUrl = '';

    @Input()
    public placeholderUrl = '';

}
