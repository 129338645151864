<wui-modal-layout>

    <div title>{{ title | translate }}</div>

    <div body class="body">

        <wui-entry>
            <div name>{{ 'shared.firstName' | translate | capitalize }} *</div>
            <wui-input
                [(value)]="address.firstName"
                autocomplete="given-name"
                [required]="true"
                format="capitalized"></wui-input>
        </wui-entry>
        <wui-entry>
            <div name>{{ 'shared.lastName' | translate | capitalize }} *</div>
            <wui-input
                [(value)]="address.lastName"
                autocomplete="family-name"
                [required]="true"
                format="capitalized"></wui-input>
        </wui-entry>

        <wui-entry>
            <div name>{{ 'shared.street' | translate | capitalize }} *</div>
            <wui-input
                [(value)]="address.street"
                autocomplete="address-line1"
                [required]="true"
                format="capitalized">
            </wui-input>
        </wui-entry>

        <wui-entry>
            <div name>{{ 'shared.postalCode' | translate | capitalize }} *</div>
            <wui-input
                [(value)]="address.postalCode"
                autocomplete="postal-code"
                [required]="true"
                pattern="^\d{5}$">
            </wui-input>
        </wui-entry>

        <wui-entry>
            <div name>{{ 'shared.city' | translate | capitalize }} *</div>
            <wui-input
                [(value)]="address.city"
                [required]="true"
                format="capitalized">
            </wui-input>
        </wui-entry>

        <wui-entry>
            <div name>{{ 'shared.country' | translate | capitalize }} *</div>
            <wui-input
                [(value)]="address.country"
                autocomplete="country-name"
                [required]="true"
                format="capitalized"
                [wuiDisabled]="true">
            </wui-input>
        </wui-entry>

        <!-- <wui-entry>
            <div name>{{ 'shared.isDefault' | translate | capitalize }}</div>
            <wui-input [(value)]="address.isDefault" type="checkbox"></wui-input>
        </wui-entry> -->

        <wui-entry>
            <div name>{{ 'shared.phone' | translate | capitalize }}</div>
            <wui-input
                [(value)]="address.phone">
            </wui-input>
        </wui-entry>

    </div>

    <div footer>
        <wui-buttons>
            <wui-button (click)="cancel()">{{ 'shared.cancel' | translate }}</wui-button>
            <wui-button (click)="confirm()" [wuiDisabled]="isAddressInvalid">{{ 'shared.confirm' | translate }}</wui-button>
        </wui-buttons>
    </div>

</wui-modal-layout>
