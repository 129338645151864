
export enum NfcTagsTypes {
    Operator =  'OPR',
    Guest =     'GST',
    Resource =  'RES',
    Part =      'PRT', // Special & part -> Item ?
    Special =   'SPL',

    Location =  'LCT',
    System =    'SYS'
}

export enum NfcTagsSpecials {
    OperationStartResume =  'OperationStartResume',
    OperationPause =        'OperationPause',
    OperationComplete =     'OperationComplete',

    Explosive =             'Explosive',
    Drill =                 'Drill'
}

export enum NfcTagsSystemActions {
    Update =        'update',
    RangeTest =     'rangeTest',
    FactoryReset =  'factoryReset'
}
