export enum GroundLocations {
    Alpha = 'Alpha',
    Bravo = 'Bravo',
    Charlie = 'Charlie',
    Delta = 'Delta',
    Echo = 'Echo',
    Foxtrot = 'Foxtrot',
    Golf = 'Golf',
    Hotel = 'Hotel',
    India = 'India',
    Juliett = 'Juliett',
    Kilo = 'Kilo',
    Lima = 'Lima',
    Mike = 'Mike',
    November = 'November',
    Oscar = 'Oscar',
    Papa = 'Papa',
    Quebec = 'Quebec',
    Romeo = 'Romeo',
    Sierra = 'Sierra',
    Tango = 'Tango',
    Uniform = 'Uniform',
    Victor = 'Victor',
    Wiskey = 'Wiskey',
    XRay = 'XRay',
    Yankee = 'Yankee',
    Zulu = 'Zulu',

    Armory = 'Armory',
    Bank = 'Bank',
    Bridge = 'Bridge',
    Church = 'Church',
    CityHall = 'CityHall',
    Heliport = 'Heliport',
    Hospital = 'Hospital',
    Laboratory = 'Laboratory',
    LaunchArea = 'LaunchArea',
    Motel = 'Motel',
    Powerplant = 'Powerplant',
    Prison = 'Prison',
    RadioTower = 'RadioTower',
    Sawmill = 'Sawmill',
    Torii = 'Torii',
    TrainStation = 'TrainStation',
    Tunnel = 'Tunnel',
    Village = 'Village',
    Workshop = 'Workshop',

    NeutralZone = 'NeutralZone',
};
