import { Component, Input } from '@angular/core';

import { TeamUnderOperation } from '@lightning/lightning-definitions';

@Component({
    selector: 'lui-operation-teams-progress',
    templateUrl: './operation-teams-progress.component.html',
    styleUrls: ['./operation-teams-progress.component.scss']
})

export class OperationTeamsProgressComponent {

    @Input()
    public teams: Array<TeamUnderOperation> = [];

    // @Input()
    // public assembly = ''; // TODO: Use the enum of the part to assemble in a specialized OperationTeamsAssemblyComponent

    public getDoneStepsCount(team: TeamUnderOperation): number {

        return team.data.steps?.filter(step => step.isDone).length || 0;
    }

    public getTotalStepsCount(team: TeamUnderOperation): number {

        return team.data.steps?.length || 0;
    }

}
