import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[wuiBackgroundColor]'
})
export class BackgroundColorDirective {

    @Input('wuiBackgroundColor')
    set color(value: string) {
        this.update(value);
    }

    constructor(private elementRef: ElementRef) { }

    private update(value: string) {
        this.elementRef.nativeElement.style.backgroundColor = value;
    }
}
