import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[wuiStopPropagation]'
})
export class StopPropagationDirective{

    constructor(elementRef: ElementRef) {

        elementRef.nativeElement.onclick = (event: any) => {
            event.stopPropagation();
        }
    }
}
