import { Component, Input, KeyValueDiffer, KeyValueDiffers, DoCheck, ElementRef, OnInit } from '@angular/core';

export interface Step {
    selector: string;
    text: string;

    component?: any;
    callback?: () => { /* */ };
}

const debounceTime = 500;

@Component({
    selector: 'wui-steps',
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit, DoCheck {


    @Input()
    public steps: { items: Step[], selected?: Step  } = { items: [] };

    private initialParentPointerEvents = '';

    private keyValueDiffer: KeyValueDiffer<string, any>;


    public constructor(private differs: KeyValueDiffers, private elementReference: ElementRef) {

        this.keyValueDiffer = this.differs.find(this.steps).create();
    }

    public ngOnInit(): void {

        this.initialParentPointerEvents = this.elementReference.nativeElement.parentElement
        .style
        .pointerEvents;
    }

    public ngDoCheck(): void {

        if (!this.steps.selected) {
            return;
        }

        if (this.keyValueDiffer.diff(this.steps)) {
            this.debounce();
        }
    }

    public previous(): void {

        const selectedIndex = this.getSelectedIndex();

        if (selectedIndex <= 0) {
           return;
        }

        this.steps.selected = this.steps.items[selectedIndex - 1];
    }

    public next(): void {

        const selectedIndex = this.getSelectedIndex();

        if (selectedIndex >= this.steps.items.length - 1) {
            return;
        }

        this.steps.selected = this.steps.items[selectedIndex + 1];
    }

    private getSelectedIndex(): number {

        if(!this.steps.selected) {
            return 0;
        }

        return this.steps.items.indexOf(this.steps.selected);
    }

    // Prevent to double click accidentally to a new 'continue' button placed at the same place
    private debounce(): void {

        this.elementReference.nativeElement.parentElement
            .style
            .pointerEvents = 'none';

        setTimeout(() => {
            this.elementReference.nativeElement.parentElement
                .style
                .pointerEvents = this.initialParentPointerEvents;
        },
        debounceTime);
    }
}
