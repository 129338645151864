import { Component, Input, OnInit } from '@angular/core';
import { OperationWinner } from '@lightning/lightning-definitions';
import { AnalyzersUtils } from '@lightning/lightning-services';

@Component({
    selector: 'lui-operation-winner',
    templateUrl: './operation-winner.component.html',
    styleUrls: ['./operation-winner.component.scss']
})
export class OperationWinnerComponent implements OnInit {

    @Input()
    public winner = new OperationWinner();

    @Input()
    public logoPath = 'assets/operations/winner/logos';

    @Input()
    public logoExtension = '.svg';

    public winnersData = {};

    public ngOnInit(): void {

        this.winnersData = {
            teamName: this.winner.team?.name,
            ...AnalyzersUtils.analyzeOperatorsUnderOperation(this.winner.operators)
        };

    }

}
