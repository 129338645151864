<div class="description">
    <div class="name">
        <ng-content select="[name]"></ng-content>
    </div>
    <div class="detail">
        <ng-content select="[detail]"></ng-content>
    </div>
</div>

<div class="value">
    <ng-content select="[value]"></ng-content>
</div>
