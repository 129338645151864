import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[wuiTypingText]'
})

export class TypingTextDirective implements OnInit {

    private text = '';

    private index = 0;

    constructor(private elementRef: ElementRef) { }

    public ngOnInit(): void {

        setTimeout(() => {

            this.text = this.elementRef.nativeElement.textContent;

            this.elementRef.nativeElement.textContent = '';

            this.write();

        }, 0);
    }

    private write(): void {

        const lastCharacter = this.text.charAt(this.index++);

        this.elementRef.nativeElement.textContent += lastCharacter;

        if(this.index >= this.text.length) {
            return;
        }

        // Calculate the delay before the next character
        const delay = ['.', ',', '!', '?'].indexOf(lastCharacter) >= 0
            ? 500                                   // Fixed 'long' at the end of a quote
            : Math.round(Math.random() * 40) + 10;  // Variable 'short' else

        setTimeout(() => this.write(), delay);
    }
}
