export enum ProductsWithSoftware {

    // With compatibility checking: Must have a minimum supported version in softwares.enums.ts

    HeadquarterGateway =    'HeadquarterGateway',
    HeadquarterGatewaySd =  'HeadquarterGatewaySd',

    GroundModule =          'GroundModule',
    MedicModule =           'MedicModule',
    LockModule =            'LockModule',

    NfcTagOperator =        'NfcTagOperator',
    NfcTagGuest =           'NfcTagGuest',
    NfcTagResource =        'NfcTagResource',
    NfcTagPart =            'NfcTagPart',
    NfcTagLocation =        'NfcTagLocation',
    NfcTagSystem =          'NfcTagSystem',
}

export enum ProductsWithoutSoftware {

    // No compatibility checking: Generic products / packs / options...

    StarterKit =        'StarterKit',
    Dummy =             'Dummy',
    Legend =            'Legend',
}

export const Products = {
    ...ProductsWithSoftware,
    ...ProductsWithoutSoftware,
};
