import { Injectable } from '@angular/core';
import { Modal } from '../../types/modal.type';
import { Notification, NotificationStyle } from '../../types/notification.type';

@Injectable({
    providedIn: 'root'
})

export class OverlayService {

    public modals = new Array<Modal>();

    public notifications: Array<Notification> = [];
    public notificationsClosingTimer: any;
    public notificationsStyles: Array<NotificationStyle> = [];

    private bodyStyleScrollInitial: string = document.body.style.overflow;

    public openModal(modal: Modal): Promise<any> {

        return new Promise(resolve => {

            // Lock body scroll when displayed
            document.body.style.overflow = 'hidden';

            modal.inputs = modal.inputs || {};

            modal.inputs.resolve = resolve;

            this.modals.push(modal);
        });
    }

    public closeModal(): void {

        if (this.modals.length === 0) {
            return;
        }

        // Remove the modal from the list and get it
        const modal = this.modals.pop();

        // Be sure it's promise is resolved
        if (modal?.inputs.resolve) {
            modal.inputs.resolve();
        }

        // Restoring initial body style for overflow
        if (this.modals.length === 0) {
            document.body.style.overflow = this.bodyStyleScrollInitial;
        }
    }

    public openNotification(notification: Notification): void {

        this.notifications.push(notification);

        if (this.notificationsClosingTimer) {

            clearTimeout(this.notificationsClosingTimer);
        }

        // This is a very basic way to do ;)
        this.notificationsClosingTimer = setTimeout(() => {

            this.notifications = [];

        }, notification.duration || 5000);
    }

    public closeNotification(notification: Notification): void {

        if(notification.callback) {
            notification.callback();
        }

        this.notifications.splice(this.notifications.indexOf(notification), 1);
    }

}
