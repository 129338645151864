/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Type, IsNumber, ApiProperty } from '@lightning/decorators';
import { Expose } from 'class-transformer';

export class PaginedRequest {

    // @Expose()
    @ApiProperty({ default: '0'})   // Expose to swagger

    @Expose()                       // Expose to class transformer
    @IsNumber()
    @Type(() => Number)             // DTOs became serialized strings when they are a Query of a Get request, class transformer had to convert it back.
    public index = 0;

    @ApiProperty({ default: '0'})
    @Expose()
    @IsNumber()
    @Type(() => Number)
    public limit = 0;

    /* extends this type with some filtering and sorting properties */
}

// Deprecated: Create dtos inheriting the following class
export class PaginedResponse {

    public index = 0;

    public total = 0;

    /* extends this type with items: Array<THE_ITEM_TYPE>; */
}

// Better way: Directly use PaginedResponseT<THE_ITEM_TYPE>, may crash the swagger plugin :s
export class PaginedResponseT<T> {

    public index = 0;

    public total = 0;

    public items: Array<T> = [];
}

