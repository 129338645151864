import { Directive, ElementRef, OnInit, isDevMode } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

const PARAMETER_NAME = 'show-development-features';

@Directive({
    selector: '[wuiDevelopmentOnly]',
})
export class DevelopmentOnlyDirective implements OnInit {

    constructor(
        private elementRef: ElementRef,
        private route: ActivatedRoute) { }

    public ngOnInit() {

        // To show the feature is a development feature
        this.elementRef.nativeElement.classList.add('pulsing-slow');

        // How only on isDevMode() or with the 'show-development-features' parameter
        this.route.queryParams.subscribe({
            next: (parameters) => {

                // Memorize for the next times
                if (parameters[PARAMETER_NAME]) {
                    localStorage.setItem(PARAMETER_NAME, parameters[PARAMETER_NAME]);
                }

                if (isDevMode() || localStorage.getItem(PARAMETER_NAME) === 'true') {
                    this.elementRef.nativeElement.style.display = 'initial';
                } else {
                    this.elementRef.nativeElement.style.display = 'none';
                }
            }
        });
    }
}
