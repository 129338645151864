import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'wui-electron-handle',
    templateUrl: './electron-handle.component.html',
    styleUrls: ['./electron-handle.component.scss']
})

export class ElectronHandleComponent {

    @HostBinding('style.display')
    public get display() {
        return this.isElectron ? 'default' : 'none';
    };

    get isElectron(): boolean {

        return false; //navigator.userAgent.indexOf('Electron') >= 0;
    }
}
