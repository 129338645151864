import { Component, Input } from '@angular/core';
import { OverlayService } from '@lightning/wild-ui';

@Component({
    selector: 'app-modal-cookies-learn-more',
    templateUrl: './modal-cookies-learn-more.component.html',
    styleUrls: ['./modal-cookies-learn-more.component.scss'],
})
export class ModalCookiesLearnMoreComponent {

    @Input()
    public resolve = (value: boolean) => Function;

    @Input()
    public isProposalDisplayed = false;

    constructor(
        private overlayService: OverlayService) { }

    public decline(): void {

        this.resolve(false);

        this.overlayService.closeModal();
    }

    public accept(): void {

        this.resolve(true);

        this.overlayService.closeModal();
    }

}

