import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'wui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

    @Input()
    public href = '';

    @Input()
    public target = '';

    @Input()
    public routerLink = '';

    constructor(private router: Router) {}

    public clicked(): void {

        if (this.href) {
            window.open(this.href, this.target);
        }

        if (this.routerLink) {
            this.router.navigateByUrl(this.routerLink);
        }

    }

}
