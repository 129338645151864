import { Directive, Input, Output, OnInit, ElementRef } from '@angular/core';

@Directive({
    selector: '[wuiMaximizable]'
})
export class MaximizableDirective implements OnInit {

    @Input('wuiMaximizable')
    public options: {
        container?: string,
        handle?: string
    } | undefined;

    private elementTarget: any;
    private normalState: {
        left: string,
        top: string,
        width: string;
        height: string;
    } | undefined;

    constructor(private elementRef: ElementRef) { }

    ngOnInit() {

        // Get elements
        this.elementTarget = this.elementRef.nativeElement;

        if (this.options?.handle) {

            const elementHandle = this.elementRef.nativeElement.querySelector(this.options.handle);

            elementHandle.addEventListener('dblclick', () => {
                this.toggleSize();
            });
        }
    }

    // Also callable from outside
    @Output()
    public toggleSize(): void {

        this.isMaximized ? this.setNormalSize() : this.setMaximizedSize();
    }

    @Output()
    public setNormalSize(): void {

        if (!this.normalState) {
            return;
        }

        // Back to normal state
        this.elementTarget.style.left = this.normalState.left;
        this.elementTarget.style.top = this.normalState.top;
        this.elementTarget.style.width = this.normalState.width;
        this.elementTarget.style.height = this.normalState.height;

        // Clean memorized normal state
        this.normalState = undefined;
    }

    @Output()
    public setMaximizedSize(): void {

        // Save normal state
        this.normalState = {
            left: this.elementTarget.style.left,
            top: this.elementTarget.style.top,
            width: this.elementTarget.style.width,
            height: this.elementTarget.style.height
        };

        // Maximize
        this.elementTarget.style.left = '0px';
        this.elementTarget.style.top = '0px';
        this.elementTarget.style.width = '100%';
        this.elementTarget.style.height = '100%';
    }

    @Output()
    public get isMaximized(): boolean {

        return this.normalState !== undefined;
    }
}
