import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-sized-body',
    templateUrl: './sized-body.component.html',
    styleUrls: ['./sized-body.component.scss'],
})
export class SizedBodyComponent {

    // @Input()
    // public size: 'small' | 'normal' | 'large' = 'normal';

}
