import { OperationPhotography } from '../entities/operation-photography.entity';
import { OperationPhotographyUploadResponseFileError } from '../enums/operation-photography.enum';
import { PaginedRequest, PaginedResponse } from './pagined.dto';


export interface OperationPhotographyUploadResponse {

    sessionId: string;
    timeOffset: number;
    files: Array<OperationPhotographyUploadResponseFile>;
}

export interface OperationPhotographyUploadResponseFile {

    name: string;
    date?: Date;
    hasQrCode?: boolean;
    error?: OperationPhotographyUploadResponseFileError;
}

export type OperationsPhotographiesSearchFilters = {
    sessionId?: string;
    date?: Date;
};

export type OperationPhotographiesSearchRequest = PaginedRequest & OperationsPhotographiesSearchFilters;

export type OperationPhotographiesSearchResponse = PaginedResponse & {
    items: Array<OperationPhotography>;
}
