import { apps } from '@lightning/configuration';

export const environment = {
    production: false,
    apps,

    accounts: {
        googleApi: {
            id: '228409360887-j477tdoin1g4rtbq0rqfvs8t7eiguusb.apps.googleusercontent.com' // TODO: SETUP DOTENV TO USE process.env.ACCOUNTS_GOOGLE_API_ID,
        }
    },
    discovery: {
        operationReportId: '65dcd51945052c542479ac3e'
    },
    store: {
        stripe: {
            publicKey: 'pk_test_51Ntz0FLDxn6KQwRHfNtIeZHjdoPtk62W8gSJfeR5dpKHr0bGLJIAPcd1bVJcqvdHtWpmrSWX86fZsljPxJX5vK3m00F1CEEy8a'
        }
    },
    links: {
        socials: {
            discord: "https://discord.gg/GPHR6dVKZs",
            instagram: "https://www.instagram.com/lightning_tactical_systems/",
            facebook: "https://www.facebook.com/mwlightning",
            mail: "contact@lightning-tactical.com",
        },
        downloads: {
            gatewaySd: 'https://drive.google.com/drive/folders/1jLr8gLKBVCYs9vXFx52Oll0Iw_zkbB7U?usp=share_link',
            baofengSoftware: 'https://chirp.danplanet.com/projects/chirp/wiki/Download',
            baofengCable: 'https://www.google.com/search?q=baofeng+cable',
            baofengConfiguration: 'https://drive.google.com/file/d/1ThupyDKwT0Wy73_QaMkb077eu-j63UBo/view',
            baofengImageUV5R: 'https://drive.google.com/file/d/11T4cvBmh3rSj_FUH6geBGBZ1HMV1-49n/view?usp=drive_link',
            baofengImageBFF8Plus: 'https://drive.google.com/file/d/1xg1sqKJvyOScN28wJsSYxN-9TILGKuGm/view?usp=drive_link'
        }
    }
};
