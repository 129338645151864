import { ApiProperty, IsNumber, Transform, Type } from '@lightning/decorators';

export class SementicVersion {

    @ApiProperty({type: Number})
    @Type(() => String)                                             // they
    @Transform(({ value }: { value: string }) => parseInt(value))   // work
    @IsNumber()                                                     // together
    public major!: number;

    @ApiProperty({type: Number})
    @Type(() => String)                                             // they
    @Transform(({ value }: { value: string }) => parseInt(value))   // work
    @IsNumber()                                                     // together
    public minor!: number;

    @ApiProperty({type: Number})
    @Type(() => String)                                             // they
    @Transform(({ value }: { value: string }) => parseInt(value))   // work
    @IsNumber()                                                     // together
    public patch?: number;
}
