import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { WildUiModule } from '@lightning/wild-ui';

import { OperationAreasCapturesComponent } from './components/operation-areas-captures/operation-areas-captures.component';
import { OperationAreasRolesComponent } from './components/operation-areas-roles/operation-areas-roles.component';
import { OperationReportComponent } from './components/operation-report/operation-report.component';
import { OperationTeamsRankingComponent } from './components/operation-teams-ranking/operation-teams-ranking.component';
import { OperationTeamsRolesComponent } from './components/operation-teams-roles/operation-teams-roles.component';
import { OperationTeamsProgressComponent } from './components/operation-teams-progress/operation-teams-progress.component';
import { OperationTrophiesComponent } from './components/operation-trophies/operation-trophies.component';
import { OperationWinnerComponent } from './components/operation-winner/operation-winner.component';
import { TimelineComponent } from './components/timeline/timeline.component';

import { ArrayExtractPipe } from './pipes/array-extract/array-extract.pipe';
import { LoraRssiPercentsPipe } from './pipes/lora-rssi-percents/lora-rssi-percents.pipe';
import { MillisecondsPipe } from './pipes/milliseconds/milliseconds.pipe';
import { OrdinalPipe } from './pipes/ordinal/ordinal.pipe';
import { AnyPipe } from './pipes/any/any.pipe';

@NgModule({
  declarations: [
      LoraRssiPercentsPipe,
      MillisecondsPipe,
      OrdinalPipe,
      ArrayExtractPipe,
      AnyPipe,

      TimelineComponent,
      OperationReportComponent,
      OperationAreasRolesComponent,
      OperationTeamsRankingComponent,
      OperationTeamsRolesComponent,
      OperationTeamsProgressComponent,
      OperationAreasCapturesComponent,
      OperationTrophiesComponent,
      OperationWinnerComponent,
  ],
  imports: [
      CommonModule,
      WildUiModule,
      TranslateModule
  ],
  exports: [
      LoraRssiPercentsPipe,
      MillisecondsPipe,
      OrdinalPipe,
      ArrayExtractPipe,
      AnyPipe,

      TimelineComponent,
      OperationReportComponent,
      OperationAreasRolesComponent,
      OperationTeamsRankingComponent,
      OperationTeamsRolesComponent,
      OperationTeamsProgressComponent,
      OperationAreasCapturesComponent,
      OperationTrophiesComponent,
      OperationWinnerComponent,
  ]
})
export class LightningUiModule {}
