<svg viewBox="0 0 1000 1000" overflow="hidden" class="animation">

    <g
        *ngIf="scene"
        [class]="scene.camera.class"
        [attr.transform]="
            isCameraLocked
            ? ''
            : 'translate(' + (500 - (scene.camera.x || 0) * (scene.camera.zoom || 1)) + ',' + (500 - (scene.camera.y || 0) * (scene.camera.zoom || 1)) + ') scale(' + (scene.camera.zoom || 1) + ')'
        ">

        <g *ngIf="isGridDisplayed" fill="none" stroke="gray" stroke-dasharray="5, 5" stroke-width="0.4">
            <rect x="0" y="0" width="1000" height="1000" />
            <line x1="0" y1="500" x2="1000" y2="500"></line>
            <line x1="500" y1="0" x2="500" y2="1000"></line>
        </g>

        <ng-container *ngFor="let sprite of scene.sprites">

            <circle
                *ngIf="sprite.type === 'circle'"
                [id]="sprite.id"
                (click)="displaySpriteData(sprite)"
                [class]="sprite.class"
                [attr.cx]="sprite.x"
                [attr.cy]="sprite.y"
                [attr.r]="sprite.radius"
                [attr.fill]="sprite.fillColor || 'transparent'"
                [attr.stroke]="sprite.strokeColor"
                [attr.stroke-width]="sprite.strokeWidth"
                ></circle>

            <text
                *ngIf="sprite.type === 'text'"
                [id]="sprite.id"
                (click)="displaySpriteData(sprite)"
                [class]="sprite.class"
                [attr.x]="sprite.x"
                [attr.y]="sprite.y"
                [attr.transform]="'rotate(' + (sprite.angle || 0) + ')'"
                [attr.stroke]="sprite.strokeColor"
                [attr.stroke-width]="sprite.strokeWidth"
                [attr.font-family]="sprite.fontFamily"
                [attr.font-size]="sprite.fontSize"
                >{{ sprite.text }}</text>

            <line
                *ngIf="sprite.type === 'line'"
                [id]="sprite.id"
                [class]="sprite.class"
                [attr.x1]="sprite.x1"
                [attr.y1]="sprite.y1"
                [attr.x2]="sprite.x2"
                [attr.y2]="sprite.y2"
                [attr.stroke]="sprite.strokeColor"
                [attr.stroke-width]="sprite.strokeWidth"></line>

            <g
                *ngIf="sprite.type === 'svg'"
                [id]="sprite.id"
                (click)="displaySpriteData(sprite)"
                [class]="sprite.class"
                [attr.transform]="'translate(' + sprite.x + ',' + sprite.y + ') rotate(' + (sprite.angle || 0) + ') scale(' + (sprite.scale || 1) + ')'"
                [innerHtml]="sprite.content"
                ></g>

            <!-- <svg
                *ngIf="sprite.type === 'svg'"
                [id]="sprite.id"
                [class]="sprite.class"
                [attr.x]="sprite.x"
                [attr.y]="sprite.y"
                [innerHtml]="sprite.content"></svg> -->

        </ng-container>

    </g>

</svg>

<div class="controls" *ngIf="isControlsDisplayed">
    <div class="control">
        <div class="name">frames</div>
        <div class="container">

            <div class="control">
                <div class="name">previous</div>
                <div class="content button" (click)="stop(); previous();">⏴</div>
            </div>

            <div class="control">
                <div class="name">current</div>
                <div class="content" (click)="togglePlayStop()">{{ frameIndex + 1 }} / {{ frames.length }}</div>
            </div>

            <div class="control">
                <div class="name">next</div>
                <div class="content button" (click)="stop(); next();">⏵</div>
            </div>
        </div>
    </div>
    <div class="control">
        <div class="name">camera</div>
        <div class="container">

            <div class="control">
                <div class="name">x</div>
                <div class="content">{{ scene.camera.x }}</div>
            </div>

            <div class="control">
                <div class="name">y</div>
                <div class="content">{{ scene.camera.y }}</div>
            </div>

            <div class="control">
                <div class="name">zoom</div>
                <div class="content">{{ scene.camera.zoom }}</div>
            </div>

        </div>
    </div>
</div>

<div class="subtitle">{{ scene.subtitle }}</div>
