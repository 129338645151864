import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { OverlayComponent } from './components/overlay/overlay.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalLayoutComponent } from './components/modal-layout/modal-layout.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ButtonComponent } from './components/button/button.component';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { CardComponent } from './components/card/card.component';
import { CardsComponent } from './components/cards/cards.component';
import { ElectronHandleComponent } from './components/electron-handle/electron-handle.component';
import { EntryComponent } from './components/entry/entry.component';
import { ExpendableComponent } from './components/expendable/expendable.component';
import { InputComponent } from './components/input/input.component';
import { JsonComponent } from './components/json/json.component';
import { EmptyFillerComponent } from './components/placeholder/placeholder.component';
import { SectionComponent } from './components/section/section.component';
import { SelectComponent } from './components/select/select.component';
import { StepsComponent } from './components/steps/steps.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { GraphBarComponent } from './components/graph-bar/graph-bar.component';
import { WorkInProgressComponent } from './components/work-in-progress/work-in-progress.component';

import { BackgroundColorDirective } from './directives/background-color/background-color.directive';
import { BackgroundImageDirective } from './directives/background-image/background-image.directive';
import { DisabledDirective } from './directives/disabled/disabled.directive';
import { DraggableDirective } from './directives/draggable/draggable.directive';
import { MaximizableDirective } from './directives/maximizable/maximizable.directive';
import { PointerEndDirective } from './directives/pointer-end/pointer-end.directive';
import { PointerLongDirective } from './directives/pointer-long/pointer-long.directive';
import { PointerStartDirective } from './directives/pointer-start/pointer-start.directive';
import { PointerSwipeDirective } from './directives/pointer-swipe/pointer-swipe.directive';
import { ElectronOnlyDirective } from './directives/electron-only/electron-only.directive';
import { ReadOnlyDirective } from './directives/read-only/read-only.directive';
import { StopPropagationDirective } from './directives/stop-progagation/stop-propagation.directive';
import { StopPropagationLinksDirective } from './directives/stop-progagation-links/stop-propagation-links.directive';
import { ResizableDirective } from './directives/resizable/resizable.directive';
import { ScrollDirective } from './directives/scroll/scroll.directive';
import { ScrolledDirective } from './directives/scrolled-bottom/scrolled-bottom.directive';
import { DevelopmentOnlyDirective } from './directives/development-only/development-only.directive';
import { TacticalCornersDirective } from './directives/tactical-corners/tactical-corners.directive';
import { TypingTextDirective } from './directives/typing-text/typing-text.directive';

import { CapitalizePipe } from './pipes/capitalize/capitalize.pipe';
import { UncapitalizePipe } from './pipes/uncapitalize/uncapitalize.pipe';
import { JoinPipe } from './pipes/join/join.pipe';
import { KeyValuePipe } from './pipes/key-value/key-value.pipe';
import { InputImageComponent } from './components/input-image/input-image.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        CapitalizePipe,
        UncapitalizePipe,
        JoinPipe,
        KeyValuePipe,

        DisabledDirective,
        DraggableDirective,
        MaximizableDirective,
        ResizableDirective,
        ScrollDirective,
        ScrolledDirective,
        StopPropagationDirective,
        StopPropagationLinksDirective,
        ElectronOnlyDirective,
        ReadOnlyDirective,
        PointerLongDirective,
        PointerStartDirective,
        PointerEndDirective,
        PointerSwipeDirective,
        BackgroundImageDirective,
        BackgroundColorDirective,
        DevelopmentOnlyDirective,
        TacticalCornersDirective,
        TypingTextDirective,

        ElectronHandleComponent,
        OverlayComponent,
        ModalComponent,
        NotificationComponent,
        ModalLayoutComponent,
        ButtonsComponent,
        ButtonComponent,
        CardsComponent,
        CardComponent,
        ExpendableComponent,
        StepsComponent,
        TabsComponent,
        WorkInProgressComponent,
        SectionComponent,
        EntryComponent,
        InputComponent,
        InputImageComponent,
        TextareaComponent,
        GraphBarComponent,
        SelectComponent,
        EmptyFillerComponent,
        JsonComponent,
    ],
    exports: [
        CapitalizePipe,
        UncapitalizePipe,
        JoinPipe,
        KeyValuePipe,

        DraggableDirective,
        MaximizableDirective,
        ResizableDirective,
        ScrollDirective,
        ScrolledDirective,
        StopPropagationDirective,
        StopPropagationLinksDirective,
        PointerLongDirective,
        PointerStartDirective,
        PointerEndDirective,
        PointerSwipeDirective,
        DisabledDirective,
        ElectronOnlyDirective,
        ReadOnlyDirective,
        BackgroundImageDirective,
        BackgroundColorDirective,
        DevelopmentOnlyDirective,
        TacticalCornersDirective,
        TypingTextDirective,

        ElectronHandleComponent,
        OverlayComponent,
        ModalComponent,
        ModalLayoutComponent,
        ButtonsComponent,
        ButtonComponent,
        CardsComponent,
        CardComponent,
        ExpendableComponent,
        StepsComponent,
        TabsComponent,
        WorkInProgressComponent,
        SectionComponent,
        EntryComponent,
        InputComponent,
        InputImageComponent,
        TextareaComponent,
        GraphBarComponent,
        SelectComponent,
        EmptyFillerComponent,
        JsonComponent,
    ],
    providers: [
        // Provide pipe to use it in components.ts if needed
        CapitalizePipe,
        UncapitalizePipe,
        JoinPipe,
        KeyValuePipe,
    ],
})
export class WildUiModule {}
