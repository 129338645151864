import { Component, Input } from '@angular/core';
import { FilesUtils } from '@lightning/utils';
import { OverlayService } from '@lightning/wild-ui';

@Component({
    selector: 'app-modal-image-upload',
    templateUrl: './modal-image-upload.component.html',
    styleUrls: ['./modal-image-upload.component.scss'],
})
export class ModalImageUploadComponent {

    @Input()
    public title  = '';

    @Input()
    public imageUrl = '';

    @Input()
    public placeholderUrl = '';

    @Input()
    public patternUrl = '';

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    public resolve = (image: ArrayBuffer | null) => { };

    constructor(private overlayService: OverlayService) { }

    public cancel() {

        this.resolve(null);

        this.overlayService.closeModal();
    }

    public async select(): Promise<void> {

        const file = await FilesUtils.load('.jpg, .png', false);

        this.resolve(file as ArrayBuffer);

        this.overlayService.closeModal();
    }

}
