import { Directive, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[wuiDraggable]'
})

export class DraggableDirective implements OnInit {

    @Input('wuiDraggable')
    public options: {
        container: string;
        handle: string;
        bounded: boolean;
    } | undefined;


    // eslint-disable-next-line @angular-eslint/no-output-rename
    @Output('dragStart')
    public startEvent = new EventEmitter();

    // eslint-disable-next-line @angular-eslint/no-output-rename
    @Output('dragStop')
    public stopEvent = new EventEmitter<any>();

    // eslint-disable-next-line @angular-eslint/no-output-rename
    @Output('dragBounds')
    public boundsEvent = new EventEmitter<any>();

    private elementTarget: HTMLElement | undefined;
    private elementContainer: HTMLElement | undefined;
    private elementHandle: HTMLElement | undefined;

    private isDragging = false;
    private mouseOffset = { x: 0, y: 0 };
    private bounds = { left: false, right: false, top: false, bottom: false };


    constructor(private elementRef: ElementRef) { }

    public ngOnInit() {

        if(!this.options) {
            return;
        }

        // Get elements
        this.elementTarget = this.elementRef.nativeElement;
        this.elementContainer = this.elementRef.nativeElement.closest(this.options.container);
        this.elementHandle = this.elementRef.nativeElement.querySelector(this.options.handle);

        if(!this.elementContainer || !this.elementHandle) {
            return;
        }

        // Drag start
        this.elementHandle.addEventListener('mousedown', (event: MouseEvent) => this.start(event));
        this.elementHandle.addEventListener('touchstart', (event: TouchEvent) =>this.start(event));

        // Dragging
        this.elementContainer.addEventListener('mousemove', (event: MouseEvent) => this.move(event));
        this.elementContainer.addEventListener('touchmove', (event: TouchEvent) => this.move(event));

        // Drag stop
        this.elementContainer.addEventListener('mouseup', (event: MouseEvent) => this.stop(event));
        this.elementContainer.addEventListener('touchend', (event: TouchEvent) => this.stop(event));

    }

    private start(event: MouseEvent | TouchEvent): void {

        if(!this.elementTarget) {
            return;
        }

        // Get the pointer location (from a touch or the mouse)
        const pointer = this.getPointerLocation(event);

        // Memorize mouse offsets
        this.mouseOffset.x = pointer.left - this.elementTarget.offsetLeft;
        this.mouseOffset.y = pointer.top - this.elementTarget.offsetTop;

        // Disable selection highligh
        this.elementTarget.style.setProperty('-webkit-user-select', 'none');

        // Enable dragging
        this.isDragging = true;

        // Fire start event
        this.startEvent.emit();
    }

    private move(event: MouseEvent | TouchEvent): void {

        if (!this.isDragging) {
            return;
        }

        if (!this.options || !this.elementTarget || !this.elementContainer) {
            return;
        }

        // Get the pointer location (from a touch or the mouse)
        const pointer = this.getPointerLocation(event);

        // Move using mouse location and offsets
        let left = pointer.left - this.mouseOffset.x;
        let top = pointer.top - this.mouseOffset.y;

        // Bounds
        if (this.options.bounded) {

            const bounds = { left: false, right: false, top: false, bottom: false };

            // Detect bounds colisions
            if (left < 0) {
                bounds.left = true;

            } else if (left + this.elementTarget.offsetWidth > this.elementContainer.offsetWidth) {
                bounds.right = true;
            }

            if (top < 0) {
                bounds.top = true;

            } else if (top + this.elementTarget.offsetHeight > this.elementContainer.offsetHeight) {
                bounds.bottom = true;
            }

            // Keep in bounds
            if (bounds.left) {
                left = 0;

            } else if (bounds.right) {
                left = this.elementContainer.offsetWidth - this.elementTarget.offsetWidth;
            }

            if (bounds.top) {
                top = 0;

            } else if (bounds.bottom) {
                top = this.elementContainer.offsetHeight - this.elementTarget.offsetHeight;
            }

            // Check changes
            if (JSON.stringify(this.bounds) !== JSON.stringify(bounds)) {

                // Update memorized bounds
                this.bounds = Object.assign(this.bounds, bounds);

                // Fire bounds event
                this.boundsEvent.emit(this.bounds);
            }
        }

        // Apply new values as % to the target (% for responsive)
        this.elementTarget.style.left = this.getPercentFromPx(left, this.elementContainer, 'width');
        this.elementTarget.style.top = this.getPercentFromPx(top, this.elementContainer, 'height');

    }

    private stop(event: MouseEvent | TouchEvent): void {

        if (!this.isDragging) {
            return;
        }

        if (!this.elementTarget) {
            return;
        }

        // Restore selection highligh
        this.elementTarget.style.setProperty('-webkit-user-select', 'initial');

        // Disable dragging
        this.isDragging = false;

        // Fire stop event
        this.stopEvent.emit({
            left: this.elementTarget.style.left,
            top:  this.elementTarget.style.top
        });
    }

    private getPercentFromPx(pixels: number, container: HTMLElement, property: string): string {

        const containerSize = parseFloat(window.getComputedStyle(container).getPropertyValue(property).replace('px', ''));

        return ((pixels / containerSize) * 100) + '%';
    }

    private getPointerLocation(event: MouseEvent | TouchEvent): {left: number, top: number} {

        const { pageX, pageY } = event instanceof MouseEvent ? event : event.changedTouches[0];

        return { left: pageX, top: pageY };
    }
}
