import { Directive, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';

const MINIMAL_SWIPE = 100;

@Directive({
    selector: '[wuiPointerSwipe]'
})

export class PointerSwipeDirective implements OnInit {

    @Output('wuiPointerSwipe')
    private swipeEvent = new EventEmitter();

    private pointerLocationStart: { left: number, top: number } | undefined;

    constructor(private elementRef: ElementRef) {
    }

    public ngOnInit() {

        // Get element
        const elementTarget = this.elementRef.nativeElement;

        // Start to click or touch
        elementTarget.addEventListener('mousedown', (event: MouseEvent) => this.start(event));
        elementTarget.addEventListener('touchstart', (event: TouchEvent) => this.start(event));

        // End to click or touch
        elementTarget.addEventListener('mouseup', (event: MouseEvent) => this.end(event));
        elementTarget.addEventListener('touchend', (event: TouchEvent) => this.end(event));
    }

    private start(event: MouseEvent | TouchEvent): void {

        // Memorize starting location
        this.pointerLocationStart = this.getPointerLocation(event);
    }

    private end(event : MouseEvent | TouchEvent): void {

        // Can happen when the touch starts out of the window
        if (!this.pointerLocationStart) {
            return;
        }

        const pointerLocationEnd = this.getPointerLocation(event);

        let direction = '';

        if (Math.abs(pointerLocationEnd.left - this.pointerLocationStart.left) >= MINIMAL_SWIPE) {
            direction = pointerLocationEnd.left < this.pointerLocationStart.left ? 'left' : 'right';
        }

        if (direction) {
            direction += ' ';
        }

        if (Math.abs(pointerLocationEnd.top - this.pointerLocationStart.top) >= MINIMAL_SWIPE) {
            direction += pointerLocationEnd.top < this.pointerLocationStart.top ? 'up' : 'down';
        }

        if (direction) {

            event.preventDefault();

            this.swipeEvent.emit(direction);
        }

        this.pointerLocationStart = undefined;
    }

    private getPointerLocation(event: MouseEvent | TouchEvent): {left: number, top: number} {

        const { pageX, pageY } = event instanceof MouseEvent ? event : event.changedTouches[0];

        return { left: pageX, top: pageY };
    }

}
