import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppsUtils } from '@lightning/configuration';

import { AccountRoles } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService } from '@lightning/wild-ui';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {

    @HostListener('click')
    private onClick() {

        this.overlayService.closeModal();
    }

    constructor(
        private router: Router,
        private overlayService: OverlayService,
        private onlineService: OnlineService) { }

    public ngOnInit(): void {

        // Close after the navigation completes
        this.router.events.subscribe({
            next: event => {
                if (event instanceof NavigationEnd) {
                    this.overlayService.closeModal();
                }
            }
        });
    }

    public get isAdministrator(): boolean {
        return this.onlineService.tokenPayload?.roles.includes(AccountRoles.Admininstrator) === true;
    }

    public navigateTo(url: string): void {

        if (url === this.router.url) {

            this.overlayService.closeModal();

            return;
        }

        this.router.navigateByUrl(url);
    }

    public openBackoffice(): void {
        window.open(AppsUtils.resolveAppUrl('backoffice'));
    }

}
