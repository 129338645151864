import { Component, Input } from '@angular/core';

export class Tab {

    public selector: string | any;

    public text = '';

    public component?: any;

    public callback?: () => { /* */ };
}

export class Tabs {

    public items = new Array<Tab>();

    public selected?: Tab;
}

@Component({
    selector: 'wui-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {

    @Input()
    public tabs: { items: Tab[], selected?: Tab  } = { items: [] };

    public select(selector: string): void {

        this.tabs.selected = this.tabs.items.find(item => item.selector === selector);

        if (!this.tabs.selected) {
            return;
        }

        if (this.tabs.selected.callback) {
            this.tabs.selected.callback.call(this);
        }
    }

    public previous(): void {

        const selectedIndex = this.getSelectedIndex();

        if (selectedIndex <= 0) {
           return;
        }

        this.tabs.selected = this.tabs.items[selectedIndex - 1];
    }

    public next(): void {

        const selectedIndex = this.getSelectedIndex();

        if (selectedIndex >= this.tabs.items.length - 1) {
            return;
        }

        this.tabs.selected = this.tabs.items[selectedIndex + 1];
    }

    private getSelectedIndex(): number {

        if(!this.tabs.selected) {
            return 0;
        }

        return this.tabs.items.indexOf(this.tabs.selected);
    }

}
