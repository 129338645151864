<wui-modal-layout>

    <div title (click)="randomFill()">{{ 'shared.signin' | translate }}</div>

    <div body>

        <wui-section [wuiDisabled]="isLoading">

            <wui-input
                type="text"
                [(value)]="request.name"
                [placeholder]="'shared.firstName' | translate"
                autocomplete="given-name"
                [required]="true">
            </wui-input>

            <wui-input
                type="email"
                [(value)]="request.email"
                [placeholder]="'shared.email' | translate"
                autocomplete="email"
                [required]="true">
            </wui-input>

            <wui-input
                type="password"
                [(value)]="request.password"
                [placeholder]="'shared.password' | translate"
                [required]="true">
            </wui-input>

            <wui-input
                type="password"
                [(value)]="passwordConfirm"
                [placeholder]="'shared.passwordConfirm' | translate"
                [required]="true">
            </wui-input>

        </wui-section>

    </div>

    <div footer>
        <wui-buttons>
            <wui-button (click)="signin()" [wuiDisabled]="isInvalid">{{ 'shared.signin' | translate }}</wui-button>
        </wui-buttons>
    </div>

</wui-modal-layout>
