import { Expose, Transform } from 'class-transformer';

import { Type } from '@lightning/decorators';

import { Address } from '../entities/account.entity';
import { StoreProduct, StoreOrder } from '../entities/store.entity';
import { StoreOrderStatus } from '../enums/store.enum';
import { PaginedRequest, PaginedResponse, PaginedResponseT } from './pagined.dto';
import { IsArray, IsBoolean, IsNumber, IsString } from 'class-validator';

export class StoreProductsSearchRequest extends PaginedRequest {

    @Expose()
    public id?: string;

    @Expose()
    public name?: string;

}

export class StoreProductsSearchResponse extends PaginedResponseT<StoreProduct> { }





export class StoreOrdersSearchRequest extends PaginedRequest {

    @Expose()
    public id?: string;

    @Expose()
    public accountId?: string;

    @Expose()
    public status?: StoreOrderStatus;
}

export class StoreOrdersSearchResponse extends PaginedResponse {

    @Expose()
    public items = new Array<StoreOrder>();
}


/**
 * Minimalist StoreOrderItem without any unthrustable data (prices), it is made to request orders toward the backend
 */
export class StoreOrderCreateRequestItem {

    @Expose()
    @IsString()
    public name = '';

    @Expose()
    @IsNumber()
    public quantity = 0;

    @Expose()
    @IsArray()
    public options = new Array<{ name: string, value: string | boolean }>();
}


export class StoreOrderCreateRequest {

    @Expose()
    @IsBoolean()
    @Transform(({ value }: { value: string }) => value === 'true')
    public isTermsOfSalesAccepted = false;

    @Expose()
    @Type(() => Address)
    public address = new Address();

    @Expose()
    @IsArray()
    public items = new Array<StoreOrderCreateRequestItem>();
}

export class StoreOrderUpdateStatusRequest {

    @Expose()
    public status!: StoreOrderStatus;
}

export class StoreOrderUpdateStatusResponse {

    @Expose()
    public status!: StoreOrderStatus;
}






