
export enum PmrSounds {

    OperationBriefing,

    OperationBriefingSpeechDestruction,
    OperationBriefingSpeechDomination,
    OperationBriefingSpeechDrops,
    OperationBriefingSpeechEncounter,
    OperationBriefingSpeechLooting,
    OperationBriefingSpeechPrisoners,
    OperationBriefingSpeechSandbox,
    //...

    OperationDeploying,
    OperationDebriefing,
    OperationStartSoon,
    OperationStarted,
    OperationPaused,
    OperationResumed,
    OperationCanceled,
    OperationCompleteSoon,
    OperationCompleted,

    OperatorCapturedTeamAlpha,
    OperatorCapturedTeamBravo,
    OperatorCapturedTeamDelta,
    OperatorCapturedTeamCharlie,

    PmrCheckAlpha,
    PmrCheckBravo,
    PmrCheckCharlie,
    PmrCheckDelta,
    PmrCheckStaff,
    PmrCheckBroadcast,

    TeamLeadsAlpha,
    TeamLeadsBravo,
    TeamLeadsCharlie,
    TeamLeadsDelta,
    TeamByAlpha,
    TeamByBravo,
    TeamByCharlie,
    TeamByDelta,

    AreaCapturedAlpha,
    AreaCapturedBravo,
    AreaCapturedCharlie,
    AreaCapturedDelta,
    AreaCapturedEcho,
    AreaCapturedFoxtrot,
    AreaCapturedGolf,
    AreaCapturedHotel,
    AreaCapturedIndia,
    AreaCapturedJuliett,
    AreaCapturedKilo,
    AreaCapturedLima,
    AreaCapturedMike,
    AreaCapturedNovember,
    AreaCapturedOscar,
    AreaCapturedPapa,
    AreaCapturedQuebec,
    AreaCapturedRomeo,
    AreaCapturedSierra,
    AreaCapturedTango,
    AreaCapturedUniform,
    AreaCapturedVictor,
    AreaCapturedWiskey,
    AreaCapturedXRay,
    AreaCapturedYankee,
    AreaCapturedZulu,
    AreaCapturedArmory,
    AreaCapturedBank,
    AreaCapturedBridge,
    AreaCapturedChurch,
    AreaCapturedCityHall,
    AreaCapturedHeliport,
    AreaCapturedHospital,
    AreaCapturedLaboratory,
    AreaCapturedLaunchArea,
    AreaCapturedMotel,
    AreaCapturedPowerplant,
    AreaCapturedPrison,
    AreaCapturedRadioTower,
    AreaCapturedSawmill,
    AreaCapturedTorii,
    AreaCapturedTrainStation,
    AreaCapturedTunnel,
    AreaCapturedVillage,
    AreaCapturedWorkshop,

    AreaDropAlpha,
    AreaDropBravo,
    AreaDropCharlie,
    AreaDropDelta,
    AreaDropEcho,
    AreaDropFoxtrot,
    AreaDropGolf,
    AreaDropHotel,
    AreaDropIndia,
    AreaDropJuliett,
    AreaDropKilo,
    AreaDropLima,
    AreaDropMike,
    AreaDropNovember,
    AreaDropOscar,
    AreaDropPapa,
    AreaDropQuebec,
    AreaDropRomeo,
    AreaDropSierra,
    AreaDropTango,
    AreaDropUniform,
    AreaDropVictor,
    AreaDropWiskey,
    AreaDropXRay,
    AreaDropYankee,
    AreaDropZulu,
    AreaDropArmory,
    AreaDropBank,
    AreaDropBridge,
    AreaDropChurch,
    AreaDropCityHall,
    AreaDropHeliport,
    AreaDropHospital,
    AreaDropLaboratory,
    AreaDropLaunchArea,
    AreaDropMotel,
    AreaDropPowerplant,
    AreaDropPrison,
    AreaDropRadioTower,
    AreaDropSawmill,
    AreaDropTorii,
    AreaDropTrainStation,
    AreaDropTunnel,
    AreaDropVillage,
    AreaDropWorkshop,

    AreaDropCatched, // ?
    AreaDropCatchedAlpha,
    AreaDropCatchedBravo,
    AreaDropCatchedCharlie,
    AreaDropCatchedDelta,
    AreaDropCatchedEcho,
    AreaDropCatchedFoxtrot,
    AreaDropCatchedGolf,
    AreaDropCatchedHotel,
    AreaDropCatchedIndia,
    AreaDropCatchedJuliett,
    AreaDropCatchedKilo,
    AreaDropCatchedLima,
    AreaDropCatchedMike,
    AreaDropCatchedNovember,
    AreaDropCatchedOscar,
    AreaDropCatchedPapa,
    AreaDropCatchedQuebec,
    AreaDropCatchedRomeo,
    AreaDropCatchedSierra,
    AreaDropCatchedTango,
    AreaDropCatchedUniform,
    AreaDropCatchedVictor,
    AreaDropCatchedWiskey,
    AreaDropCatchedXRay,
    AreaDropCatchedYankee,
    AreaDropCatchedZulu,
    AreaDropCatchedArmory,
    AreaDropCatchedBank,
    AreaDropCatchedBridge,
    AreaDropCatchedChurch,
    AreaDropCatchedCityHall,
    AreaDropCatchedHeliport,
    AreaDropCatchedHospital,
    AreaDropCatchedLaboratory,
    AreaDropCatchedLaunchArea,
    AreaDropCatchedMotel,
    AreaDropCatchedPowerplant,
    AreaDropCatchedPrison,
    AreaDropCatchedRadioTower,
    AreaDropCatchedSawmill,
    AreaDropCatchedTorii,
    AreaDropCatchedTrainStation,
    AreaDropCatchedTunnel,
    AreaDropCatchedVillage,
    AreaDropCatchedWorkshop,

    AreaDestroyedAlpha,
    AreaDestroyedBravo,
    AreaDestroyedCharlie,
    AreaDestroyedDelta,
    AreaDestroyedEcho,
    AreaDestroyedFoxtrot,
    AreaDestroyedGolf,
    AreaDestroyedHotel,
    AreaDestroyedIndia,
    AreaDestroyedJuliett,
    AreaDestroyedKilo,
    AreaDestroyedLima,
    AreaDestroyedMike,
    AreaDestroyedNovember,
    AreaDestroyedOscar,
    AreaDestroyedPapa,
    AreaDestroyedQuebec,
    AreaDestroyedRomeo,
    AreaDestroyedSierra,
    AreaDestroyedTango,
    AreaDestroyedUniform,
    AreaDestroyedVictor,
    AreaDestroyedWiskey,
    AreaDestroyedXRay,
    AreaDestroyedYankee,
    AreaDestroyedZulu,
    AreaDestroyedArmory,
    AreaDestroyedBank,
    AreaDestroyedBridge,
    AreaDestroyedChurch,
    AreaDestroyedCityHall,
    AreaDestroyedHeliport,
    AreaDestroyedHospital,
    AreaDestroyedLaboratory,
    AreaDestroyedLaunchArea,
    AreaDestroyedMotel,
    AreaDestroyedPowerplant,
    AreaDestroyedPrison,
    AreaDestroyedRadioTower,
    AreaDestroyedSawmill,
    AreaDestroyedTorii,
    AreaDestroyedTrainStation,
    AreaDestroyedTunnel,
    AreaDestroyedVillage,
    AreaDestroyedWorkshop,

    Caution,
    Success,
    Explosion,
    Copter,
    AssemblyPart,
    AssemblyComplete, // TODO
    AssemblyCompleteMissile,

    EEPDR,
    EEFW,

    Success1,
    Success2,
    Success3,

    Music1,
    Music2,
    Music3,
    Music4
}
