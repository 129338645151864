
<app-header></app-header>

<div class="content">
    <router-outlet (activate)="activate()"></router-outlet>
</div>

<app-footer></app-footer>

<app-underground *ngIf="isUndergroundEnabled"></app-underground>

<app-cookies-wall></app-cookies-wall>

<wui-overlay></wui-overlay>

<app-particles></app-particles>
