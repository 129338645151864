import { Component, Input } from '@angular/core';
import { OverlayService } from '@lightning/wild-ui';

@Component({
    selector: 'app-modal-yes-no',
    templateUrl: './modal-yes-no.component.html',
    styleUrls: ['./modal-yes-no.component.scss'],
})
export class ModalYesNoComponent {

    @Input()
    public resolve = (value: boolean) => Function;

    @Input()
    public title  = '';

    @Input()
    public message = '';

    constructor(
        private overlayService: OverlayService) { }

    public yes(): void {

        this.resolve(true);

        this.overlayService.closeModal();
    }

    public no(): void {

        this.resolve(false);

        this.overlayService.closeModal();
    }

}
