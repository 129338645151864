import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { OverlayService } from '@lightning/wild-ui';
import { AccountService } from '@lightning/website/app/account/services/account/account.service';

import { ScrollService } from '../../services/scroll/scroll.service';
import { MenuComponent } from '../menu/menu.component';
import { StoreService } from '../../../store/services/store.service';
import { PersonalComponent } from '../personal/personal.component';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

    constructor(
        public accountService: AccountService,
        public scrollService: ScrollService,
        public storeService: StoreService,
        private router: Router,
        private overlayService: OverlayService,
        ) { }

    public openAccount(): void {

        if(!this.accountService.isLoggedIn) {

            this.accountService.login();

            return;
        }

        this.overlayService.openModal({
            component: PersonalComponent
        });
    }

    public openMenu(): void {

        this.overlayService.openModal({
            component: MenuComponent
        });
    }

}
