<wui-modal-layout>

    <div title (click)="randomFill()">{{ 'shared.signin' | translate }}</div>

    <div body>

        <wui-section [wuiDisabled]="isLoading">

            @if(!signinConfirmRequest.email) {

                <wui-input
                    type="text"
                    [(value)]="signinRequest.name"
                    [placeholder]="'shared.firstName' | translate"
                    autocomplete="given-name"
                    [required]="true">
                </wui-input>

                <wui-input
                    type="email"
                    [(value)]="signinRequest.email"
                    [placeholder]="'shared.email' | translate"
                    autocomplete="email"
                    [required]="true">
                </wui-input>

                <wui-input
                    type="password"
                    [(value)]="signinRequest.password"
                    [placeholder]="'shared.password' | translate"
                    [min]="8">
                </wui-input>

                <wui-input
                    type="password"
                    [(value)]="passwordConfirm"
                    [placeholder]="'shared.passwordConfirm' | translate"
                    [min]="8">
                </wui-input>

            } @else {

                <wui-input
                    type="text"
                    [(value)]="signinConfirmRequest.code"
                    [placeholder]="'shared.code' | translate"
                    pattern="^[A-Z0-9]{8}$">
                </wui-input>

            }

        </wui-section>

    </div>

    <div footer>
        <wui-buttons>

            @if(!signinConfirmRequest.email) {
                <wui-button (click)="signin()" [wuiDisabled]="isSigninInvalid">{{ 'shared.signin' | translate }}</wui-button>
            } @else {
                <wui-button (click)="signinConfirm()" [wuiDisabled]="isSigninConfirmInvalid">{{ 'shared.confirm' | translate }}</wui-button>
            }
        </wui-buttons>
    </div>

</wui-modal-layout>
