<div class="ground" [style.height]="height + 'px'">

    <div
        *ngFor="let item of items"
        class="item"
        [style.left]="item.left + '%'"
        [style.top]="item.top + 'px'"
        [style.transform]="'translate(-50%, -50%) rotate(' + item.rotation + 'deg)'"
        [style.width]="item.height + 'px'"
        [style.height]="item.height + 'px'"
        [style.backgroundImage]="'url(assets/easter/' + item .image + '.svg)'"
        [style.opacity]="item.opacity"
        [style.cursor]="item.click ? 'pointer' : 'default'"
        [wuiTacticalCorners]="item.corners"
        [wuiTacticalCornersOptions]="{ infinite: true }"

        (click)="item.click ? item.click($event) : null"></div>
</div>

<ng-container *ngIf="isAtMaxDeep">
    <app-stack *ngIf="!isCardFound">
        <div title>CONGRATULATIONS!</div>
        <div body>
            YOU DISCOVER (A PART) OF THE DEEPER EASTER EGG EVER MADE<br/>
            BUT YOU PROBABLY MISSED SOMETHING BETWEEN HERE AND THE SURFACE
        </div>
    </app-stack>

    <ng-container *ngIf="isCardFound">

        <app-stack>
            <div title>BIP!!! CLACK!!!</div>
            <div body>GREAT! YOU UNLOCKED THE UNDERLAB</div>
        </app-stack>

        <app-stack
            class="floor"
            logo="/assets/logos/lightning.svg">
            <div title>LIGHTNING UNDERLAB!</div>
            <div body>
                WELCOME TO THE UNDERLAB, THIS IS STRANGE AREA FOR FUTURE SECRET THINGS.<br/>
                THERE IS NOTHING AMAZING HERE FOR THE MOMENT :)
            </div>
        </app-stack>

        <!-- <app-stack
            *ngFor="let i of [].constructor(20); let index = index"
            class="floor"
            logo="/assets/logos/lightning.svg">
            <div title>UNDERLAB FLOOR {{ 20 - index }}</div>
            <div body>RESTRICTED AREA</div>
        </app-stack> -->

    </ng-container>
</ng-container>
