import { Component } from '@angular/core';
import { OverlayService } from '../../services/overlay/overlay.service';

@Component({
    selector: 'wui-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent {

    constructor(public overlayService: OverlayService) { }
}
