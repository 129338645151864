<wui-modal-layout>

    <div title>{{ 'shared.login' | translate }}</div>

    <div body>

        <wui-section [name]="'shared.regular' | translate">

            <wui-input
                type="email"
                [(value)]="request.email"
                autocomplete="email"
                [placeholder]="'shared.email' | translate">
            </wui-input>

            <wui-input
                type="password"
                [(value)]="request.password"
                autocomplete="password"
                [placeholder]="'shared.password' | translate">
            </wui-input>

            <div
                (click)="renewPassword()"
                [wuiDisabled]="!request.email"
                class="passwordLost">
                {{ 'shared.passwordLost' | translate }}
            </div>

            <wui-buttons>
                <wui-button (click)="signin()">{{ 'shared.signin' | translate }}</wui-button>
                <wui-button (click)="login()">{{ 'shared.login' | translate }}</wui-button>
            </wui-buttons>

        </wui-section>

        <wui-section [name]="'shared.or' | translate">

            <a [href]="loginWithGoogleUrl" target="_blank">
                <div class="google">
                    <img src="/assets/networks/google.svg">
                    Sign in with Google
                </div>
            </a>

        </wui-section>

    </div>

</wui-modal-layout>
