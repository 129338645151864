<wui-modal-layout>

    <div title>{{ 'cookies.title' | translate }}</div>

    <div body>

        <wui-section [name]="'cookies.learnMore.introduction.title' | translate">
            {{ 'cookies.learnMore.introduction.description' | translate }}
        </wui-section>

        <wui-section [name]="'cookies.learnMore.functional.title' | translate">
            {{ 'cookies.learnMore.functional.description' | translate }}
        </wui-section>

        <wui-section [name]="'cookies.learnMore.statistics.title' | translate">
            {{ 'cookies.learnMore.statistics.description' | translate }}
        </wui-section>

        <wui-section [name]="'cookies.learnMore.commercial.title' | translate">
            {{ 'cookies.learnMore.commercial.description' | translate }}
        </wui-section>

    </div>

    <div footer>
        <wui-buttons *ngIf="isProposalDisplayed">
            <wui-button (click)="decline()">{{ 'shared.decline' | translate }}</wui-button>
            <wui-button (click)="accept()">{{ 'shared.accept' | translate }}</wui-button>
        </wui-buttons>
        <wui-buttons *ngIf="!isProposalDisplayed">
            <wui-button (click)="accept()">{{ 'shared.continue' | translate }}</wui-button>
        </wui-buttons>
    </div>

</wui-modal-layout>
