<ng-container *ngFor="let device of groundModules">
    <div class="area"
        [wuiTacticalCorners]="device.data?.teamId"
        [wuiTacticalCornersOptions]="{infinite: true}">
        <div class="title" [style.backgroundColor]="getTeam(device)?.color">area</div>
        <div class="body">
            <div class="logo"
                [wuiBackgroundImage]="device.data?.teamId ? 'assets/apps/operations/domination/logo.svg' : ''"></div>
            <div class="name">{{ device.name }}</div>
            <!-- {{ device.data.teamId ? 'captured by team ' getTeam(device)?.name : '' }} -->
        </div>
    </div>
</ng-container>

<div *ngIf="groundModules.length === 0">⚠ no available ground modules</div>
