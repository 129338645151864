export interface OperationPhotography {

    id?: any;
    authorAccountId: string;
    sessionId: string;

    type: string;
    image: Buffer;
    hash: string;

    date: Date;
    specs: any;
}
