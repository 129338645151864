import { SafeHtml } from '@angular/platform-browser';

export enum SpriteTypes {
    Circle = 'circle',
    Line = 'line',
    Text = 'text',
    Svg = 'svg'
};

export type Sprite = {
    id: string;
    type?: SpriteTypes | string;
    class?: string;

    x?: number;
    x1?: number;
    x2?: number;

    y?: number;
    y1?: number;
    y2?: number;

    z?: number;
    angle?: number;
    scale?: number;

    fillColor?: string;

    strokeColor?: string;
    strokeWidth?: number;

    radius?: number;

    text?: string;
    fontSize?: number;
    fontFamily?: string;

    url?: string;
    content?: SafeHtml;

    delete?: boolean;
};

export type Camera = {
    x?: number;
    y?: number;
    zoom?: number;
    class?: string;
}

export type Scene = {
    sprites: Array<Sprite>;
    camera: Camera;
    subtitle?: string;
};

export type Frame = {
    id?: string;
    sprites?: Array<Sprite>;
    camera?: Camera;
    subtitle?: string;
    jump?: string;
    duration?: number;
}
