import { Component, OnInit,  ElementRef } from '@angular/core';

@Component({
    selector: 'wui-expendable',
    templateUrl: './expendable.component.html',
    styleUrls: ['./expendable.component.scss']
})
export class ExpendableComponent implements OnInit {

    public normalHeight = '';
    public expendedHeight = '';
    public isExpended = false;

    private previewElement: HTMLElement | undefined;

    constructor(private elementRef: ElementRef) { }

    ngOnInit() {

        this.previewElement = this.elementRef.nativeElement.querySelector('.preview');

        if(!this.previewElement) {
            return;
        }

        this.normalHeight = window.getComputedStyle(this.previewElement).height;
        this.expendedHeight = (parseFloat(this.normalHeight.replace('px', '')) * 1.5) + 'px';
    }

    public toggle(): void {

        this.isExpended = !this.isExpended;

        // Stopped to work!
        if (this.isExpended) {
            this.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
    }

}
