<ng-container *ngFor="let team of teams">
    <div class="team" *ngIf="team.role">
        <div class="title" [style.backgroundColor]="team.color">team {{ team.id }}</div>
        <div class="body">
            <div class="role">{{team.role}}</div>
            <div class="points">
                <div class="value">{{ team.score.points | currency:pointsCurrency:'symbol':'1.0' }}</div>
                <div class="limit" *ngIf="pointsLimit > 0">{{'/' + (pointsLimit | currency:' ':'symbol':'1.0') }}</div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="teams.length === 0">⚠ no available teams</div>