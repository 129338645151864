
<!-- Todo : replace 1000px by the computed height preview + content -->

<div class="expendable"  [style.maxHeight]="isExpended ?        '10000px' : normalHeight">
    <div class="preview" [style.height]=   "isExpended ? 'expendedHeight' : normalHeight" (click)="toggle()">
        <ng-content select="[preview]"></ng-content>
    </div>
    <div class="content">
        <ng-content select="[content]"></ng-content>
    </div>
</div>