
import { IsDate, IsEmail } from '@lightning/decorators';

export class NewsLettersSubscription {

    public id?: any;

    @IsEmail()
    public email = '';

    @IsDate()
    public date = new Date();
}
