<wui-modal-layout>

    <div title>{{ 'accounts.renewPassword.title' | translate }}</div>

    <div body>

        <wui-section [wuiDisabled]="isLoading">

            <wui-input
                type="password"
                [(value)]="request.password"
                [placeholder]="'shared.password' | translate"
                [min]="8">
            </wui-input>

            <wui-input
                type="password"
                [(value)]="passwordConfirm"
                placeholder="{{ 'shared.password' | translate }} *"
                [min]="8">
            </wui-input>

            <wui-input
                type="text"
                [(value)]="request.code"
                [placeholder]="'shared.code' | translate"
                pattern="^[A-Z0-9]{8}$">
            </wui-input>

        </wui-section>

    </div>

    <div footer>
        <wui-buttons>
            <wui-button (click)="cancel()">
                {{ 'shared.cancel' | translate }}
            </wui-button>
            <wui-button (click)="confirm()" [wuiDisabled]="isInvalid">
                {{ 'shared.confirm' | translate }}
            </wui-button>
        </wui-buttons>
    </div>

</wui-modal-layout>

