import { Component, Input, OnInit } from '@angular/core';
import { RenewPasswordConfirmRequest } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService } from '@lightning/wild-ui';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';

@Component({
    selector: 'app-renew-password',
    templateUrl: './renew-password.component.html',
    styleUrls: ['./renew-password.component.scss']
})
export class RenewPasswordComponent implements OnInit {

    @Input()
    public set email(email: string) {
        this.request.email = email;
    }

    public request = new RenewPasswordConfirmRequest();

    public passwordConfirm = '';

    public isLoading = false;

    constructor(
        private translateService: TranslateService,
        private onlineService: OnlineService,
        private overlayService: OverlayService) { }

    public ngOnInit(): void {

        this.sendCode();
    }

    public get isInvalid(): boolean {
        return  !this.request.email ||
                !this.request.code ||
                !this.request.password ||
                !this.passwordConfirm || this.request.password !== this.passwordConfirm;
    }

    public sendCode(): void {

        this.onlineService.renewPassword({
            email: this.request.email
        }).subscribe({
            next: () => {

                this.overlayService.openNotification({
                    message: this.translateService.instant('accounts.renewPassword.emailSent'),
                    type: 'success'
                });
            },
            error: () => {
                this.overlayService.closeModal();
            }
        });
    }

    public cancel(): void {

        this.overlayService.closeModal();
    }

    public confirm(): void {

        if (this.isInvalid) {
            return;
        }

        this.isLoading = true;

        this.onlineService.renewPasswordConfirm(this.request)
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe({
                next: (account) => {

                    if (account) {

                        this.overlayService.openNotification({
                            message: this.translateService.instant('accounts.renewPassword.success'),
                            type: 'success'
                        });

                        this.overlayService.closeModal();
                    }
                }
            });
    }
}
