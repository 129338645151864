import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SigninRequest } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService } from '@lightning/wild-ui';
import { Router } from '@angular/router';


@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
})
export class SigninComponent {

    public request = new SigninRequest();

    public passwordConfirm = '';

    public isLoading = false;

    constructor(
        private router: Router,
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private translateService: TranslateService) {

        // Url to come back after the signin process
        this.request.initialUrl = this.router.routerState.snapshot.url;

    }

    public get isInvalid(): boolean {

        return !this.request.email ||
            !this.request.password ||
            this.request.password != this.passwordConfirm ||
            !this.request.name;
    }

    public signin(): void {

        this.isLoading = true;

        this.onlineService.signin(this.request).subscribe({
            next: () => {

                this.overlayService.openNotification({
                    message: this.translateService.instant('accounts.signin.emailSent'),
                    type: 'success',
                    duration: 10000
                });

                this.overlayService.closeModal();
            },
            error: () => {
                this.isLoading = false;
            }
        });

    }

    // To make the development easier
    public randomFill(): void {

        this.request = {
            name: 'test',
            email: Math.round(Math.random() * 1000) + 'test@gmail.com',
            password: 'pass'
        };

        this.passwordConfirm = this.request.password;
    }

}
