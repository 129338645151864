
<div class="item" (click)="navigateTo('discovery'); $event.stopPropagation()">{{ 'discovery.title' | translate | uppercase }}</div>
<div class="item" (click)="navigateTo('operations/modes'); $event.stopPropagation()">{{ 'operations.modes.title' | translate | uppercase }}</div>
<div class="item" (click)="navigateTo('operations/reports'); $event.stopPropagation()">{{ 'operations.reports.title' | translate | uppercase }}</div>
<div class="item" (click)="navigateTo('store'); $event.stopPropagation()">{{ 'store.title' | translate | uppercase }}</div>

<div class="item" (click)="navigateTo('photographies/upload'); $event.stopPropagation()">{{ 'photographies.title' | translate | uppercase }}</div>
<div class="item" (click)="navigateTo('pmr/baofeng'); $event.stopPropagation()">{{ 'pmr.baofeng.title' | translate | uppercase }}</div>
<div class="item" (click)="navigateTo('downloads'); $event.stopPropagation()">{{ 'downloads.title' | translate | uppercase }}</div>

<div class="item" *ngIf="isAdministrator" (click)="openBackoffice()">{{ 'shared.backoffice' | translate | uppercase }}</div>
