import { Score } from './score.entity';

export class TeamSelector {

    public id = '';

    public name = '';

    public color = '';
}

export class TeamUnderOperation extends TeamSelector {

    public operatorsCount = 0;

    public pmr = '';

    public isStaff?: boolean;

    public role?: string;

    public score: Score = {
        points: 0,
        rank: 0
    };

    public data = new TeamUnderOperationData();
}

class TeamUnderOperationData {

    public steps?: Array<{ isDone: boolean, number: number }>;  // Assembly

    public areas?: Array<{ id: string, value: number }>;        // Drop

    public isDead?: boolean;                                    // Encounter (remove)

    public respawnsCount?: number;                              // Encounter (remove)

    public prison?: string;                                     // prisoner (rename it own area id? or remove it and use operation settings instead?)

}
