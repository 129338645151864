import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[wuiScrolledBottom]'
})
export class ScrolledDirective implements OnInit {

    @Input('wuiScrolledBottomThreshold')
    public threshold = 10;

    @Output('wuiScrolledBottom')
    public event = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) { }

    public ngOnInit(): void {

        this.elementRef.nativeElement.onscroll = (event: any) => {

            if ((event.target.scrollHeight - event.target.offsetHeight) - event.target.scrollTop <= this.threshold) {
                this.event.emit();
            }
        }
    }
}
