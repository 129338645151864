export enum OperationTrophiesTypes {

    PrisonersCapturedFirstOne = "PrisonersCapturedFirstOne",
    PrisonersCapturedBiggestTotal = "PrisonersCapturedBiggestTotal",

    DropsFirstOne = "DropsFirstOne",
    DropsBiggestTotal = "DropsBiggestTotal",

    LootingFirstOne = "LootingFirstOne",
    LootingBiggestOne = "LootingBiggestOne",
    LootingBiggestTotal = "LootingBiggestTotal",

    DominationCapturesFirstOne = "DominationCapturesFirstOne",
    DominationCapturesBiggestTotal = "DominationCapturesBiggestTotal",

    AssemblyFirstOne = "AssemblyFirstOne",
    AssemblyBiggestTotal = "AssemblyBiggestTotal"

}
