import { ApiProperty, ApiPropertyOptional, IsBoolean, IsEnum, Type, Transform, IsNotEmpty, IsOptional } from '@lightning/decorators';
import { Firmware } from '../entities/firmware.entity';
import { ProductsWithSoftware } from '../enums/products.enum';
import { SoftwaresChannels } from '../enums/softwares.enum';
import { PaginedRequest, PaginedResponse } from './pagined.dto';


export class FirmwaresSearchRequest extends PaginedRequest {

    @ApiPropertyOptional()                      // Equals: @ApiProperty({ required: false })
    public id?: string;

    @ApiPropertyOptional()
    public name?: string;

    @ApiPropertyOptional()
    public version?: string;

    @ApiPropertyOptional({ enum: SoftwaresChannels })
    public channel?: SoftwaresChannels;

    @ApiPropertyOptional({ enum: ProductsWithSoftware })
    public targetProduct?: ProductsWithSoftware;

    @ApiPropertyOptional()
    public targetHardwareVersion?: string;
}

export class FirmwaresSearchResponse extends PaginedResponse {
    public items: Array<Firmware> = [];
}

export class FirmwaresLatestRequest {

    @ApiProperty()
    @IsNotEmpty()
    public serial!: string;

    @ApiProperty({ enum: ProductsWithSoftware })
    @IsEnum(ProductsWithSoftware)
    public product!: ProductsWithSoftware;

    @ApiProperty()
    @IsNotEmpty()
    public hardwareVersion!: string;

    @ApiProperty()
    @IsNotEmpty()
    public firmwareVersion!: string;

    @ApiPropertyOptional({ enum: SoftwaresChannels, default: SoftwaresChannels.Main })
    public channel?: SoftwaresChannels;
}


export class FirmwareCreateFormDataRequest {

    // This is a form data request, file, blob and string only
    // Use @Type decorator to convert number and boolean
    // Flat objets (no nested)

    @ApiProperty()
    @IsNotEmpty()
    public name!: string;

    @ApiProperty()
    @IsNotEmpty()
    public version!: string;

    @ApiProperty()
    @IsNotEmpty()
    public description!: string;

    @ApiProperty({ enum: SoftwaresChannels })
    @IsEnum(SoftwaresChannels)
    public channel!: SoftwaresChannels;

    // @ApiProperty()
    // @Type(() => String)                                             // they
    // @Transform(({ value }: { value: string }) => value === 'true')  // work
    // @IsBoolean()                                                    // together
    // public isActive!: boolean;

    @ApiProperty({ enum: ProductsWithSoftware })
    @IsEnum(ProductsWithSoftware)
    public targetProduct!: ProductsWithSoftware;

    @ApiProperty()
    @IsNotEmpty()
    public targetHardwareVersion!: string;

    @ApiProperty({ type: 'string', format: 'binary', required: true })  // Required by the backend
    public file!: any;                                                  // Optional while filling in a form of the frontend (what?)
}

export class FirmwareUpdateFormDataRequest {

    // This is a form data request, file, blob and string only
    // Use @Type decorator to convert number and boolean
    // Flat objets (no nested)

    public id!: string;

    public name!: string;

    public version!: string;

    public description!: string;

    @IsEnum(SoftwaresChannels)
    public channel!: SoftwaresChannels;

    // @Type(() => String)                                             // they
    // @Transform(({ value }: { value: string }) => value === 'true')  // work
    // @IsBoolean()                                                    // together
    // public isActive!: boolean;

    @IsEnum(ProductsWithSoftware)
    public targetProduct!: ProductsWithSoftware;

    public targetHardwareVersion!: string;

    @ApiProperty({ type: 'string', format: 'binary' })  // Not required by the backend for update
    public file?: any;

}
