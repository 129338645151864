import { Component } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import { LoginRequest } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { OverlayService } from '@lightning/wild-ui';
import { AppsUtils } from '@lightning/configuration';

import { SigninComponent } from '../signin/signin.component';

import { environment } from '@lightning/website/environments/environment';
import { RenewPasswordComponent } from '../renew-password/renew-password.component';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    public request: LoginRequest = {
        email: '',
        password: ''
    }

    public loginWithGoogleUrl: SafeUrl = '';

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private onlineService: OnlineService) {

        const loginWithGoogleUrlParameters = new HttpParams({ fromObject: {
            client_id: environment.accounts.googleApi.id,
            redirect_uri: `${AppsUtils.resolveAppUrl('website')}/account/login-with-google-redirection`,
            scope: [
                'https://www.googleapis.com/auth/userinfo.email',
                'https://www.googleapis.com/auth/userinfo.profile',
            ].join(' '),
            response_type: 'code',
            access_type: 'offline',
            prompt: 'consent',
        }})
        .toString();

        this.loginWithGoogleUrl = `https://accounts.google.com/o/oauth2/v2/auth?${loginWithGoogleUrlParameters}`;

        // Url to come back after the signin process, query parameter not allowed by Google Signin.
        // A local storage is used instead.
        // This is not a great solution because each social app (Messenger, Gmail...) loves to use its own browser.
        localStorage.setItem('initial-url', this.router.routerState.snapshot.url);
    }

    public signin(): void {

        this.overlayService.closeModal();

        this.overlayService.openModal({
            component: SigninComponent
        });
    }

    public login(): void {

        if (!this.request.email || !this.request.password) {
            return;
        }

        this.onlineService.login(this.request).subscribe({
            next: () => {

                this.overlayService.openNotification({
                    message: this.translateService.instant('accounts.login.success', { name: this.onlineService.tokenPayload?.name }),
                    type: 'success'
                });

                if (this.router.url === '/restricted') {
                    this.router.navigate(['..']);
                }

                this.overlayService.closeModal();
            }
        });
    }

    public renewPassword(): void {

        const { email } = this.request;

        if (!email) {
            return;
        }

        this.overlayService.closeModal();

        this.overlayService.openModal({
            component: RenewPasswordComponent,
            inputs: {
                email
            }
        });

    }
}
