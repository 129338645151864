import { Component, EventEmitter, Input, Output } from '@angular/core';

export class GraphBarItem {

    public name = '';

    public color = '';

    public value = 1;

    public callback?: () => { /* */ };
}

class GraphBarItemWithPercents extends GraphBarItem {

    public percent = 0;
}


@Component({
  selector: 'wui-graph-bar',
  templateUrl: './graph-bar.component.html',
  styleUrls: ['./graph-bar.component.scss']
})
export class GraphBarComponent {

    public total = 0;

    public itemsx = new Array<GraphBarItemWithPercents>();

    @Input()
    public set items(items: Array<GraphBarItem>) {

        this.total = items.reduce((accumulator, current) => accumulator + (current.value || 0), 0);

        this.itemsx = items
            .filter(item => item.value > 0)
            .map(item => ({
                percent: Math.round((item.value / this.total) * 100),
                ...item
            })
        );
    }

    @Output()
    public clickItem = new EventEmitter<GraphBarItem>();
}
