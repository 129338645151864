<div class="item"
    *ngFor="let item of items; let index = index;"

    (click)="click(index, item)"
    [wuiScroll]="scrolling && index === (selectedIndex >= 0 ? selectedIndex : items.length - 1)"
    [wuiScrollOptions]="{ behavior: 'smooth', block: 'center', inline: 'center' }"

    [style.opacity]="selectedIndex >= 0 && selectedIndex < index ? 0.6 : 1">

    <div class="header">
        <div class="timestamp">+ {{ getTimestamp(item.time) | date: 'HH:mm:ss' }}</div>
        <div class="time" [ngClass]="index > 0 ? 'separator' : ''">{{ item.time | date: 'HH:mm:ss' }}</div>
    </div>
    <div class="body">
        <div class="left">
            <div class="icons" [ngClass]="['small', 'medium', 'big'][item.level]">
                <div class="action"     [wuiBackgroundImage]="getActionImage(item.action)"></div>
                <div class="secondary"  [wuiBackgroundImage]="getSecondaryActionImage(item.customIconPath)"></div>
            </div>
        </div>
        <div class="message">
            <div class="title">{{ getTitle(item.action) | translate: item.data }}</div>
            <div class="description">{{ getDescription(item.action) | translate: item.data }}</div>
            <div class="image" *ngIf="item.image" [wuiBackgroundImage]="item.image.url"></div>
        </div>
    </div>
</div>
