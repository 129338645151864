/* eslint-disable @angular-eslint/no-output-rename */
import { Directive, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[wuiResizable]'
})

export class ResizableDirective implements OnInit {

    @Input('wuiResizable')
    public options: {
        container: string;
        handles: Array<string>;
        limits: {
            minWidth?: number;
            minHeight?: number;
            maxWidth?: number;
            maxHeight?: number;
        }
    } | undefined;


    @Output('wuiResizeStart')
    private startEvent = new EventEmitter();

    @Output('wuiResizeStop')
    private stopEvent = new EventEmitter<any>();

    private elementTarget: HTMLElement | undefined;
    private elementContainer: HTMLElement | undefined;
    private elementHandleW: HTMLElement | undefined;
    private elementHandleE: HTMLElement | undefined;
    private elementHandleN: HTMLElement | undefined;
    private elementHandleS: HTMLElement | undefined;
    private elementHandleNW: HTMLElement | undefined;
    private elementHandleSW: HTMLElement | undefined;
    private elementHandleNE: HTMLElement | undefined;
    private elementHandleSE: HTMLElement | undefined;

    private isResizing = false;
    private resizingHandle = '';


    constructor(private elementRef: ElementRef) { }


    public ngOnInit() {

        if (!this.options) {
            return;
        }

        // Get elements
        this.elementTarget = this.elementRef.nativeElement;
        this.elementContainer = this.elementRef.nativeElement.closest(this.options.container);

        if (!this.elementContainer) {
            return;
        }


        // Extra handles options
        if (this.options.handles.indexOf('all') >= 0) {
            this.options.handles = ['w', 'e', 'n', 's', 'nw', 'sw', 'ne', 'se'];

        } else if (this.options.handles.indexOf('corners') >= 0) {
            this.options.handles = ['nw', 'sw', 'ne', 'se'];
        }


        // Handles size and position helpers
        const size = 10;                                                    // size
        const sizeH = size / 2;                                             // half size
        const sizeHN = 0 - sizeH;                                           // negative half size


        // West side handle
        if (this.options.handles.indexOf('w') >= 0) {
            this.elementHandleW = this.createHandle(
                sizeHN + 'px',                    // left
                '',                               // right
                sizeH + 'px',                     // top
                '',                               // bottom
                size + 'px',                      // width
                'calc(100% - ' + size + 'px)',    // height
                'ew-resize');                     // cursor

            this.elementHandleW.addEventListener('mousedown', () => this.start('w'));
            this.elementHandleW.addEventListener('touchstart', () => this.start('w'));
        }

        // East side handle
        if (this.options.handles.indexOf('e') >= 0) {
            this.elementHandleE = this.createHandle(
                '',                               // left
                sizeHN + 'px',                    // right
                sizeH + 'px',                     // top
                '',                               // bottom
                size + 'px',                      // width
                'calc(100% - ' + size + 'px)',    // height
                'ew-resize');                     // cursor

            this.elementHandleE.addEventListener('mousedown', () => this.start('e'));
            this.elementHandleE.addEventListener('touchstart', () => this.start('e'));
        }

        // North side handle
        if (this.options.handles.indexOf('n') >= 0) {
            this.elementHandleN = this.createHandle(
                sizeH + 'px',                     // left
                '',                               // right
                sizeHN + 'px',                    // top
                '',                               // bottom
                'calc(100% - ' + size + 'px)',    // width
                size + 'px',                      // height
                'ns-resize');                     // cursor

            this.elementHandleN.addEventListener('mousedown', () => this.start('n'));
            this.elementHandleN.addEventListener('touchstart', () => this.start('n'));
        }

        // South side handle
        if (this.options.handles.indexOf('s') >= 0) {
            this.elementHandleS = this.createHandle(
                sizeH + 'px',                     // left
                '',                               // right
                '',                               // top
                sizeHN + 'px',                    // bottom
                'calc(100% - ' + size + 'px)',    // width
                size + 'px',                      // height
                'ns-resize');                     // cursor

            this.elementHandleS.addEventListener('mousedown', () => this.start('s'));
            this.elementHandleS.addEventListener('touchstart', () => this.start('s'));
        }

        // North West corner handle
        if (this.options.handles.indexOf('nw') >= 0) {
            this.elementHandleNW = this.createHandle(
                sizeHN + 'px',                    // left
                '',                               // right
                sizeHN + 'px',                    // top
                '',                               // bottom
                size + 'px',                      // width
                size + 'px',                      // height
                'nwse-resize');                   // cursor

            this.elementHandleNW.addEventListener('mousedown', () => this.start('nw'));
            this.elementHandleNW.addEventListener('touchstart', () => this.start('nw'));
        }

        // South west corner handle
        if (this.options.handles.indexOf('sw') >= 0) {
            this.elementHandleSW = this.createHandle(
                sizeHN + 'px',                    // left
                '',                               // right
                '',                               // top
                sizeHN + 'px',                    // bottom
                size + 'px',                      // width
                size + 'px',                      // height
                'nesw-resize');                   // cursor

            this.elementHandleSW.addEventListener('mousedown', () => this.start('sw'));
            this.elementHandleSW.addEventListener('touchstart', () => this.start('sw'));
        }

        // Right top corner handle
        if (this.options.handles.indexOf('ne') >= 0) {
            this.elementHandleNE = this.createHandle(
                '',                               // left
                sizeHN + 'px',                    // right
                sizeHN + 'px',                    // top
                '',                               // bottom
                size + 'px',                      // width
                size + 'px',                      // height
                'nesw-resize');                   // cursor

            this.elementHandleNE.addEventListener('mousedown', () => this.start('ne'));
            this.elementHandleNE.addEventListener('touchstart', () => this.start('ne'));
        }

        // Right bottom corner handle
        if (this.options.handles.indexOf('se') >= 0) {
            this.elementHandleSE = this.createHandle(
                '',                               // left
                sizeHN + 'px',                    // right
                '',                               // top
                sizeHN + 'px',                    // bottom
                size + 'px',                      // width
                size + 'px',                      // height
                'nwse-resize');                   // cursor

            this.elementHandleSE.addEventListener('mousedown', () => this.start('se'));
            this.elementHandleSE.addEventListener('touchstart', () => this.start('se'));
        }


        // Resizing
        this.elementContainer.addEventListener('mousemove', (event: MouseEvent) => this.move(event));
        this.elementContainer.addEventListener('touchmove', (event: TouchEvent) => this.move(event));

        // Resize stop
        this.elementContainer.addEventListener('mouseup', (event: MouseEvent) => this.end(event));
        this.elementContainer.addEventListener('touchend', (event: TouchEvent) => this.end(event));
    }


    private start(handleName: string): void {

        if (!this.elementTarget) {
            return;
        }

        // Save the used handle name
        this.resizingHandle = handleName;

        // Disable selection highligh
        this.elementTarget.style.setProperty('-webkit-user-select', 'none');

        // Enable resizing
        this.isResizing = true;

        // Fire start event
        this.startEvent.emit();
    }

    private move(event: MouseEvent | TouchEvent): void {

        if (!this.isResizing) {
            return;
        }

        if (!this.elementTarget || !this.elementContainer) {
            return;
        }

        // Get the pointer location (from a touch or the mouse)
        const pointer = this.getPointerLocation(event);

        // Get computed location and size of the target
        const computedStyle = window.getComputedStyle(this.elementTarget, null);

        let left =   parseFloat(computedStyle.getPropertyValue('left').replace('px', ''));
        let top =    parseFloat(computedStyle.getPropertyValue('top').replace('px', ''));
        let width =  parseFloat(computedStyle.getPropertyValue('width').replace('px', ''));
        let height = parseFloat(computedStyle.getPropertyValue('height').replace('px', ''));

        // Horizontal resizing from the west side
        if (this.resizingHandle.indexOf('w') >= 0) {

            // Calculate the new width using mouse move
            const newWidth = width - (pointer.left - left);

            // Size checking
            if ((this.options?.limits.minWidth || 0) < newWidth) {

                width = newWidth;
                left = pointer.left;
            }
        }

        // Horizontal resizing from the east side
        if (this.resizingHandle.indexOf('e') >= 0) {

            // Calculate the new width using mouse move
            const newWidth = pointer.left - left;

            // Size checking
            if ((this.options?.limits.minWidth || 0) < newWidth) {

                width = newWidth;
            }
        }

        // Vertical resizing from the north side
        if (this.resizingHandle.indexOf('n') >= 0) {

            // Calculate the new height using mouse move
            const newHeight = height - (pointer.top - top);

            // Size checking
            if ((this.options?.limits.minHeight || 0) < newHeight) {

                height = newHeight;
                top = pointer.top;
            }
        }

        // Vertical resizing from the south side
        if (this.resizingHandle.indexOf('s') >= 0) {

            // Calculate the new width using mouse move
            const newHeight = pointer.top - top;

            // Size checking
            if ((this.options?.limits.minHeight || 0) < newHeight) {

                height = newHeight;
            }
        }

        // Apply new values as % to the target (% for responsive)
        this.elementTarget.style.left =   this.getComputedPercents(left, this.elementContainer, 'width');
        this.elementTarget.style.top =    this.getComputedPercents(top, this.elementContainer, 'height');
        this.elementTarget.style.width =  this.getComputedPercents(width, this.elementContainer, 'width');
        this.elementTarget.style.height = this.getComputedPercents(height, this.elementContainer, 'height');
    }

    private end(event: MouseEvent | TouchEvent): void {

        if (!this.isResizing) {
            return;
        }

        if (!this.elementTarget || !this.elementContainer) {
            return;
        }

        // Clear the used handle name
        this.resizingHandle = '';

        // Restore selection highligh
        this.elementTarget.style.setProperty('-webkit-user-select', 'initial');

        // Disable resizing
        this.isResizing = false;

        // Fire stop event
        this.stopEvent.emit({
            left: this.elementTarget.style.left,
            top:  this.elementTarget.style.top,
            width: this.elementTarget.style.width,
            height: this.elementTarget.style.height
        });

    }

    private getPointerLocation(event: MouseEvent | TouchEvent): { left: number, top: number } {

        const { pageX, pageY } = event instanceof MouseEvent ? event : event.changedTouches[0];

        return { left: pageX, top: pageY };
    }

    private getComputedPercents(pixels: number, container: HTMLElement, property: string): string {

        const containerSize =
            parseFloat(
                window
                .getComputedStyle(container)
                .getPropertyValue(property)
                .replace('px', ''));

        return ((pixels / containerSize) * 100) + '%';
    }

    private createHandle(left: string, right: string, top: string, bottom: string, width: string, height: string, cursor: string): HTMLDivElement {

        const   handle = document.createElement('div');
                handle.style.position = 'absolute';
                handle.style.left = left;
                handle.style.right = right;
                handle.style.top = top;
                handle.style.bottom = bottom;
                handle.style.width = width;
                handle.style.height = height;
                handle.style.cursor = cursor;

        this.elementRef.nativeElement.appendChild(handle);

        return handle;
    }

}
