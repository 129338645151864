

<wui-modal-layout>

    <div title>{{ title | translate }}</div>

    <div body>
        <div class="image"
            (click)="select()"
            [style.backgroundImage]="'url(' + patternUrl + '), url(' + imageUrl + '), url(' + placeholderUrl + ')'">
       </div>
    </div>

    <div footer>
        <wui-buttons>
            <wui-button (click)="cancel()">{{ 'shared.cancel' | translate }}</wui-button>
            <wui-button (click)="select()">{{ 'shared.import' | translate }}</wui-button>
        </wui-buttons>
    </div>

</wui-modal-layout>
