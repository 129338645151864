import { Component, Input } from '@angular/core';
import { LoraDevice, ProductsWithSoftware } from '@lightning/lightning-definitions';

@Component({
    selector: 'lui-operation-areas-roles',
    templateUrl: './operation-areas-roles.component.html',
    styleUrls: ['./operation-areas-roles.component.scss']
})

export class OperationAreasRolesComponent {

    @Input()
    public devices: Array<LoraDevice> = [];

    public get groundModules(): Array<LoraDevice> {

        return this.devices
            .filter(device => device['state']?.product === ProductsWithSoftware.GroundModule && device.isUsable)
            .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }

}
