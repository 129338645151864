import { Component, Input } from '@angular/core';

@Component({
  selector: 'wui-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})

export class EntryComponent {

  @Input()
  public name = '';

}
