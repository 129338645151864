
import { IsDate, IsObject, IsOptional, IsString } from '@lightning/decorators';
import { SementicVersion } from './sementic-version.entity';

export class DeviceRegistration {

    public id?: any;

    @IsDate()
    @IsOptional()
    public created = new Date();

    @IsString()
    public product = '';

    @IsString()
    public serial = '';

    @IsString()
    @IsOptional()
    public version = new SementicVersion();

    @IsObject()
    @IsOptional()
    public details?: any;
}
