import { Injectable, computed, effect, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Address, StoreProduct, StoreOrder, StoreOrderCreateRequest, StoreProductsSearchRequest, StoreProductsSearchResponse } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { CapitalizePipe, UncapitalizePipe, OverlayService } from '@lightning/wild-ui';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, of, tap } from 'rxjs';
import { StoreAddToCartComponent } from '../components/store-add-to-cart/store-add-to-cart.component';
import { LoginComponent } from '../../account/components/login/login.component';

const STORAGE_NAME = 'store-chart';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    public products = signal<Array<StoreProduct>>([]);

    public cartItems = signal<Array<StoreProduct>>([]);

    public cartItemsCount = computed(() => this.cartItems().reduce((accumulator, item) => accumulator + item.quantity.value, 0));

    public cartItemsPrice = computed(() => this.cartItems().reduce((accumulator, item) => accumulator + item.price.total, 0));

    private cartEffect = effect(() => {

        // Compute all items on change
        for (const item of this.cartItems()) {
            this.updateItemComputedValues(item);
        }

        // Save locally
        localStorage.setItem(STORAGE_NAME, JSON.stringify(this.cartItems()));
    });


    public constructor(
        private router: Router,
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private onlineService: OnlineService,
        private capitalizePipe: CapitalizePipe,
        private uncapitalize: UncapitalizePipe) {

        // Load cart content
        this.cartItems.update(() => JSON.parse(localStorage.getItem(STORAGE_NAME) || '[]'));
    }

    public loadProducts(): Observable<Array<StoreProduct>> {

        if (this.products().length > 0) {
            return of(this.products());
        }

        return this.onlineService.getStoreProducts(new StoreProductsSearchRequest())
            .pipe(
                map((response: StoreProductsSearchResponse) => response.items),
                tap((items) => { this.products.update(() => items); }));
    }

    public getProduct(name: string): StoreProduct {

        return this.products().find((product) => product.name === name) || new StoreProduct();
    }

    public async openProduct(product: StoreProduct): Promise<void> {

        if (this.onlineService.isTokenValid() === false) {

            this.overlayService.openNotification({
                message: this.translateService.instant('store.loginRequired'),
                type: 'information',
                duration: 10000
            });

            await this.overlayService.openModal({
                component: LoginComponent
            });

            if (this.onlineService.isTokenValid() === false) {
                return;
            }
        }

        await this.overlayService.openModal({
            component: StoreAddToCartComponent,
            inputs: {
                product: structuredClone(product)
            }
        });
    }

    public addToCart(product: StoreProduct): void {

        this.cartItems.update(cartItems => {

            const existing = cartItems
                .filter(i => i.name === product.name)[0];

            if (existing) {

                existing.quantity.value += product.quantity.value;

                if (existing.quantity.max && existing.quantity.value > existing.quantity.max) {

                    existing.quantity.value = existing.quantity.max;

                    this.overlayService.openNotification({
                        message: this.capitalizePipe.transform(this.translateService.instant('store.cart.maxQuantityReached', product)),
                        type: 'warning'
                    });

                    return cartItems;
                }

            } else {

                cartItems.push(product);
            }

            this.overlayService.openNotification({
                message: this.capitalizePipe.transform(this.translateService.instant('store.cart.added', product)),
                type: 'success',
                callback: () => {
                    this.router.navigate(['store', 'cart']);
                }
            });

            return [...cartItems];
        });
    }

    public updateItemComputedValues(product: StoreProduct): void {

        // Calculate the unit price (base + options)
        product.price.total = product.options
            .reduce((previous, option) => previous + (option.value ? option.price : 0), product.price.base);

        // Multiply by the quantity
        product.price.total *= product.quantity.value;

        // Update details from selected options
        product.details = product.options
            .map(option => {

                if (option.type === 'checkbox') {
                    return option.value ? this.translateService.instant('store.products.' + this.uncapitalize.transform(product.name) + '.cart.options.' + option.name + '.name') : '';
                }

                return option.value;
            })
            .filter(detail => detail)
            .join(' ');

        if (product.details) {
            product.details = `(${ product.details })`;
        }
    }

    public decreaseItemQuantity(product: StoreProduct): void {

        this.cartItems.update(cartItems => {

            if (product.quantity.value === 1) {

                return cartItems.filter(i => i !== product);
            }

            product.quantity.value--;

            return cartItems;

        });
    }

    public increaseItemQuantity(product: StoreProduct): void {

        if (product.quantity.max && product.quantity.value >= product.quantity.max) {
            return;
        }

        this.cartItems.update(cartItems => {

            product.quantity.value++;

            return cartItems;
        });
    }


    public clearCart(): void {

        this.cartItems.update(() => []);
    }

    public order(isTermsOfSalesAccepted: boolean, address: Address): Observable<StoreOrder> {

        if (!isTermsOfSalesAccepted || !address || this.cartItems().length === 0) {
            throw '';
        }

        // Create a new order from the data the backend needs and can thrust
        const order: StoreOrderCreateRequest = {
            isTermsOfSalesAccepted,
            address,
            // StoreProducts to StoreOrderItems
            items: this.cartItems().map(cartItem => { return {
                name: cartItem.name,
                options: cartItem.options
                    .map(option => { return { name: option.name, value: option.value }})
                    .filter(option => option.value),
                quantity: cartItem.quantity.value
            }})
        };

        // Request the creation of the order and clear the cart
        return this.onlineService
            .createStoreOrder(order)
            .pipe(tap(() => this.clearCart()));

    }

}
