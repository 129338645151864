import { Component, OnInit, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { OverlayService } from '../../services/overlay/overlay.service';
import { Modal } from '../../types/modal.type';

@Component({
    selector: 'wui-overlay-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnInit {

    @Input()
    public modal?: Modal;

    @ViewChild('content', { static: true, read: ViewContainerRef })
    private viewContainerRef?: ViewContainerRef;

    constructor(private overlayService: OverlayService) { }

    ngOnInit(): void {

        if (!this.modal || !this.viewContainerRef) {

            return;
        }

        // Create component instance
        const reference = this.viewContainerRef.createComponent(this.modal.component);

        // Assigning inputs
        for (const key in this.modal.inputs) {
            (reference.instance as any)[key] = this.modal.inputs[key];
        }

        // Insert to the DOM
        this.viewContainerRef.insert(reference.hostView);

        // Update
        reference.changeDetectorRef.detectChanges();
    }

    public close() {

        this.overlayService.closeModal();
    }

}
