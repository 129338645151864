<div class="item" wuiStopPropagation (click)="navigateTo('account')">
    {{ 'accounts.yours' | translate | uppercase }}
</div>

<div class="item" wuiStopPropagation (click)="navigateTo(accountService.account.operatorId ? '/operators/' + accountService.account.operatorId : 'account/no-operator-profile')">
    {{ 'operators.yours' | translate | uppercase }}
</div>

<div class="item" wuiStopPropagation (click)="navigateTo('/store/orders')">
    {{ 'store.orders.yours' | translate | uppercase }}
</div>

<div class="item" wuiStopPropagation (click)="navigateTo('/operations/reports/edit')">
    {{ 'operations.reports.yours' | translate | uppercase }}
</div>
