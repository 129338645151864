import { SementicVersion } from '../entities/sementic-version.entity';

export class SementicVersionUtils {

    public static parse(value: string): SementicVersion | undefined {

        if (!value) {
            return;
        }

        const fields = value.split('.', 3);

        if (fields.length !== 3) {

            return undefined;
        }

        return {
            major: parseInt(fields[0]),
            minor: parseInt(fields[1]),
            patch: parseInt(fields[2])
        }
    }

    public static toString(value: SementicVersion, options?: { ignorePatch: boolean }): string {

        return `${value.major}.${value.minor}.${options?.ignorePatch? 'X' : value.patch}`;
    }

    public static isEqual(a: SementicVersion, b: SementicVersion, options?: { ignorePatch: boolean }): boolean {

        return a.major === b.major && a.minor === b.minor && (options?.ignorePatch ? true : a.patch === b.patch);
    }

    public static isCompatible(version: SementicVersion, minimal: SementicVersion): boolean {

        // Inferior major
        if (version.major < minimal.major) {
            return false;
        }

        // Equal majour, inferior minor
        if (version.major === minimal.major && version.minor < minimal.minor) {
            return false;
        }

        // Equal or newer
        return true;
    }

    public static isCompatibleFromStrings(version: string, minimal: string): boolean {

        const sementicVersion = SementicVersionUtils.parse(version);

        const sementicMinimal = SementicVersionUtils.parse(minimal);

        if (sementicVersion && sementicMinimal) {
            return SementicVersionUtils.isCompatible(sementicVersion, sementicMinimal);
        }

        return false;
    }

}
