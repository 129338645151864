import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PaymentIntent } from '@stripe/stripe-js';
import {
    AccountJWTPayload,
    SigninRequest,
    SigninConfirmRequest,
    LoginRequest,
    LoginResponse,
    OperationPhotographiesSearchRequest,
    OperationPhotographiesSearchResponse,
    OperationReport,
    OperationReportCreatedResponse,
    OperationReportPreview,
    OperationsReportsSearchRequest,
    OperationsReportsSearchResponse,
    OperatorProfile,
    OperatorAvatarName,
    AccountsSearchRequest,
    AccountsSearchResponse,
    OperatorsSearchRequest,
    OperatorsSearchResponse,
    Dashboard,
    Host,
    RenewPasswordRequest,
    RenewPasswordConfirmRequest,
    Firmware,
    FirmwaresSearchRequest,
    FirmwaresSearchResponse,
    FirmwareCreateFormDataRequest,
    FirmwareUpdateFormDataRequest,
    OperatorsUpdateRequest,
    LoginWithGoogleRequest,
    Address,
    Account,
    StoreOrderCreateRequest,
    StoreOrder,
    StoreProductsSearchRequest,
    StoreProductsSearchResponse,
    StoreOrdersSearchRequest,
    StoreOrdersSearchResponse,
    DeviceCreateNfcTagOperatorRequest,
    DeviceNfcTags,
    OperatorsCreateRequest,
    DevicesRegistrationsSearchRequest,
    DevicesRegistrationsSearchResponse,
    NewsLetterSubscribeRequest,
    DeviceCreateNfcTagGuestRequest,
    DeviceCreateNfcTagResourceRequest,
    DeviceCreateNfcTagPartRequest,
    StoreOrderStatus,
    StoreOrderUpdateStatusResponse,
    DeviceCreateNfcTagSystemRequest,
    OperationsSession,
    DeviceCreateNfcTagLocationRequest,
    DeviceCreateGroundModuleRequest,
    DeviceCreateHeadquarterGatewayRequest,
    DeviceRegistration,
    TrackingCreateRequest,
} from '@lightning/lightning-definitions';
import { AppsUtils } from '@lightning/configuration'

const STORAGE_NAME_TOKEN = 'token';
const AVAILABILITY_CHECKING_INTERVAL = 60000;

@Injectable({
    providedIn: 'root'
})

export class OnlineService {

    private _backendUrl: string;
    private _token: string | undefined;
    private _tokenPayload: AccountJWTPayload | undefined;
    private _expiration: Date | undefined;
    private _isAvailable = false;

    constructor(private http: HttpClient) {

        // Load autentication token
        const storedToken = localStorage.getItem(STORAGE_NAME_TOKEN);

        if (storedToken) {
            this.token = storedToken;
        }

        // Get the url of the backend
        this._backendUrl =
            new URLSearchParams(window.location.search).get('backendUrl') ||    // Given by the host (electron app)
            AppsUtils.resolveAppUrl('backend') ||                               // Resolved from the current url (web app)
            '';                                                                 // Unknown

        // Backend availability checking
        setTimeout( this.updateAvailability.bind(this), 1000);
        setInterval(this.updateAvailability.bind(this), AVAILABILITY_CHECKING_INTERVAL);
    }

    public get backendUrl(): string {

        return this._backendUrl;
    }

    // ------------------------------------------------------------------------------------
    // Authentication
    // ------------------------------------------------------------------------------------


    public set token(token: string | undefined) {

        this._token = token;

        this._tokenPayload = this._token ? JSON.parse(atob(this._token.split('.')[1])) : undefined;

        if (this._tokenPayload) {

            // The timestamps in JWT are UNIX timestamps counting from 01.01.1970 00:00 UTC
            this._expiration = new Date(0);
            this._expiration.setUTCSeconds(this._tokenPayload.exp);

        } else {

            this._expiration = undefined;
        }

        localStorage.setItem(STORAGE_NAME_TOKEN, token || '');
    }

    public get token(): string | undefined {

        return this._token;
    }

    public get expiration(): Date | undefined {

        return this._expiration;
    }

    public get tokenPayload(): AccountJWTPayload | undefined {

        // Don't get an invalid token payload
        if (this.isTokenValid === false) {
            return undefined;
        }

        return this._tokenPayload;
    }

    public get isTokenValid(): boolean {

        return (
            this._token !== undefined &&
            this._expiration !== undefined &&
            this._expiration.getTime() > new Date().getTime());
    }

    public signin(request: SigninRequest): Observable<void> {

        return this.http.post<void>(`${this.backendUrl}/accounts/signin`, request);
    }

    public signinConfirm(request: SigninConfirmRequest): Observable<AccountJWTPayload | undefined> {

        return this.http.post<LoginResponse>(`${this.backendUrl}/accounts/signin-confirm`, request)
            .pipe(map(response => {

                this.token = response.token;

                return this.tokenPayload;
            }));
    }

    public renewPassword(request: RenewPasswordRequest): Observable<void> {

        return this.http.post<void>(`${this.backendUrl}/accounts/renew-password`, request);
    }

    public renewPasswordConfirm(request: RenewPasswordConfirmRequest): Observable<AccountJWTPayload | undefined> {

        return this.http.post<LoginResponse>(`${this.backendUrl}/accounts/renew-password-confirm`, request)
            .pipe(map(response => {

                this.token = response.token;

                return this.tokenPayload;
            }));
    }

    public getFreshToken(): Observable<AccountJWTPayload | undefined> {

        return this.http.get<LoginResponse>(`${this.backendUrl}/accounts/token`)
            .pipe(map(response => {

                this.token = response.token;

                return this.tokenPayload;
            }));
    }

    public login(request: LoginRequest): Observable<AccountJWTPayload | undefined> {

        return this.http.post<LoginResponse>(`${this.backendUrl}/accounts/login`, request)
            .pipe(map(response => {

                this.token = response.token;

                return this.tokenPayload;
            }));
    }

    public logout(): void {

        this.token = undefined;

        localStorage.removeItem('token');
    }

    public loginWithGoogle(request: LoginWithGoogleRequest): Observable<AccountJWTPayload | undefined> {

        return this.http.post<LoginResponse>(`${this.backendUrl}/accounts/login-with-google`, request)
            .pipe(map(response => {

                this.token = response.token;

                return this.tokenPayload;
            }));
    }


    public checkJWTAccess(): void {

        this.http.get<any>(`${this.backendUrl}/accounts/checkJWTAccess`)
            .subscribe({
                next: (response) => {

                    console.log(response);

                    alert('Allowed');
                },
                error: (response) => {

                    console.error(response);

                    alert('Denied');
                }
            });
    }


    public getAccount(id: string): Observable<Account> {

        return this.http.get<Account>(`${this.backendUrl}/accounts/${id}`);
    }

    public addAccountAddress(id: string, address: Address): Observable<Array<Address>> {

        return this.http.post<Array<Address>>(`${this.backendUrl}/accounts/${id}/addresses`, address);
    }

    public updateAccountAddress(id: string, address: Address): Observable<Array<Address>> {

        return this.http.patch<Array<Address>>(`${this.backendUrl}/accounts/${id}/addresses`, address);
    }

    public deleteAccountAddress(id: string, addressId: string): Observable<Array<Address>> {

        return this.http.delete<Array<Address>>(`${this.backendUrl}/accounts/${id}/addresses/${addressId}`,);
    }


    // ------------------------------------------------------------------------------------
    // Backend availability
    // ------------------------------------------------------------------------------------

    private updateAvailability(): void {

        if (navigator.onLine === false) {

            this._isAvailable = false;

            return;
        }

        this.http.get<void>(`${this.backendUrl}/state/check`)
            .subscribe({
                next: () => {
                    this._isAvailable = true;
                },
                error: () => {
                    this._isAvailable = false;
                }
            });
    }

    public get isAvailable(): boolean {

        return this._isAvailable;
    }

    public get isAvailableAndLoggedIn(): boolean {

        return this._isAvailable && this.isTokenValid;
    }


    // ------------------------------------------------------------------------------------
    // Accounts
    // ------------------------------------------------------------------------------------

    public searchAccounts(request: AccountsSearchRequest): Observable<AccountsSearchResponse> {

        const params = request as any;

        return this.http.get<AccountsSearchResponse>(`${this.backendUrl}/accounts`, { params });
    }

    public deleteAccount(id: string): Observable<void> {

        return this.http.delete<void>(`${this.backendUrl}/accounts/${id}`);
    }

    public getOperator(id: string | number): Observable<OperatorProfile> {

        return this.http.get<OperatorProfile>(`${this.backendUrl}/operators/${id}`);
    }

    public searchOperators(request: OperatorsSearchRequest): Observable<OperatorsSearchResponse> {

        const params = request as any;

        return this.http.get<OperatorsSearchResponse>(`${this.backendUrl}/operators`, { params });
    }

    public createOperator(request: OperatorsCreateRequest): Observable<OperatorProfile> {

        return this.http.post<OperatorProfile>(`${this.backendUrl}/operators`, request);
    }

    public updateOperator(id: string, request: OperatorsUpdateRequest): Observable<OperatorProfile> {

        return this.http.patch<OperatorProfile>(`${this.backendUrl}/operators/${id}`, request);
    }

    public deleteOperator(id: string): Observable<void> {

        return this.http.delete<void>(`${this.backendUrl}/operators/${id}`);
    }


    public getOperatorAvatarPath(id: string | number, name: OperatorAvatarName): string {

        return `operators/${id}/avatars/${name}`;
    }

    public uploadOperatorAvatar(file: ArrayBuffer, id: string, name: OperatorAvatarName): Observable<HttpEvent<void>> {

        const formData = new FormData();

        formData.append('file', new Blob([file], { type: 'image/jpg' }));

        return this.http.post<void>(`${this.backendUrl}/operators/${id}/avatars/${name}`, formData, {
            reportProgress: true,
            observe: 'events',
            responseType: 'json'
        });
    }


    // ------------------------------------------------------------------------------------
    // Operations reports
    // ------------------------------------------------------------------------------------

    public getOperationsSession(id: string): Observable<OperationsSession> {

        return this.http.get<OperationsSession>(`${this.backendUrl}/operations/sessions/${id}`);
    }

    // ------------------------------------------------------------------------------------
    // Operations reports
    // ------------------------------------------------------------------------------------

    public searchOperationsReports(request: OperationsReportsSearchRequest): Observable<OperationsReportsSearchResponse> {

        const params = request as any;

        return this.http.get<OperationsReportsSearchResponse>(`${this.backendUrl}/operations/reports`, { params });
    }

    public getOperationReport(id: string): Observable<OperationReport> {

        return this.http.get<OperationReport>(`${this.backendUrl}/operations/reports/${id}`);
    }

    public getOperationReportPreview(id: string): Observable<OperationReportPreview> {

        return this.http.get<OperationReportPreview>(`${this.backendUrl}/operations/reports/${id}/preview`);
    }

    public uploadOperationReport(report: OperationReport): Observable<OperationReportCreatedResponse> {

        return this.http.post<OperationReportCreatedResponse>(`${this.backendUrl}/operations/reports`, report);
    }

    public deleteOperationReport(id: string): Observable<void> {

        return this.http.delete<void>(`${this.backendUrl}/operations/reports/${id}`);
    }


    // ------------------------------------------------------------------------------------
    // Operations photographies
    // ------------------------------------------------------------------------------------


    public uploadOperationsPhotographies(files: Array<any>, sessionId = ''): Observable<HttpEvent<any>> {

        const formData = new FormData();

        if (sessionId) {
            formData.append('sessionId', sessionId);
        }

        for (const file of files) {
            formData.append('file', file);
        }

        return this.http.post<OperationReportCreatedResponse>(`${this._backendUrl}/operations/photographies`, formData, {
            reportProgress: true,
            observe: 'events',
            responseType: 'json'
        });
    }

    public searchOperationPhotographies(request: OperationPhotographiesSearchRequest): Observable<OperationPhotographiesSearchResponse> {

        const params = request as any;

        return this.http.get<OperationPhotographiesSearchResponse>(`${this.backendUrl}/operations/photographies`, { params });
    }

    public deleteOperationPhotography(id: string): Observable<void> {

        return this.http.delete<void>(`${this.backendUrl}/operations/photographies/${id}`);
    }


    // ------------------------------------------------------------------------------------
    // Store
    // ------------------------------------------------------------------------------------

    public getStoreProducts(request: StoreProductsSearchRequest): Observable<StoreProductsSearchResponse> {

        const params = request as any;

        return this.http.get<StoreProductsSearchResponse>(`${this.backendUrl}/store/products`, { params });
    }




    public searchStoreOrders(request: StoreOrdersSearchRequest): Observable<StoreOrdersSearchResponse> {

        const params = request as any;

        return this.http.get<StoreOrdersSearchResponse>(`${this.backendUrl}/store/orders`, { params });
    }

    public getStoreOrder(id: string): Observable<StoreOrder> {

        return this.http.get<StoreOrder>(`${this.backendUrl}/store/orders/${id}`);
    }

    public createStoreOrder(request: StoreOrderCreateRequest): Observable<StoreOrder> {

        return this.http.post<StoreOrder>(`${this.backendUrl}/store/orders`, request);
    }

    public payStoreOrder(id: string): Observable<PaymentIntent> {

        return this.http.post<PaymentIntent>(`${this.backendUrl}/store/orders/${id}/pay`, {});
    }

    public payStoreOrderOnDelivery(id: string): Observable<void> {

        return this.http.post<void>(`${this.backendUrl}/store/orders/${id}/pay-on-delivery`, {});
    }

    public deleteStoreOrder(id: string): Observable<void> {

        return this.http.delete<void>(`${this.backendUrl}/store/orders/${id}`);
    }

    public updateStoreOrderStatus(id: string, status: StoreOrderStatus): Observable<StoreOrderUpdateStatusResponse> {

        return this.http.patch<StoreOrderUpdateStatusResponse>(`${this.backendUrl}/store/orders/${id}/status`, { status });
    }


    // ------------------------------------------------------------------------------------
    // Firmwares
    // ------------------------------------------------------------------------------------

    public getFirmware(id: string | number): Observable<Firmware> {

        return this.http.get<Firmware>(`${this.backendUrl}/firmwares/${id}`);
    }

    public searchFirmwares(request: FirmwaresSearchRequest): Observable<FirmwaresSearchResponse> {

        const params = request as any;

        return this.http.get<FirmwaresSearchResponse>(`${this.backendUrl}/firmwares`, { params });
    }

    public createFirmware(request: FirmwareCreateFormDataRequest): Observable<HttpEvent<any>> {

        const formData = new FormData();

        for (const key in request) {
            if (Object.prototype.hasOwnProperty.call(request, key)) {
                formData.append(key, (request as any)[key]);
            }
        }

        return this.http.post<void>(`${this.backendUrl}/firmwares`, formData, {
            reportProgress: true,
            observe: 'events',
            responseType: 'json'
        });
    }

    public updateFirmware(request: FirmwareUpdateFormDataRequest): Observable<HttpEvent<any>> {

        const formData = new FormData();

        for (const key in request) {
            if (Object.prototype.hasOwnProperty.call(request, key)) {
                formData.append(key, (request as any)[key]);
            }
        }

        return this.http.patch<void>(`${this.backendUrl}/firmwares`, formData, {
            reportProgress: true,
            observe: 'events',
            responseType: 'json'
        });
    }

    public deleteFirmware(id: string): Observable<void> {

        return this.http.delete<void>(`${this.backendUrl}/firmwares/${id}`);
    }


    // ------------------------------------------------------------------------------------
    // Devices
    // ------------------------------------------------------------------------------------

    public searchDevicesRegistrations(request: DevicesRegistrationsSearchRequest): Observable<DevicesRegistrationsSearchResponse> {

        const params = request as any;

        return this.http.get<DevicesRegistrationsSearchResponse>(`${this.backendUrl}/devices`, { params });
    }

    public deleteDeviceRegistration(id: string): Observable<void> {

        return this.http.delete<void>(`${this.backendUrl}/devices/${id}`);
    }

    public createDeviceNfcTagOperator(request: DeviceCreateNfcTagOperatorRequest): Observable<DeviceNfcTags> {

        return this.http.post<DeviceNfcTags>(`${this.backendUrl}/devices/nfc-tags-operators`, request);
    }

    public createDeviceNfcTagGuest(request: DeviceCreateNfcTagGuestRequest): Observable<DeviceNfcTags> {

        return this.http.post<DeviceNfcTags>(`${this.backendUrl}/devices/nfc-tags-guests`, request);
    }

    public createDeviceNfcTagResource(request: DeviceCreateNfcTagResourceRequest): Observable<DeviceNfcTags> {

        return this.http.post<DeviceNfcTags>(`${this.backendUrl}/devices/nfc-tags-resources`, request);
    }

    public createDeviceNfcTagPart(request: DeviceCreateNfcTagPartRequest): Observable<DeviceNfcTags> {

        return this.http.post<DeviceNfcTags>(`${this.backendUrl}/devices/nfc-tags-parts`, request);
    }

    public createDeviceNfcTagLocation(request: DeviceCreateNfcTagLocationRequest): Observable<DeviceNfcTags> {

        return this.http.post<DeviceNfcTags>(`${this._backendUrl}/devices/nfc-tags-location`, request);
    }

    public createDeviceNfcTagSystem(request: DeviceCreateNfcTagSystemRequest): Observable<DeviceNfcTags> {

        return this.http.post<DeviceNfcTags>(`${this._backendUrl}/devices/nfc-tags-system`, request);
    }

    public createDeviceHeadquarterGateway(request: DeviceCreateHeadquarterGatewayRequest): Observable<DeviceRegistration> {

        return this.http.post<DeviceRegistration>(`${this._backendUrl}/devices/headquarter-gateway`, request);
    }

    public createDeviceGroundModule(request: DeviceCreateGroundModuleRequest): Observable<DeviceRegistration> {

        return this.http.post<DeviceRegistration>(`${this._backendUrl}/devices/ground-module`, request);
    }


    // ------------------------------------------------------------------------------------
    // Contact
    // ------------------------------------------------------------------------------------

    // TODO (backoffice)
    // public searchNewsLettersSubscriptions(): Observable<void> {

    //     return this.http.get<void>(`${this.backendUrl}/news-letters/subscriptions`);
    // }

    public subscribeNewsLetters(request: NewsLetterSubscribeRequest): Observable<void> {

        return this.http.post<void>(`${this.backendUrl}/news-letters/subscriptions`, request);
    }

    public unsubscribeNewsLetters(id: string): Observable<void> {

        return this.http.delete<void>(`${this.backendUrl}/news-letters/subscriptions/${id}`);
    }

    // ------------------------------------------------------------------------------------
    // Tracking
    // ------------------------------------------------------------------------------------

    public track(request: TrackingCreateRequest): Observable<void> {

        return this.http.post<void>(`${this.backendUrl}/tracking`, request);
    }


    // ------------------------------------------------------------------------------------
    // Overview
    // ------------------------------------------------------------------------------------

    public getHost(): Observable<Host> {

        return this.http.get<Host>(`${this.backendUrl}/state/host`);
    }

    public getDashboard(): Observable<Dashboard> {

        return this.http.get<Dashboard>(`${this.backendUrl}/dashboard`);
    }

}
