import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Scene, Frame, Sprite } from '../../types/types';

@Component({
    selector: 'wild-animation',
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {

    @Input()
    public frames: Array<Frame> = [];

    @Input()
    public isControlsDisplayed = false;

    @Input()
    public isGridDisplayed = false;

    @Input()
    public isCameraLocked = false;

    public scene: Scene = {
        sprites: [],
        camera: {
            x: 500,
            y: 500,
            zoom: 1,
        },
        subtitle: ''
    };

    public frameIndex = -1;

    public frameTimer: any | undefined;

    constructor(private domSanitizer: DomSanitizer) { }

    ngOnInit(): void {

        this.play();
    }


    public play() {

        if (this.isPlaying) {
            return;
        }

        this.frameTimer = setTimeout(() => this.next(), 0);
    }

    public stop() {

        if (!this.isPlaying) {
            return;
        }

        clearTimeout(this.frameTimer);

        this.frameTimer = undefined;
    }

    public togglePlayStop() {

        this.isPlaying ? this.stop() : this.play();
    }

    public previous() {

        if (this.frameIndex === 0) {
            return;
        }

        this.jump(this.frameIndex - 1);

    }

    public async next(): Promise<void> {

        if (this.frameIndex >= this.frames.length - 1) {
            return;
        }

        // Go next
        this.frameIndex++;

        // Jump feature
        if (this.currentFrame.jump) {

            this.jump(this.currentFrame.jump);

            return;
        }

        // Update the scene
        await this.update();

        // Self call the next frame if playing
        if (this.isPlaying) {
            this.frameTimer = setTimeout(() => this.next(), this.currentFrame.duration || 3000);
        }
    }


    public async jump(frameIdOrIndex: string | number): Promise<void> {

        const index = (Number.isInteger(frameIdOrIndex) ? frameIdOrIndex : this.frames.findIndex(f => f.id === frameIdOrIndex)) as number;

        if (index < 0 || index > this.frames.length) {
            return;
        }

        this.scene.sprites = [];

        this.frameIndex = -1;

        while (this.frameIndex < index) {
            await this.next();
        }

    }

    public displaySpriteData(sprite: Sprite): void {

        alert(`
        ${sprite.id}
        x: ${sprite.x} y: ${sprite.y} z: ${sprite.z} angle: ${sprite.angle}

        frame: ${this.frameIndex}`);
    }

    private async update() {

        // Create, update or delete sprites
        for (let sprite of this.currentFrame.sprites || []) {

            if (sprite.type === 'svg' && sprite.url) {
                sprite.content = await this.loadSVG(sprite.url);
            }

            let existingSprite = this.scene.sprites.find(s => s.id === sprite.id);

            if (existingSprite) {

                if (sprite.delete) {

                    this.scene.sprites = this.scene.sprites.filter(s => s.id !== sprite.id);

                } else {

                    existingSprite = Object.assign(existingSprite, sprite);
                }

            } else {

                this.scene.sprites.push({ ...sprite });
            }

        }

        // Sort sprites by z axis
        this.scene.sprites = this.scene.sprites.sort((a, b) => (a.z || 0) - (b.z || 0));

        // Update the camera
        this.scene.camera = Object.assign(this.scene.camera, this.currentFrame.camera);

        // Update the subtitle (translation may be used here)
        this.scene.subtitle = this.currentFrame.subtitle === undefined ? this.scene.subtitle : this.currentFrame.subtitle;
    }


    private get currentFrame(): Frame {
        return this.frames[this.frameIndex];
    }

    private get isPlaying(): boolean {
        return this.frameTimer !== undefined;
    }

    private async loadSVG(url: string): Promise<SafeHtml> {

        // To prevent the usage of a same id or class inside the imported svg,
        // a random prefix is injected
        const prefix = Math.round(Math.random() * 100000);

        let data = (await (await fetch(url)).text())
            .replace(/class=\"/gi, 'class="' + prefix + '_')
            .replace(/id=\"/gi, 'id="' + prefix + '_')
            .replace(/href="#/gi, 'href="#' + prefix + '_');

        return this.domSanitizer.bypassSecurityTrustHtml(data);
    }


}
